.instruction {
  @include outer-wrap;
  @include inner-wrap;
  border: 2px solid #e5ebf1;
  margin-bottom: $module-spacing;
  @include padding(40px null);

  @include media($mobile) {
    @include margin(null 20px null 10px);
    @include padding(null 20px null 10px); }

  @include media($tablet) {
    @include margin(auto);
    @include padding(null 30px null 20px); }

  ol {
    list-style-type: none;
    margin-left: 0;
    counter-reset: step; }

  li {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0; }
    @include media($desktop) {
      @include span-columns(4 of 12);
      margin-bottom: 0; }
    &:before {
      display: block;
      counter-increment: step;
      content: counter(step);
      margin-bottom: 1em;
      font-family: $heading-font-family;
      text-align: center;
      color: $white;
      background-color: $cyan;
      border-radius: 100%;
      height: 30px;
      width: 30px; }
    p {
      font-size: $base-font-size; } }

  img {
    padding-top: 1em;
    padding-left: 1em;
    @include media($tablet) {
      width: 100%; } } }

.alert {

  background: $fuchsia;
  color: #fff;
  position: relative;
  margin-bottom: -10px;
  background-position: -227px ​-87px !important;
  a {
    font-weight: bold;
    text-decoration: underline; }
  p {
    padding: 10px 50px 10px 40px;
    font-size: 0.875em;
    line-height: 1.428em; }
  .icon {
    @include retina-sprite($alert-group);
    display: block;
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -12px; }
  .close {
    @include hide-text;
    display: block;
    width: 13px;
    height: 18px;
    position: absolute;
    right: 23px;
    top: 65%;
    padding: 0px;
    box-sizing: content-box;
    margin-top: -18px;
    &:after {
      content: '';
      @include retina-sprite($close-group);
 }      // +icon
    background-image: url(../img/close-icon2.png);
    background-position: 14px 16px {
      display: block;
      float: right; } }


  // Toggle/Extended styles
  &.alert-toggle {

    .icon, .toggle {
      top: 22px; }
    > p {
      font-size: 1em;
      text-align: center; }
    p {
      @include padding(null 40px null 40px); } }

  .toggle {
    @include hide-text;
    display: block;
    position: absolute;
    right: 10px;
    top: 50%;
    box-sizing: content-box;
    padding: 15px;
    margin-top: -39px;

    &:after {
      content: '';
      @include retina-sprite($arrow-down-blue-group);

      // +icon
      display: block;
      float: right; } }

  .btn {
    margin: 15px 50px 0 130px;
    background: #fff;
    text-decoration: none;

    @include media($tablet) {
      width: 150px;
      display: block;
      margin: 0 auto; } }

  .dropdown {
    display: none;
    padding-bottom: 25px;
    margin-bottom: 10px; } }

.alert.sticky {
  display: none !important; }

.alert-error {
  background: #ffe6d8 !important;
  color: #a91c00 !important;
  background-position: -227px ​-87px !important;
  .btn {
    color: #003c71;
    border-radius: 30px; } }

.alert-info {
  background: #ceecf9 !important;
  color: #001745 !important;
  background-position: -227px ​-87px !important;
  .btn {
    color: #003c71;
    border-radius: 30px; } }

.alert-success {
  background: #e4f9de !important;
  color: #007f00 !important;
  background-position: -227px ​-87px !important;
  .btn {
    color: #003c71;
    border-radius: 30px; } }

.alert-warning {
  background: #fffadc !important;
  color: #c58500 !important;
  background-position: -227px ​-87px !important;
  .btn {
    color: #003c71;
    border-radius: 30px; } }

.down-arrow {
  top: 42px !important; }

.btn-dismiss {
  padding: 14px 24px;
  width: 110px !important;
  text-transform: capitalize;
  &:hover, &:focus {
  	background-color: #00a3e0 !important;
	  color: #ffffff; } }


.down-arrow.active:after {
  transform: rotate(180deg);
  transition: all 250ms ease; }


.alert .toggle:after {
  transition: all 250ms ease; }

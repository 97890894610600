.rebates-offer-page {
  padding-top: 120px;

  @include media($mobile) {
    padding-top: 64px;
  }

  @include media($tablet-lg) {
    padding-top: 100px;
  }

  .section-intro {
    margin-top: 0;

    &_container {
      padding-top: 0;
    }

    &_name {
      font-size: 12px;
    }

    &_title {
      line-height: 1.12;
    }
  }

  .breadcrumbs {
    margin-bottom: 20px;

    ul {
      margin-right: 0;
      margin-left: 0;
      padding-left: 22px;

      @include media($tablet) {
        padding-left: 100px;
      }

      @include media($desktop-lg) {
        padding-left: 185px;
      }
    }

    @include media($tablet) {
      margin-bottom: 15px;
    }
  }

  .article-content {
    padding-top: 0;
    margin: 0 23px;
    border: none;

    @include media($tablet) {
      margin: 0
    }
  }
}

.interactive-map {
  @include outer-wrap;
  margin-bottom: $module-spacing;

  .map {
    width: 100%;
    min-height: 1100px;
    background: url(../img/map-base.png) no-repeat;
    position: relative;
    background-size: 100%; }
  .map div {
    display: none;
    position: absolute;
    cursor: pointer;
    border-radius: 300px;
    img {
      border-radius: 300px; } }
  @include media($desktop-lg) {
    .map .visible {
      display: block; } } }

@charset "UTF-8";

/// Provides a quick method for targeting `margin` on specific sides of a
/// box. Use a `null` value to “skip” a side.
///
/// @argument {arglist} $values
///   List of margin values, defined as CSS shorthand.
///
/// @example scss
///   .element {
///     @include margin(null 10px 3em 20vh);
///   }
///
/// @example css
///   .element {
///     margin-bottom: 3em;
///     margin-left: 20vh;
///     margin-right: 10px;
///   }
///
/// @require {mixin} _directional-property

@mixin margin($values...) {
  @include _directional-property(margin, false, $values...);
}

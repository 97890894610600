/* 
custom-select styles
https://github.com/filamentgroup/select-css
*/

/* Container used for styling the custom select, the buttom class below adds the
* bg gradient, corners, etc. */

.custom-select {
    position: relative;
    display: block;
}

/* This is the native select, we're making everything but the text invisible so
* we can see the button styles in the wrapper */

.custom-select select {
    width: 100%;
    margin: 0;
    margin-top: 8px;
    outline: none;
    padding: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: inset 0 0 0 rgba(0,0,0,0.06);
    transition: border-color 150ms ease;
    /* Font size must be 16px to prevent iOS page zoom on focus */
    font-size: 16px;
    @include media($desktop) {
        font-size: 18px
    }
}

/* Custom arrow sits on top of the select - could be an image, SVG, icon font,
* etc. or the arrow could just baked into the bg image on the select. */

.custom-select {
    &::after {
        @include retina-sprite($arrow-down-blue-group);
        content: '';
        position: absolute;
        top: 27px;
        right: 1em;
        z-index: 2;
        /* These hacks make the select behind the arrow clickable in some browsers */
        pointer-events: none;
        display: none;
    }
}

/* Firefox <= 34 has a false positive on @supports( -moz-appearance: none )
* @supports ( mask-type: alpha ) is Firefox 35+
*/

@supports (-webkit-appearance: none) or (appearance: none) or ((-moz-appearance: none) and (mask-type: alpha)) {
    /* Show custom arrow */
    .custom-select::after {
        display: block;
    }
    /* Remove select styling */
    .custom-select select {
        padding-right: 2em;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
    }
    .custom-select select:focus {
        &::after {
            @include retina-sprite($arrow-up-group);
        }
    }
}
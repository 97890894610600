.full-article {
  margin: $module-spacing / 2 0;
  img:not(.article-logo) {
    display: block;
    height: auto;
    margin: 20px 0;
    max-width: 100%;
    width: auto;
    @include media($desktop) {
      margin-bottom: 50px;
      margin-top: 50px; } }
  @include media($desktop) {
    margin: $module-spacing 0; } }

.article-nav {
  display: none;
  @include media($desktop) {
    display: block; } }

.article-content {
  margin: 10px 10px 0;
  overflow: auto;
  @include media($desktop) {
    border: 2px solid $light-grey;
    border-top: 0;
    border-bottom: 0;
    padding: 50px 120px 0px 120px;
    margin: -5px 0 0; }
  @include media($desktop-lg) {
    padding-left: 220px;
    padding-right: 220px; }

  ul {
    list-style: disc;
    margin-bottom: 0.625em;
    padding-left: 1em; }
  ol {
    list-style: decimal;
    margin-bottom: 0.625em;
    padding-left: 1em; }

  .video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; } } }

.article-title {
  h2 {
    margin-bottom: 0; } }

.article-head {
  margin: 0 10px;
  @include media($desktop) {
    border: 2px solid $light-grey;
    border-bottom: 0;
    margin: -5px 0 0;
    padding: 40px 120px 0px 120px; }
  @include media($desktop-lg) {
    padding-left: 220px;
    padding-right: 220px; } }

.article-footer {
  padding: 0px 0 20px;
  margin: 0 10px;
  overflow: auto;
  @include media($desktop) {
    border: 2px solid $light-grey;
    border-top: 0;
    margin: -5px 0 0;
    padding: 0px 120px 100px 120px; }
  @include media($desktop-lg) {
    padding-left: 220px;
    padding-right: 220px; } }

.article-highlight {
  color: $light-blue;
  margin-top: 40px; }

.article-heavy {
  font-weight: bold; }

.article-back {
  color: $light-blue;
  margin-bottom: 10px;
  margin-left: 10px;
  &:before {
    content: '';
    @include retina-sprite($arrow-left-group);
    // +icon
    display: inline-block;
    margin-right: 6px; }
  @include media($desktop) {
    margin-left: 0; } }

.article-logo {
  @include media($mobile) {
    margin: -54px 20px;
    max-width: 47px;
    float: right; }
  display: block;
  margin: 15px auto 20px;
  max-width: 56px;
  @include media($desktop) {
    margin-bottom: 40px;
    margin-top: 0;
    max-width: 100%; } }


.article-contact {
  overflow: auto;
  padding-top: 40px;
  .article-call {
    display: inline-block;
    float: left;
    margin: 15px 0; }
  .social-share {
    display: inline-block;
    float: none;
    font-weight: bold;
    &::before {
      content: '';
      @include retina-sprite($fb-group);
      display: inline-block;
      margin: 0 15px 0 0;
      position: relative;
      top: 12px; }
    @include media($desktop) {
      float: right; }
    p {
      display: inline-block; } } }

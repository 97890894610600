footer {

  // All Else Styles
  a {
    color: #FFF;
    font-size: 1.125em; }
  h5 {
    color: $light-blue; }

  // Upper area of the footer
  .ankle {
    background: #003c71;
    padding: 60px 0 70px 0;
    .container div {
      @include media($tablet) {
        display: none; }
      @include media($desktop) {
        display: inline-block;
        @include span-columns(5.5 of 24); }
      .logo {
        @include retina-sprite($footer-logo-group);
        // +icon
        display: block;
        @include media($desktop) {
          margin-left: -10px; }
        @include media($desktop-lg) {
          margin-left: 0; } } }

    .sections {
      @include media($tablet) {
        @include span-columns(24 of 24); }
      @include media($desktop) {
        @include span-columns(18.5 of 24); }
      > li {
        @include span-columns(3);
        li {
          margin-bottom: 1em; }
        a {
          line-height: 1em; } } }
    // Contact Numbers
    .number {
      span {
        color: #fff;
        font-size: 1.125em;
        line-height: 2em; }
      a {
        color: $light-blue;
        display: block;
        font-size: 1.375em;
        font-family: $bree;
        line-height: 1em !important;
        margin-bottom: 10px;
        margin-top: -6px; } } }

  .foot {
    background: #003767;
    color: #FFF;
    height: 90px;
    line-height: 90px;
    font-size: 0.875em;

    span {
      @include span-columns(4);
      text-transform: uppercase; }

    ul {
      @include span-columns(8);
      text-align: right;
      li {
        display: inline-block;
        &:before {
          content: '| ';
          margin: 0 6px; }
        &:first-child:before {
          display: none; } } } }



  // Hide the ankle on mobile
  .ankle {
    display: none;
    @include media($tablet) {
      display: block; } }
  .mobile-ankle {
    display: block; }

  .mobile-links {
    li a {
      background: #013868;
      display: block;
      text-align: center;
      border-bottom: 1px solid #01335f;
      line-height: 60px;
      span {
        color: $light-blue; } }
    li.top a {
      background: #FFF;
      color: $dark-blue;
      text-transform: uppercase;
      font-weight: bold;
      line-height: 100px;
      &:after {
        content: '';
        @include retina-sprite($back-to-top-group);
        display: inline-block;
        margin: 0 0 3px 10px; } }
    li.alt a {
      background: #003c71; } }
  .foot {
    span {
      display: none;
      @include media($tablet) {
        display: inline; } }
    ul {
      @include media($mobile) {
        @include span-columns(12 of 12);
        text-align: center;
        li {
          display: inline-block; } } } }
  @include media($mobile-sm) {
    .foot li {
      font-size: 12px; } }
  @include media($tablet) {
    .mobile-links li {
      display: none; }
    .mobile-links li.top {
      display: block; } }
  @include media($desktop-lg) {
    .mobile-links li.top {
      display: none; } } }

/// Used for base icon styles
/// @depreciated Should not need to use anymore
@mixin icon {
    display: block;
    background-image: url('../img/sprite@2x.png');
    text-indent: -999999px;
}

@mixin block-image {
  display: block;
  width: 100%;
}

/// Applies responsive gutters as padding while conforming to the outer wrap
@mixin inner-wrap($local-max-width: $max-width) {
    @include clearfix;
    max-width: $local-max-width;
    margin: {
        left: auto;
        right: auto;
    }
	@include media($mobile) {
		padding-left: $gutter-mobile;
		padding-right: $gutter-mobile;
	}
    @include media($tablet) {
        padding-left: $gutter-tablet;
        padding-right: $gutter-tablet;
    }
    @include media($desktop) {
        padding-left: $gutter-desktop;
        padding-right: $gutter-desktop;
    }
    @include media($desktop-lg) {
        padding-left: $gutter-desktop-lg;
        padding-right: $gutter-desktop-lg;
    }
}

/// Outer wrap that responsivly applies the gutter margins
@mixin outer-wrap() {
    @include outer-container;
    @include media($tablet) {
        margin-left: $gutter-tablet;
        margin-right: $gutter-tablet;
    }
    @include media($desktop) {
        margin-left: $gutter-desktop;
        margin-right: $gutter-desktop;
    }
    @include media($desktop-lg) {
        margin-left: auto;
        margin-right: auto;
    }
}

/// Used to apply a mobile gutter to elements that need the border spacing
@mixin mobile-gutter() {
    @include media($mobile){
        margin-left: $gutter-mobile;
        margin-right: $gutter-mobile;
    }
}

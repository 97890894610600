.roi {
  @include outer-wrap;

  .main-col {
    @include inner-wrap;
    @include media($mobile) {
      @include padding(null 0); } }

  margin-bottom: $module-spacing;
  @include media($mobile) {
    margin: 0 $mobile-gutter; }

  form > div {
    margin-bottom: 15px;
    max-width: 70%;
    @include media($mobile) {
      max-width: 100%; } }

  div.half {
    width: 35%;
    @include media($mobile) {
      width: 50%; } }
  .checkbox-group {
    &:after {
      display: block;
      content: '';
      clear: both; }
    div {
      width: 50%;
      float: left;
      margin-bottom: 5px;
      @include media($mobile) {
        width: 100%;
        float: none; } }
    input {
      display: none; }
    &>label {
      margin-bottom: 10px; }

    div label {
      color: #676767;
      font-weight: normal;
      padding-left: 28px;
      margin-bottom: 15px;
      position: relative;
      &:before {
        display: block;
        content: '';
        width: 20px;
        height: 20px;
        background: #EFEFEF;
        float: left;
        margin-right: 8px;
        cursor: pointer;
        margin-left: -28px; } }
    div input:checked + label:after {
      content: "";
      background-color: transparent;
      position: absolute;
      left: 7px;
      top: 1px;
      width: 7px;
      border-bottom: 2px solid #686868;
      height: 14px;
      border-right: 2px solid #686868;
      transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg); } }

  button[type="submit"] {
    float: right;
    margin-right: 30%;
    @include media($mobile) {
      width: 100%;
      margin: 0 auto; } }



  textarea {
    min-height: 150px; } }

.search-results {
  @include outer-wrap;
  @include inner-wrap;
  margin-top: 50px;
  h4 {
    @include h5;
    text-decoration: underline;
    margin: 0 0 4px 0; }

  .sub-link {
    font-size: 0.875em;
    color: #606060;
    &:hover {
      text-decoration: underline; } }

  .results li {
    margin-bottom: 30px; } }

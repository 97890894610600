@charset "UTF-8";

/// Strips the unit from a number.
///
/// @argument {number} $value
///
/// @return {number (unitless)}
///
/// @example scss
///   $dimension: strip-unit(10em);
///
/// @example css
///   $dimension: 10;

@function strip-unit($value) {
  @return ($value / ($value * 0 + 1));
}

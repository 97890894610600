.connection-process {

  margin-top: -130px;
  @include mobile-gutter;
  margin-bottom: $module-spacing;
  z-index: 1;

  .links {
    padding: 0 auto;
    @include media($tablet) {
      padding: 0 30px; }
    li a:before {
      content: '';
      display: inline-block;
      vertical-align: -20px;
      @include margin(null 20px); }
    li.home a:before {
      @include retina-sprite($tab-home-large-on-group); }
    li.commercial a:before {
      @include retina-sprite($tab-business-large-on-group);
      margin-left: 28px; }
    li {
      border-bottom: 1px solid #e9e9e9; }
    li:last-child {
      border-bottom: none; }
    li a {
      line-height: 120px;
      font-size: 26px;
      font-family: $bree;
      color: $dark-blue; } }


  // Navigation tabs for mobile
  > nav {
    @include outer-wrap;
    > ul > li > a {
      background: $light-blue;
      border-bottom: 1px solid #008abe;
      display: block;
      color: #fff;
      padding: 0 12px;
      line-height: 60px;
      &:before {
        content: '';
        // +icon
        display: block;
        float: left;
        margin: 18px 5px 0 5px; }
      &:after {
        content: '';
        @include retina-sprite($arrow-down-white-group);
        // +icon
        display: block;
        float: right;
        margin: 25px 10px 0 0; }
      &.active:after {
        @include retina-sprite($arrow-up-white-group); } }

    // Icons
    li.home a:before {
      @include retina-sprite($tab-home-group); }
    li.business a:before {
      @include retina-sprite($tab-business-group); }
    li.appliance a:before {
      @include retina-sprite($tab-appliance-group); }
    li.wrench a:before {
      @include retina-sprite($tab-wrench-group);
      vertical-align: -7px; }
    li.report a:before {
      @include retina-sprite($tab-report-group); }

    // Hover
    @include media($tablet) {
      li.home .active:before {
        @include retina-sprite($tab-home-on-group); }
      li.appliance .active:before {
        @include retina-sprite($tab-appliance-on-group); }
      li.wrench .active:before {
        @include retina-sprite($tab-wrench-on-group); }
      li.report .active:before {
        @include retina-sprite($tab-report-on-group); } }
    //li.business .active:before
 }    //  +retina-sprite($tab-business-on-group)


  // Tab content
  input[type=text] {
    background: #fff; }

  .tab-content > li, .content {
    display: none;
    background: #EFEFEF;
    padding: 40px 20px 40px 20px;
    margin-bottom: 0;
    @include media($tablet) {
      &.show {
        display: block !important; } }
    // Form Styling
    button,.btn,input[type=submit] {
      float: right;
      margin-left: 0;
      margin-right: 0;
      @include media($mobile) {
        width: 100%;
        float: none; } } }

  .twitter-typeahead, .tt-hint, .tt-input, .tt-menu {
    width: 100%; }

  .tt-menu {
    max-height: 150px;
    overflow-y: auto; }

  // Tablet and Up
  @include media($tablet) {
    margin-top: -60px;
    nav {
      height: 60px; }
    .half {
      @include span-columns(6 of 12); }
    // Tabs
    nav ul li {
      display: block;
      width: 25%;
      float: left;

      a {
        border-bottom: none;
        text-align: center;
        padding: 2px 0 0 0;
        font-size: 0.750em;
        border-right: 2px solid #00729c;
        &.active {
          background: #fff;
          color: $dark-blue;
          border: 2px solid #e9e9e9;
          border-bottom: none;
          padding-top: 0; }
        &:before {
          float: none;
          display: inline-block;
          vertical-align: -6px;
          margin-left: 0; }
        &:after {
          display: none; } }
      &:last-child a:not(.active) {
        border-right: none; } }
    // Tab Content
    .tab-content {
      @include outer-wrap; }
    .tab-content > li.show {
      @include inner-wrap;
      background: #fff;
      border-bottom: 2px solid #e9e9e9;
      border-left: 2px solid #e9e9e9;
      border-right: 2px solid #e9e9e9;
      &:after {
        clear: both;
        content: '';
        display: block; }
      input[type=text] {
        background: #efefef; }
      .tt-hint {
        background: #efefef !important; } } }

  // Desktop
  @include media($desktop) {
    nav ul li a {
      font-size: 1em; } }

  @include media($tablet) {
    form {
      position: relative; }
    label.validation {
      position: relative; } }


  .mini-connection-process {
    background: #efefef;
    margin: 0;
    padding: 0;
    @include media($tablet) {
      background: #fff; }
    p {
      margin-bottom: 20px; }
    a.home:before {
      @include retina-sprite($tab-home-lg-group); }
    a.business:before {
      @include retina-sprite($tab-business-lg-group); } } }

// shorlist
.shortlist {
    //@include inner-wrap;
	@include outer-wrap;
	.container {
		@include inner-wrap;
	}
    margin-bottom: $module-spacing;
    @media screen and (min-width: $tablet-screen) {
        .item {
            @include span-columns(3 of 9)
            margin-bottom: $module-spacing;
        }
    }
    h2 { text-align: center; margin-bottom: 50px;  }
    .item {
        img { @include block-image; margin-bottom: 25px; }
    }
}

.shortlist-categories {
  position: relative;
  padding-top: 40px;
  .inner {
    overflow: auto;
    h2 {
      text-align: left;
      @media screen and (min-width: $tablet-screen) {
        text-align: center;
      }
    }

    h3 a {
      color: $dark-blue;
    }
  }
  .cat-icon::after {
    color: $white;
    position: absolute;
    margin: 10px;
    padding: 5px 10px;
  }
  .advice::after {
    background: #008c95;
    content: "Advice";
  }
  .tradehub::after {
    background: #69659b;
    content: "Trade hub";
  }
  .life::after {
    background: #c55616;
    content: "Life";
  }
  .news::after {
    background: #00A3E0;
    content: "News & Views";
  }
}


/// H1 style consitent with the style guide
@mixin h1 {
  font-size: 2em; //32px
  line-height: 1.06em; //46px
  @media screen and (min-width: $tablet-screen) {
    font-size: 2.625em; //42px
    line-height: 1.05em;
  }
  @media screen and (min-width: $desktop-screen) {
    font-size: 3.25em; //52px
    line-height: 0.98em;
  }
}

/// H2 style consitent with the style guide
@mixin h2 {
  font-size: 1.875em; //30px
  line-height: 0.93em;
  @media screen and (min-width: $tablet-screen) {
    font-size: 2.25em; //36px
    line-height: 0.94em;
  }
  @media screen and (min-width: $desktop-screen) {
    font-size: 2.625em; //42px
    line-height: 1.05em
  }
}

/// H3 style consitent with the style guide
@mixin h3 {
  font-size: 1.5em; //24px
  line-height: 0.92em;
  @media screen and (min-width: $tablet-screen) {
    font-size: 1.75em; //28px
    line-height: 0.93em;
  }
  @media screen and (min-width: $desktop-screen) {
    font-size: 2em; //32px
    line-height: 1em
  }
}

/// H4 style consitent with the style guide
@mixin h4 {
  font-size: 1.125em; //18px
  line-height: 0.83em;
  @media screen and (min-width: $tablet-screen) {
    font-size: 1.25rem; //20px
    line-height: 0.9em;
  }
  @media screen and (min-width: $desktop-screen) {
    font-size: 1.375em; // 22px
    line-height: 0.91em
  }
}

/// H5 style consitent with the style guide
@mixin h5 {
  font-size: 0.6875em; //11px
  line-height: 1em;
  @media screen and (min-width: $tablet-screen) {
    font-size: 0.75em; // 12px
    line-height: 1em
  }
  @media screen and (min-width: $desktop-screen) {
    font-size: 0.75em; // 12px
    line-height: 1em
  }
}

/// Text content / paragraph styling consitent with the style guied
@mixin p1 {
  font-size: 1.0625em; //17px
  line-height: 1.41em;
  @media screen and (min-width: $tablet-screen) {
    font-size: 1.125em; //18px
    line-height: 1.33em;
  }
  @media screen and (min-width: $desktop-screen) {
    font-size: 1.25em; //20px
    line-height: 1.3em;
  }
}

@mixin p2 {
  font-size: 0.938em; //15px
  line-height: 1.47em;
  @media screen and (min-width: $tablet-screen) {
    font-size: 1em; //16px
    line-height: 1.5em;
  }
  @media screen and (min-width: $desktop-screen) {
    font-size: 1.0625em; //17px
    line-height: 1.41em;
  }
}

@mixin p3 {
  font-size: 0.8125em; //13px
  line-height: 1.38em;
  @media screen and (min-width: $tablet-screen) {
    font-size: 0.875em; //14px
    line-height: 1.43em;
  }
  @media screen and (min-width: $desktop-screen) {
    font-size: 0.875em; //14px
    line-height: 1.43em;
  }
}




.footer {
  &-feature {
    background-color: $white;
    box-shadow: 0 -1px 0 0 #e7e7e7;
    padding: 50px 0;

    @include media($mobile) {
      padding: 0;
      box-shadow: none; } }

  &-details {
    @include span-columns(12 of 12);
    display: flex;
    float: none;
    width: auto;

    @include media($mobile) {
      flex-direction: column; }

    &:last-child {
      margin-right: 39px;

      @include media($desktop-lg) {
        margin-right: auto; }

      @include media($mobile) {
        margin-right: 0; } }

    .col-3 {
      @include span-columns(3 of 12);

      @include media($mobile) {
        width: 100%;
        box-shadow: inset 0 1px 0 0 #e7e7e7; }

      .heading {
        margin-bottom: 23px;

        @include media($mobile) {
          margin-bottom: 0; } } }

    .partner-logos {
      @include media($mobile) {
        padding: 24px 24px 0;

        .heading {
          margin-bottom: 10px; } }

      a {
        margin-right: 20px; }

      img {
        height: 40px;
        width: auto;
        margin-bottom: 23px;

        @include media($mobile) {
          height: 32px;
          margin-bottom: 17px; } } } }

  &-link {
    color: $dark-blue;
    font-size: inherit;

    &:hover,&:focus,&:active {
      color: $dark-blue; }

    @include media($mobile) {
      display: inline-flex;
      padding: 24px;
      align-items: center;
      width: 100%; }

    p {
      @include media($mobile) {
        display: none; } }

    .text-link {
      font-family: $albert-bold;
      display: flex;
      align-items: center;

      @include media($mobile) {
        color: $white;
        position: absolute;
        right: 24px; } } }

  &-ankle {
    background-color: $white;
    box-shadow: 0 -1px 0 0 #e7e7e7;
    height: 90px;
    line-height: 90px;
    color: $dark-blue;

    @include media($mobile) {
      height: auto;
      line-height: 35px;
      padding: 20px 0; }

    .container {
      display: flex;

      @include media($mobile) {
        flex-direction: column;
        text-align: center; }

      span {
        @include span-columns(4 of 12);

        @include media($mobile) {
          width: 100%; } }

      ul {
        @include span-columns(8 of 12);
        text-align: right;

        @include media($mobile) {
          width: 100%;
          display: inline-flex;
          justify-content: center; }

        li {
          display: inline-block;

          &:before {
            content: '| ';
            margin: 0 6px; }
          &:first-child:before {
            display: none; } }

        .link {
          color: $dark-blue;
          font-size: 1em; } } } } }



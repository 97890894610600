// Basics
.hide {
  display: none; }

.show {
  display: block !important; }

.bg-orange {
  background-color: $orange; }

.bg-teal {
  background-color: $teal; }

.bg-green {
  background-color: $green; }

.bg-light-blue {
  background-color: $light-blue; }

.bg-dark-blue {
  background-color: $dark-blue; }

.bg-purple {
  background-color: $purple; }

.float-left {
  float: left; }

.float-right {
  float: right; }

body {
  overflow: hidden;
  select {
    width: 100%; } } // @todo hack

.container {
  @include outer-wrap; }

// @depreciated
.icon {
  text-indent: -99999px; }

// Hide mobile elements
.mobile {
  display: none; }
@include media($mobile) {
  .mobile {
    display: block; } }

// Layout
.two-col {
  @include media($mobile) {
    .side-col .promo-banner-locator {
      margin-bottom: -70px; } }
  @include media($tablet) {
    .main-col {
      @include span-columns(12 of 12); }
    .side-col > * {
      display: none; }
    .side-col .promo-banner-locator {
      display: block; } }
  @include media($desktop) {
    .main-col {
      @include span-columns(9 of 12); }
    .side-col {
      @include span-columns(3 of 12);
      display: block; } }
  .container {
    margin-left: 0;
    margin-right: 0; }
  @include media($mobile) {
    .main-col, .side-col {
      @include span-columns(1 of 1);
      margin-right: 0; } } // is there a better mixin for clearing column?
  .main-col .island {
    margin: 0; } }

// Other Fixes
main {
  outline: none;
  position: relative;
  z-index: 1; }

h1 {
  color: white; }

@mixin phone-heading--margin {
  margin-top: 21px;
  @include media($desktop) {
    margin-top: 41px;
    margin-bottom: 18px; } }

// Phone Heading reusable part
.phone-heading {
  &:before {
    content: '';
    @include retina-sprite($dot-phone-large-group);
    display: block;
    float: left;
    margin-right: 15px; }
  color: $dark-blue;
  line-height: 56px;
  display: block;
  font-family: $bree;
  font-size: 2.250em;
  margin-bottom: 20px; }

.support-desktop-number {
  @include media($desktop);
  @include media($tablet) {
    display: none; } }

.support-number {
  @include media($mobile) {
    display: none; }

  .contact-process & {
    margin-bottom: 16px;
    @include media($tablet) {
      font-size: 20px;
      line-height: 26px;
      &:before {
        @include retina-sprite($dot-phone-group); } }

    @include media($desktop-lg) {
      font-size: 28px;
      line-height: 56px;
      &:before {
        @include retina-sprite($dot-phone-large-group); } }

    &:before {
      margin-right: 12px; } }

  &.alt {
    color: #fff;
    &:before {
      @include retina-sprite($dot-phone-alt-group); } }

  &.dual {
    line-height: 30px;
    span {
      display: block;
      font-size: 18px;
      font-family: $albert;
      padding-left: 72px;
      margin-top: 5px;
      @include media($mobile) {
        font-size: 13px;
        line-height: 22px; }
      @include media($tablet) {
        font-size: 13px; }
      @include media($desktop) {
        font-size: 18px; } } }

  &.question:before {
    @include retina-sprite($dot-question-large-group);
    @include media($mobile) {
      margin-top: 10px; } }
  &.success:before {
    @include retina-sprite($dot-success-large-group);
    @include media($mobile) {
      margin-top: 10px; } }

  &.phone--xl:before {
    @include retina-sprite($dot-phone-xl-group);
    @include media($tablet) {
      @include retina-sprite($dot-phone-group); }
    @include media($desktop-lg) {
      @include retina-sprite($dot-phone-xl-group); } }

  &.mail--xl {
    @include phone-heading--margin;
    &:before {
      @include retina-sprite($dot-mail-xl-group);
      @include media($tablet) {
        @include retina-sprite($dot-mail-group); }
      @include media($desktop-lg) {
        @include retina-sprite($dot-mail-xl-group); } } }

  &.email--xl {
    @include phone-heading--margin;
    &:before {
      @include retina-sprite($dot-email-xl-group);
      @include media($tablet) {
        @include retina-sprite($dot-email-group); }
      @include media($desktop-lg) {
        @include retina-sprite($dot-email-xl-group); } } } }

.island {
  @include inner-wrap;
  @include outer-wrap;
  @include padding(40px null);
  border: 2px solid $light-grey;
  margin-bottom: $module-spacing;
  @include media($mobile) {
    @include margin(null 10px);
    @include padding(20px); } }

.island-search {
  border: 2px solid $light-grey;
  padding: 60px 70px;
  margin-bottom: $module-spacing;
  margin-top: $module-spacing / 2;
  .search {
    position: relative; }
  input[type=search] {
    width: 100%;
    box-sizing: border-box; }
  input[type=submit] {
    @include hide-text;
    background-color: transparent;
    @include retina-sprite($search-btn-group);
    padding: 0;
    position: absolute;
    top: 5px;
    right: 20px; } }

.btn.phone {
  @include media($mobile) {
    float: none !important;
    margin: 0; }
  font-size: 1.2em;
  &:before {
    content: '';
    display: inline-block;
    @include retina-sprite($phone-btn-group);
    vertical-align: -9px;
    margin-right: 12px; } }


.ticks {
  list-style: none !important;
  li {
    line-height: 2em;
    &:before {
      content: '';
      @include retina-sprite($tick-white-group);
      display: inline-block;
      margin-right: 10px; } }

  &--blue {
    li:before {
      @include retina-sprite($tick-blue-group);
      transform: scale(0.7);
      position: relative;
      top: 4px;
      margin-right: 15px; } } }




.pagination {
  text-align: center;
  margin-bottom: 30px;
  li {
    display: inline-block; }
  a {
    @include padding(null 5px);
    color: $dark-blue; }
  span {
    @include padding(null 5px);
    color: $light-blue; } }

// Form Validation
form > div {
  position: relative; }
label.validation {
  color: red;
  font-weight: normal; }


input[type="search"]::-ms-clear {
  width : 0;
  height: 0; }

.success-color {
  color: #2aa279; }

.gas-available {
  @include outer-wrap;
  margin-top: -100px;
  background: $white;
  position: relative;
  margin-bottom: 70px;

  .gas-connection {
    position: relative;
    display: inline-flex;
    width: 100%;
    padding: 0 15px;
    align-items: center;
    height: 208px;

    @include media($desktop-lg) {
      padding: 0 120px; }

    @media screen and (max-width: 1260px) and (min-width: 1024px) {
      padding: 0 20px; }

    .logo-text {
      @include span-columns(6 of 12);
      width: 45%;
      margin-right: 0;
      display: inline-flex;
      align-items: center;

      @media screen and (max-width: 1260px) and (min-width: 1024px) {
        width: 50%; }

      .logo {
        margin-right: 10px;
        width: 45px;
        height: auto;

        @media screen and (max-width: 900px) {
          width: 32px; }

        @include media($desktop-lg) {
          width: 64px;
          margin-right: 20px; } }

      .text {
        margin-bottom: 0;

        @include media($tablet-lg) {
          font-size: 1.5em; } }

      @media screen and (max-width: 900px) {
        margin: 50px 0 10px;
        width: 100%;
        justify-content: center; }

      @include media($tablet-lg) {
        margin-left: auto; } }

    .suburb {
      @include span-columns(6 of 12);
      width: 55%;
      display: flex;
      position: relative;

      @media screen and (max-width: 1260px) and (min-width: 1024px) {
        width: 50%; }

      @media screen and (max-width: 900px) {
        justify-content: center;
        width: 100%; }

      @include media($tablet-lg) {
        margin-right: auto; } }

    .enter-postcode {
      display: flex;
      align-items: baseline;

      @media screen and (max-width: 900px) {
        flex-direction: column;
        align-items: center; }

      .postcode {
        width: 100%;
        border-radius: 0;
        background-color: white;
        border-bottom: 2px solid $dark-blue;
        border-top: none;
        border-right: none;
        border-left: none;
        font-size: 17px;
        margin: 0;
        padding: 0 15px 9px;

        &:focus {
          box-shadow: none;
          outline: none; }

        @media screen and (max-width: 900px) {
          margin: auto auto 7px; } }

      .activeValidation {
        border-bottom: 2px solid $orange; } }

    .card-header {
      position: absolute;
      top: 0;
      left: 0;
      display: table;

      span {
        color: $white;
        padding: 4px 8px;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        text-transform: uppercase; }

      @include media($mobile) {
        left: 23px; } }

    .availability {
      border-radius: 25.5px;
      background-color: $light-blue;
      padding: 15px;
      margin: 0 0 0 18px;
      font-size: 17px;
      font-weight: bold;
      text-transform: none;
      letter-spacing: 1px;
      position: relative;
      right: 0;
      outline: none;

      @include media($desktop-lg) {
        padding: 14px 20px; }

      @media screen and (max-width: 900px) {
        margin: 24px 0 32px;
        padding: 14px 60px;
        position: relative; } }


    .link {
      position: absolute;
      bottom: 12px;
      font-weight: bold;
      text-decoration: underline;

      @media screen and (max-width: 900px) {
        bottom: 5px; } }

    @media screen and (max-width: 900px) {
      flex-direction: column;
      height: 100%; }

    @include media($mobile) {
      top: 35px;
      box-shadow: 0 1px 0 0 #e7e7e7; } }

  @include media($mobile) {
    box-shadow: inset 1px 1px 1px 0px #e7e7e7; }


  .gas-heading {
    flex-direction: column; }

  .validation {
    margin: 10px 0;
    font-weight: bold;
    position: absolute;
    color: $orange;

    @media screen and (max-width: 900px) {
      position: relative;
      text-align: center;
      display: flex;
      width: 100%;
      justify-content: center;
      margin: 0; } }

  .twitter-typeahead, .tt-hint, .tt-input, .tt-menu {
    width: 100%;

    @media screen and (max-width: 900px) {
      width: 220px; }

    @include media($desktop-lg) {
      width: 330px; } }

  .tt-menu {
    height: auto;
    max-height: 250px;
    overflow-y: auto;
    top: 109% !important;
    border: solid 1px #f1f1f1;

    .tt-suggestion {
      padding: 22px 32px;
      border: none;

      &:hover, &:focus {
        background: #eef5f8;
        color: #00a3e0;
        outline: none; } } } }

.hero-image {
  margin-bottom: 20px;

  .row {
    @include outer-wrap;
    display: flex;
    justify-content: center;
  }

  .col-10 {
    width: 100%;

    @include media($tablet) {
      @include span-columns(10 of 12);
    }

  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit:cover';

    @include media($tablet) {
      height: 400px;
    }
  }

  @include media($tablet) {
    margin-bottom: 55px;
  }
}

@charset "UTF-8";

/// Hides the text in an element, commonly used to show an image instead. Some
/// elements will need block-level styles applied.
///
/// @link http://goo.gl/EvLRIu
///
/// @example scss
///   .element {
///     @include hide-text;
///   }
///
/// @example css
///   .element {
///     overflow: hidden;
///     text-indent: 101%;
///     white-space: nowrap;
///   }

@mixin hide-text {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}

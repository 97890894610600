.form {
  &-field {
    margin: 0 5px;
    width: 100%;

    @include media($tablet) {
      margin: 0 0 20px 0;
    }

    .container {
      margin: 0;
      display: flex;
      flex-direction: column;
      position: relative;

      @include media($tablet) {
        flex-direction: row;
      }

      .col-8 {
        width: 100%;

        @include media($tablet) {
          width: 63%;
        }

        @include media($desktop) {
          @include span-columns(7 of 12);
        }
      }

      .col-4 {
        position: relative;
        margin-bottom: 24px;
      }
    }
  }

  &-container {
    .row {
      margin-top: 24px;
      padding-bottom: 24px;

      &:first-child {
        @include media($tablet) {
          margin-top: 0;
        }
      }

      @include media($tablet) {
        margin-top: 32px;
      }

      label {
        margin: 0 0 15px;

        &.disable {
          color: #ececec;
        }

        .consumption {
          position: absolute;
          color: $dark-blue;
          bottom: 0;
          width: 100%;
          display: none;
          justify-content: center;
          font-size: 14px;
          font-weight: normal;

          &:before {
            content: '';
            background: url('../../../assets/icons/fire.svg');
            width: 14px;
            height: 14px;
            display: inline-flex;
            background-repeat: no-repeat;
          }

          @include media($tablet) {
            width: auto;
            position: relative;
            float: right;
            align-items: center;
          }
        }
      }

      .label {
        display: flex;
        margin: 0;
        padding: 5px;
        flex-direction: column;
        height: 56px;
        font-size: 13px;
        font-weight: normal;
        justify-content: center;
        align-items: center;
        border: 2px solid $grey;
        border-right: 1px;
        border-radius: 4px;

        &:last-child {
          border-right: 2px solid $grey;
        }

        @include media($mobile-sm) {
          font-size: 12px;
        }

        @include media($tablet) {
          padding: 8px 15px;
          font-size: 17px;
          height: 64px;
        }
      }

      .two-btn {
        width: 375px;
      }

      .three-btn {
        width: 250px;
      }

      .four-btn {
        width: 186px;
      }

      &-wrapper {
        display: flex;
        width: 100%;

        &#building label {
          display: inline-block;
          text-align: center;

          @include media($tablet) {
            padding: 1px;
          }

          @include media($desktop-lg) {
            padding: 6px 20px;
          }
        }
      }

      .p3 {
        text-align: center;

        @include media($tablet) {
          text-align: left;
        }
      }
    }

    .reset-btn {
      background-color: $white;
      color: $light-blue;
      font-family: $albert-bold;
      border-radius: 28px;
      border: 2px solid $grey;
      display: flex;
      margin: 32px auto;
      padding: 8px 20px;
      text-transform: none;

      &:focus,
      &:hover {
        outline: none;
        background-color: $white;
        color: $light-blue;
      }
    }
  }
}

input[type="radio"] {
  opacity: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
  position: absolute;
  left: -99999px;

  &:focus + .label {
    outline: 1px dotted lightgrey;
    outline-offset: -1px;
  }

  &:checked+.label {
    background-color: $light-blue;
    color: $white;
    outline: none;
    border: 2px solid $light-blue !important;
  }

  &:disabled+.label {
    color: $grey;
    background-color: $white;
    border: 1.5px solid $grey !important;
  }

  &#newConnectionForExistingHome+.label {
    display: flex !important;
  }

}

.inline {
  display: flex !important;
}

.cost-calculator-form {
  .content {
    @include media($mobile) {
      margin-right: 10px;
      margin-left: 10px;
      margin-bottom: 100px;
    }

    @include media($tablet) {
      padding-left: 0;
      padding-right: 0;
    }

    @include media($desktop) {
      padding-left: 60px;
      padding-right: 60px;
    }

    @include media($desktop-lg) {
      padding-left: 75px;
      padding-right: 75px;
    }
  }
}

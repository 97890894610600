body {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

h1,
h2,
h3,
h4,
h5
 {
  color: $dark-blue;
  font-family: $heading-font-family;
  margin: 0 0 $small-spacing;
}

h1 {
  @include h1;
  font-weight: normal;
}

h2 {
	@include h2;
	font-weight: normal;
}

h3 {
	@include h3;
	font-weight: normal;
}

h4 {
	@include h4;
	font-weight: normal;
}

h5 {
	@include h5;
	font-weight: normal;
}

p {
  @include p2;
  margin: 0 0 $small-spacing;
}

.p1 {
  @include p1;
  margin: 0 0 $small-spacing;
}

.p2 {
  @include p2;
  margin: 0 0 $small-spacing;
}

.p3 {
  @include p3;
  margin: 0 0 $small-spacing;
}

a {
  color: $light-blue;
  text-decoration: none;
  transition: color $base-duration $base-timing;

  &:active,
  &:focus,
  &:hover {
    color: tint($light-blue, 20%);
  }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}

//a text line with the arrow animation
@keyframes arrow-slide {
  0% {
    background-position: -0px;
  }
  25% {
    background-position: 24px;
  }
  26% {
    background-position: -24px;
  }
  55% {
    background-position: 0px
  }
}

.text-link:after {
  content: '';
  background: url('../../../assets/icons/arrow-right.svg');
  width: auto;
  min-width: 24px;
  height: 24px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 0px;
  margin-left: 10px;

  a:hover & {
    @include media($tablet) {
      animation: arrow-slide 1s ease .2s;
    }
  }
}

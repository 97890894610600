.site-navigation {
  @include media($mobile) {
    display: none; }
  margin-top: -44px;
  @include media($desktop) {
    margin-top: -70px; }
  @include media($desktop-lg) {
    margin-top: -85px; }
  .container {
    background: $dark-blue; }
  ul li {
    display: inline-block;
    margin-left: 50px;
  	 @include media($mobile) {
  	   display: grid; } }
  a {
    color: #FFF;
    line-height: 70px;
    font-size: 1.125em;
    &:hover {
      color: $light-blue; } }
  li.active a, a.active {
    color: $light-blue; } }


.site-navigation.site-navigation-light {
  a:hover {
    color: $white; } }
.site-navigation-light {
  .container {
    background: $light-blue; }
  @include media($mobile) {
    margin-right: 0px; }
  ul li::before {
    content: "";
    display: inline-block;
    margin-right: 10px;
    transform: translateY(5px); }
  ul li {
    margin-left: 0;
    &.tab-all::before {
      @include retina-sprite($tab-home-group); }

    &.tab-advice::before {
      @include retina-sprite($advice-tab-group); }
    &.tab-gas::before {
      @include retina-sprite($gas-tab-group); }
    &.tab-trade::before {
      @include retina-sprite($tab-wrench-group); }
    &.tab-news::before {
      @include retina-sprite($news-tab-group); } }
  li.active {
    background: $light-blue; }
  li.active a, a.active {
    color: $white; }
  @include media($mobile) {
    display: block;
    margin: 0 0px;
    ul a {
      border-top: none;
      display: inline-grid;
      margin: 0 -2px;
      padding: 0; } }

  @include media($tablet) {
    display: block;
    ul a {
      border-top: none;
      display: inline-grid;

      li {
        margin-left: 0px;
        padding-left: 30px; } } }
  @include media($desktop) {
    display: block;
    ul {
      background: $dark-blue;
      a {
        border-top: 0;
        display: inline-block;
        text-align: center;
        width: 25%;
        transition: background 0.2s;
        &:hover {
          background: $light-blue; }
        li:hover {
          color: $white; }
        li {
          margin: 0;
          padding: 0;
          width: 100%; } } }
    ul a:not(:first-of-type) {
      border-left: 1px solid white;
      margin-left: -4px; } }
  @include media($desktop-lg); }

.tool-tip-container {
  position: relative;
  margin: 0;

  .tool-tip {
    display: flex;
    width: auto;
    background-color: #eef5f8;
    border-radius: 53.5px;
    padding: 12px 25px;
    margin-bottom: 10px;

    @include media($tablet) {
      margin: 0 0 10px;
    }

    @include media($desktop) {
      width: 415px
    }

    .tip {
      background-color: $purple;
      min-width: 36px;
      height: 36px;
      border-radius: 50%;
      margin: auto 20px auto 0;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        text-transform: uppercase;
        font-size: 11px;
        font-family: $albert-bold;
        color: $white;
        letter-spacing: 1.83px;
      }
    }

    .text {
      p {
        @include p3;
        margin-bottom: 0;

        .link {
          text-decoration: underline;
          font-family: $albert-bold;
        }
      }
    }
  }
}

.mob-phone {
  font-family: $albert-bold;
  color: $dark-blue;

  @include media($tablet) {
    pointer-events: none;
    cursor: default;
  }
}

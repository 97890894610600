.imagetext {
  border: 2px solid #efefef;
  display: block;
  padding: 24px;
  transition: box-shadow 0.2s;

  @include media($desktop) {
    .columns:not(:last-child):not(:nth-child(3n + 3)) & {
      border-right-color: transparent;
    }

    .columns:nth-child(n + 4) & {
      border-top-color: transparent;
    }
  }

  &:hover {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.18);
  }

  &__image {
    margin-bottom: 22px;

    img {
      display: block;
      margin: 0 auto;
      width: 100%;
      height: auto;
      @include media($desktop) {
        height: 190px;
        width: auto;
      }
    }
  }

  &__text {
    margin-bottom: 15px;
    p {
      color: #003c71;
    }
  }

  h3 {
    color : #002c65;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 0;
  }

  p {
    &.caps {
      font-size: 16px;
      font-weight: bold;
      line-height: 32px;
      margin: 0;
      text-transform: uppercase;
    }
  }
}

.news-item-row{
  @include media($desktop) {
    display: flex;
    flex-wrap: wrap;
  }
  .columns{
    @include media($desktop) {
      display: flex;
      flex-direction: column;
    }
  }  
}

.filter {
  margin: 40px 0;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      background: #ffffff;
      border: 2px solid #efefef;
      border-right: 0;
      display: flex;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      transition: background 0.2s;
      width: 25%;
      @include media($tablet) {
        display: list-item;
      }

      a {
        align-self: center;
        color: #003c71;
        display: block;
        flex-basis: 100%;
        line-height: 1.3;
        padding: 5px 0;
        transition: color 0.2s;
        @include media($tablet) {
          align-self: auto;
          flex-basis: auto;
          line-height: inherit;
        }

        &:hover {
          color: #00a3e0;
        }
      }

      &.selected,
      &.selected:last-child {
        background: #003c71;
        border-color: #003c71;

        a {
          color: #ffffff;
        }
      }

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-right: 2px solid #efefef;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}


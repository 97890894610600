// primary buttons
#{$all-buttons} {
  appearance: none;
  background-color: $dark-blue;
  border: 0;
  border-radius: $base-border-radius;
  color: #fff;
  cursor: pointer;
  display: block;
  font-family: $base-font-family;
  font-size: $base-font-size;
  -webkit-font-smoothing: antialiased;
  line-height: 1;
  margin: 0 15px;
  padding: 1em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color $base-duration $base-timing;
  white-space: nowrap;
  @media screen and (min-width: $tablet-screen) {
    display: inline-block;
    font-size: 1em;
    margin: 1em 0;
    padding: 23px 45px;
  }
  @media screen and (min-width: $desktop-screen) {
    font-size: 1em;
  }
  &:hover,
  &:focus {
    color: #fff;
    background-color: tint($action-color, 10%);
    text-decoration: none;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.1;
    &:hover {
      background-color: $action-color;
    }
  }
  &.green {
    background-color: $green;
    &:hover {
      background-color: tint($green, 10%);
    }
  }
}

// secondary
.btn-secondary {
  &:after {
    @include retina-sprite($arrow-right-group);
    content: '';
    display: inline-block;
    margin-left: 18px;
    margin-top: -1px;
    opacity: 1;
    vertical-align: middle;
  }
  &:hover,
  &:hover:after{
    text-decoration: none;
    opacity: .8;
  }
}

// back
.btn-back {
	&:before {
		@include retina-sprite($arrow-left-group);
		content: '';
		margin-right: 18px;
		display: inline-block;
		vertical-align: -1px;
	}
}

.btn-reset {
  content: "X";
  position: absolute;
  right: 0px;
  padding: 5px;
  margin-right: 20px;
  min-height: 30px;
  min-width: 30px;
  z-index: 5000;

  @include media($mobile) {
    margin-top: 15px;
    margin-right: 45px;
  }
}

.image-list {
    padding: 30px 20px;

    ul,
    li {
        border-style: solid;
        border-color: $grey-gallery; }

    ul {
        border-width: 2px 0 0 2px; }

    ul {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        li {
            width: 33.33%;
            border-width: 0 2px 2px 0;
            position: relative;
            box-sizing: border-box;
            a {
                display: flex;
                text-align: center;
                align-items: center;
                justify-content: center;
                &:after {
                    content: "";
                    display: block;
                    padding-top: 100%; } }
            img {
                width: calc(100% - 30px);
                height: auto; } } }

    @include media($tablet) {
        padding: 35px 0;
        .inner {
            width: 65%; }
        ul {
            display: inline-flex;
            flex-wrap: nowrap;
            li {
                width: auto;
                flex: 1; } } } }

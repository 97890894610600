.qanda {
  ul {
    list-style: none !important; }
  li {
    margin-bottom: 15px; }

  a.ec {
    font-size: 1.375em; }
  a.ec:before {
    content: '';
    @include retina-sprite($question-expand-group);
    display: inline-block;
    margin-right: 10px; }
  .active a.ec:before {
    @include retina-sprite($question-collapse-group); }

  .dropdown {
    display: none;
    padding-left: 20px;
    @include padding(40px null); } }

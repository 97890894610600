.main-gas-upgrade-section
{
	.update-date {
		text-decoration: underline;
		font-size: 14px;
	}
	select#select-box {
		background: white;
		outline: 1px solid;
	}
	.tab-navigation {
		margin-bottom: 10px;
		width: 14%;
		margin-top: 20px;
	}
	.tab-content {
		text-align: center;
		line-height: 26px;
		padding-top: 30px;
	}
	#tab-1 {
		background: #fff;
	}
	.mains-upgrade-table {
		tr {
			&:nth-of-type(odd) {
				background: #fafafa;
			}
		}
		td {
			padding: 6px;
			height: 70px;
			text-align: left;
		}
		th {
			font-size: 18px;
			text-align: left;
			padding: 6px;
			height: 70px;
		}
	}
	@media (min-width: 768px) {
		.main-gas-upgrade-container {
			width: 750px;
		}
	}
	@media (min-width: 992px) {
		.main-gas-upgrade-container {
			width: 970px;
		}
	}
	@media (min-width: 1200px) {
		.main-gas-upgrade-container {
			width: 1170px;
			margin-top: 40px;
		}
	}
	@media (max-width:768px) {
		.tab-navigation {
			margin-bottom: 40px;
			width: 95%;
		}
	}
	@media only screen and (max-width: 760px) {
		.mains-upgrade-table {
			table {
				display: block;
			}
			tbody {
				display: block;
			}
			thead {
				display: block;
			}
			td {
				display: block;
				border: none;
				border-bottom: 1px solid #eee;
				position: relative;
				padding-left: 50%;
				height: auto;
				&:before {
					position: absolute;
					top: 6px;
					left: 6px;
					width: 45%;
					padding-right: 10px;
					white-space: nowrap;
					font-weight: bold;
				}
				&:nth-of-type(1) {
					&:before {
						content: "Project Name";
					}
				}
				&:nth-of-type(2) {
					&:before {
						content: "Affected Suburbs	";
					}
				}
				&:nth-of-type(3) {
					&:before {
						content: "Status";
					}
				}
				&:nth-of-type(4) {
					&:before {
						content: "Map";
					}
				}
				&:nth-of-type(5) {
					&:before {
						content: "Site Contractor";
					}
				}
			}
			tr {
				display: block;
				border: 1px solid #ccc;
			}
			th {
				display: block;
			}
		}
		thead {
			tr {
				position: absolute;
				top: -9999px;
				left: -9999px;
			}
		}
	}
	@media (min-device-width: 768px) and (max-device-width: 1024px) {
		.mains-upgrade-table {
			table {
				display: block;
			}
			tbody {
				display: block;
			}
			thead {
				display: block;
			}
			td {
				display: block;
				border: none;
				border-bottom: 1px solid #eee;
				position: relative;
				padding-left: 50%;
				height: auto;
				&:before {
					position: absolute;
					top: 6px;
					left: 6px;
					width: 45%;
					padding-right: 10px;
					white-space: nowrap;
					font-weight: bold;
				}
				&:nth-of-type(1) {
					&:before {
						content: "Project Name";
					}
				}
				&:nth-of-type(2) {
					&:before {
						content: "Affected Suburbs	";
					}
				}
				&:nth-of-type(3) {
					&:before {
						content: "Status";
					}
				}
				&:nth-of-type(4) {
					&:before {
						content: "Map";
					}
				}
				&:nth-of-type(5) {
					&:before {
						content: "Site Contractor";
					}
				}
			}
			tr {
				display: block;
				border: 1px solid #ccc;
			}
			th {
				display: block;
			}
		}
		thead {
			tr {
				position: absolute;
				top: -9999px;
				left: -9999px;
			}
		}
	}
}
.image-content {
  margin-top: 24px;

  @include media($tablet) {
    margin: 0 0 70px !important;
  }

  #image-content-card {
    padding: 0;
    display: block;

    .image {
      width: 100%;
      height: 350px;
      object-position: top right;
      font-family: "object-position: top right;";

      @include media($tablet) {
        height: 480px;
      }
    }

    .results-section-title {
      margin-bottom: 18px;

      @include media($tablet) {
        margin-bottom: 42px;
      }
    }

    .results-module {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    #col-6 {
      width: 100%;
      margin-bottom: 16px;

      @include media($tablet) {
        @include span-columns(6 of 12);
        margin-right: 0;
        margin-bottom: 32px;
        height: auto;
        display: flex;
      }

      .image {
        height: 200px;

        @include media($tablet) {
          height: 225px;
        }
      }

      .card-title {
        @include h4;
        line-height: 1.09;
        margin-bottom: 0;
      }

      .content-card__card-body {
        padding-bottom: 30px;
        padding-top: 10px;

        &::before {
          right: 7px;
          top: 7px;
          background-size: 50%;
        }
      }

      .content-card__card-link {
        display: none;
      }

      .image-card__card {
        margin-top: -50px;

        @include media($tablet) {
          width: 72%;
        }
      }
    }

    .image-card__card {
      width: 90%;
      position: relative;
      margin-top: -100px;

      @include media($tablet) {
        width: 70%;
        margin-top: -150px;
      }
    }

    .content-card {
      margin-left: 0;
      position: relative;
      margin-bottom: 0;

      &__card-header {
        background: rgba(0, 0, 0, 0.15);
      }

      &__card-body {
        padding-bottom: 30px;

        &::before {
          content: '';
          background: url('../../../assets/icons/dollar-sign.svg') no-repeat;
          width: 48px;
          height: 48px;
          position: absolute;
          right: 7px;
          top: 7px;
          background-size: 70%;
          background-position: right top;

          @include media($tablet) {
            right: 13px;
            top: 12px;
            background-size: 100%;
          }
        }

        @include media($tablet) {
          padding-bottom: 45px;
          padding-top: 35px;
        }

        .card-title {
          color: $white;
          line-height: 1.08;
          margin-bottom: 0;

          @include media($tablet) {
            line-height: 1.22;
          }
        }
      }

      &__card-link {
        @include p2;
        color: $white;
        position: relative;
        margin-left: 0;
        margin-top: 18px;

        @include media($tablet) {
          margin-top: 25px;
        }
      }
    }

    .text-link:after {
      background: url('../../../assets/icons/arrow-right-white.svg');
      background-repeat: no-repeat;
      background-size: 24px;
      background-position: 0px;
    }
  }

  a:hover {
    .dollar-sign {
      transform: none !important;
    }
  }
}

#results-module {
  display: none;
}

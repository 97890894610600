html {
  box-sizing: border-box;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
    //overflow: hidden;
}

// Applies the containing wrap with responive inner padding
@mixin inner-wrap($local-max-width: $max-width) {
    @include clearfix;
    max-width: $local-max-width;
    margin: {
        left: auto;
        right: auto;
    }
    @include media($tablet) {
        padding-left: $gutter-tablet;
        padding-right: $gutter-tablet;
    }
    @include media($desktop) {
        padding-left: $gutter-desktop;
        padding-right: $gutter-desktop;
    }
    @include media($desktop-lg) {
        padding-left: $gutter-desktop-lg;
        padding-right: $gutter-desktop-lg;
    }
}

// Outer wrap that responsivly applies the gutter margins
@mixin outer-wrap() {
    @include outer-container;
    @include media($tablet) {
        margin-left: $gutter-tablet;
        margin-right: $gutter-tablet;
    }
    @include media($desktop) {
        margin-left: $gutter-desktop;
        margin-right: $gutter-desktop;
    }
    @include media($desktop-lg) {
        margin-left: auto;
        margin-right: auto;
    }
}

// Used to apply a mobile gutter to elements that need the border spacing
@mixin mobile-gutter() {
    @include media($mobile){
        margin-left: $gutter-mobile;
        margin-right: $gutter-mobile;
    }
}

@charset "UTF-8";

/// Provides a quick method for targeting `padding` on specific sides of a
/// box. Use a `null` value to “skip” a side.
///
/// @argument {arglist} $values
///   List of padding values, defined as CSS shorthand.
///
/// @example scss
///   .element {
///     @include padding(12vh null 10px 5%);
///   }
///
/// @example css
///   .element {
///     padding-bottom: 10px;
///     padding-left: 5%;
///     padding-top: 12vh;
///   }
///
/// @require {mixin} _directional-property

@mixin padding($values...) {
  @include _directional-property(padding, false, $values...);
}

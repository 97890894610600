.cols-1 {
	padding: 12px;
	float: left;
	width: 8.33333333%;
}
.cols-2 {
	padding: 12px;
	float: left;
	width: 16.66666667%;
}
.cols-3 {
	padding: 12px;
	float: left;
	width: 25%;
}
.cols-4 {
	padding: 12px;
	float: left;
	width: 31.76157%;
}
.cols-5 {
	padding: 12px;
	float: left;
	width: 41.66666667%;
}
.cols-6 {
	padding: 12px;
	float: left;
	width: 50%;
}
.cols-7 {
	padding: 12px;
	float: left;
	width: 58.33333333%;
}
.cols-8 {
	padding: 12px;
	float: left;
	width: 65.88078%;
}
.cols-9 {
	padding: 12px;
	float: left;
	width: 75%;
}
.cols-10 {
	padding: 12px;
	float: left;
	width: 83.33333333%;
}
.cols-11 {
	padding: 12px;
	float: left;
	width: 91.66666667%;
}
.cols-12 {
	padding: 12px;
	float: left;
	width: 100%;
}

.row {
	margin-right: -15px;
	margin-left: -15px;
	&:before {
		display: table;
		content: " ";
	}
	&:after {
		display: table;
		content: " ";
		clear: both;
	}
}	


@media (max-width:768px) {
	.cols-1 {
		position: relative;
		min-height: 1px;
		padding: 12px;
		width: 100%;
	}
	.cols-2 {
		position: relative;
		min-height: 1px;
		padding: 12px;
		width: 100%;
	}
	.cols-3 {
		position: relative;
		min-height: 1px;
		padding: 12px;
		width: 100%;
	}
	.cols-4 {
		position: relative;
		min-height: 1px;
		padding: 12px;
		width: 100%;
	}
	.cols-5 {
		position: relative;
		min-height: 1px;
		padding: 12px;
		width: 100%;
	}
	.cols-6 {
		position: relative;
		min-height: 1px;
		padding: 12px;
		width: 100%;
	}
	.cols-7 {
		position: relative;
		min-height: 1px;
		padding: 12px;
		width: 100%;
	}
	.cols-8 {
		position: relative;
		min-height: 1px;
		padding: 12px;
		width: 100%;
	}
	.cols-9 {
		position: relative;
		min-height: 1px;
		padding: 12px;
		width: 100%;
	}
	.cols-10 {
		position: relative;
		min-height: 1px;
		padding: 12px;
		width: 100%;
	}
	.cols-11 {
		position: relative;
		min-height: 1px;
		padding: 12px;
		width: 100%;
	}
	.cols-12 {
		position: relative;
		min-height: 1px;
		padding: 12px;
		width: 100%;
	}
	.col-reverse {
		flex-direction: column-reverse;
	}
}

@media (min-width: 768px) {
	.generic-container {
		width: 750px;
	}
}
@media (min-width: 992px) {
	.generic-container {
		width: 970px;
	}
}
@media (min-width: 1200px) {
	.generic-container {
		width: 1170px;
		margin-top: 40px;
	}
}


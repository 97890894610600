@mixin contact-link {
    font-family: FSAlbert;
    font-size: 16px;
    @include media($desktop) {
        font-size: 18px; } }

.contact-process {
    @include outer-wrap;
    @include inner-wrap;

    &__link {
        &--emergency {
            @include contact-link;
            position: relative;
            font-weight: bold;
            color: $light-blue;

            &:after {
                transition: translate .2s ease;
                position: absolute;
                top: 5px;
                right: -18px;
                content: '';
                @include retina-sprite($arrow-right-group);
                display: inline-block;

                @include media($tablet) {
                  display: none; }
                @include media($desktop-lg) {
                  display: inline-block; } }


            &:hover {
                &:after {
                    transform: translateX(2px); } } }

        &--corporate {
            @include contact-link;
            color: $dark-blue; } }

    > ul {
        margin-bottom: 20px;

        > li {
            padding: 5px 0 10px;
            @include media($tablet) {
                display: inline-block;
                width: 30%;
                vertical-align: top;
                padding: 0;
                margin-right: 4%; }

            &:last-of-type {
                margin-right: 0; }

            .toggle {
                font-family: $bree;
                font-size: 22px;
                font-weight: normal;
                display: block;
                color: $dark-blue;
                @include media($tablet) {
                    margin-bottom: 16px; }
                @include media($desktop-lg) {
                    font-size: 28px; }

                &:after {
                    content: '';
                    @include retina-sprite($arrow-down-group);
                    display: inline-block;
                    margin: 0 0 0 10px; }

                &.open:after {
                    @include retina-sprite($arrow-up-group); }

                @include media($tablet) {
                    &:after {
                        display: none; } }

                a {
                    font-family: $bree; }

                + div {
                    display: none;
                    padding-top: 16px;
                    @include media($tablet) {
                        padding-top: 0;
                        display: block !important;
                        height: auto !important; }

                    p {
                        margin: 0 0 4px; } } }

            .stack {
                @include media($tablet) {
                    margin-top: 40px; } } } } }

.content {
  @include outer-wrap;
  @include inner-wrap;
  margin-bottom: $module-spacing;

  &--flat {
    margin-bottom: 25px; }

  h1 {
    color: $dark-blue; }

  h2 > span:before {
    content: '';
    margin: 0 10px -7px auto;
    display: inline-block; }
  h2 > span.fridge:before {
      @include retina-sprite($heading-fridge-group); }
  h2 > span.shower:before {
      @include retina-sprite($heading-shower-group); }
  h2 > span.washer:before {
      @include retina-sprite($heading-washer-group); }
  h2 > span.light:before {
      @include retina-sprite($heading-light-group); }
  h2 > span.heater:before {
      @include retina-sprite($heading-heater-group); }

  h3 > span:before,h4 > span:before {
    content: '';
    margin: 0 10px -14px auto;
    display: inline-block; }
  h3 > span.fridge:before,h4 > span.fridge:before {
      @include retina-sprite($heading-fridge-group); }
  h3 > span.shower:before,h4 > span.shower:before {
      @include retina-sprite($heading-shower-group); }
  h3 > span.washer:before,h4 > span.washer:before {
      @include retina-sprite($heading-washer-group); }
  h3 > span.light:before,h4 > span.light:before {
      @include retina-sprite($heading-light-group); }
  h3 > span.heater:before,h4 > span.heater:before {
      @include retina-sprite($heading-heater-group); }

  ul, ol {
    line-height: 1.875em;
    padding-left: 1em; }
  ul {
    list-style-type: disc; }
  ol {
    list-style-type: decimal; }
  &.contact {
    margin-bottom: 20px; } }

.tab-component {
  margin: 0 24px $module-spacing;

  @include media($tablet) {
    margin: 0 0 $module-spacing;
  }

  nav {
    @include outer-wrap;

    ul {
      display: flex;

      .tab-name {
        display: block;
        width: 50%;

        &:focus {
          outline: none;
        }

        .tabs {
          @include h4;
          cursor: default;
          display: block;
          background: #eef5f8;
          color: $dark-blue;
          font-family: $bree;
          text-align: center;
          line-height: 60px !important;

          &.left {
            border-radius: 4px 0 0 4px;
          }

          &.right {
            border-radius: 0 4px 4px 0;
          }

          &.active {
            background: $light-blue;
            color: $white;
          }
        }
      }
    }
  }

  .tab-content {
    margin: 0 auto;

    .content {
      display: none;
      padding: 0;

      @include media($desktop-lg) {
        padding: 60px;
      }
    }
  }
}

.page-header {
  &.no-margin {
    @include media($desktop) {
      padding-bottom: 38px; }
    @include media($desktop-lg) {
      padding-bottom: 33px; }
    h1 {
      @include media($desktop) {
        margin-top: 194px; }
      @include media($desktop-lg) {
        margin-top: 224px; } }
    &:before {
      @include media($desktop) {
        bottom: 0; }
      @include media($desktop-lg) {
        bottom: 0; } } }

  padding-bottom: 60px;
  @include media($mobile) {
 }    // padding-bottom: 20px
  padding: 0 40px 20px 40px;
  text-align: center;
  overflow: hidden;
  z-index: -1;
  position: relative;
  @include media($tablet) {
    padding-bottom: 80px; }
  @include media($desktop) {
    padding-bottom: 88px; }
  @include media($desktop-lg) {
    padding-bottom: 100px; }
  &:before {
    content: '';
    background: url(../img/hero-left.png) no-repeat -16% 152%;
    width: 515px;
    height: 720px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -2;
    background-size: 64% 64%;
    @include media($tablet) {
      background: url(../img/hero-left.png) no-repeat bottom left;
      background-size: 70% 70%;
      bottom: 0px; }
    @include media($desktop) {
      background-size: auto 54%;
      bottom: 36px; }
    @include media($desktop-lg) {
      bottom: 53px; } }

  img {
    max-width: none;
    height: 400px;
    z-index: -5;
    position: absolute;
    margin-left: -384px;
    @include media($tablet) {
      height: 425px;
      margin-left: -630px; }
    @include media($desktop) {
      height: 320px; }
    @include media($desktop-lg) {
      height: 345px;
      margin-left: -1000px; } }

  picture {
    z-index: -5; }

  h1 {
    margin-top: 180px;
    @include media($mobile) {
      font-size: 26px;
      margin-top: 100px; }

    @include media($tablet) {
      margin-top: 210px; }
    @include media($desktop) {
      margin-top: 180px; }
    @include media($desktop-lg) {
      margin-top: 210px; } } }

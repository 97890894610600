.main-renewal-list {
  margin-bottom: $module-spacing;

  @include media($mobile) {
    @include margin(null 10px); }

  .inner {
    @include inner-wrap;
    border: 1px solid #ccc;
    @include padding(50px null);
    @include media($mobile) {
      @include padding(10px null); } }

  select {
    width: 46%;
    display: inline-block;
    &:last-of-type {
      margin-left: 7%; }
    @include media($mobile) {
      width: 100%;
      margin: 0;
      &:last-of-type {
        margin: 10px 0 0 0; } } }

  .list {
    margin-top: 40px;
    @include media($mobile) {
      margin-top: 20px; } }
  .list a {

    border-bottom: 1px solid #ccc;
    display: block;
    @include padding(20px null);
    position: relative;

    &:after {
      @include retina-sprite($arrow-right-group);
      content: '';
      display: block;
      position: absolute;
      top: 45%;
      right: 20px; }
    p {
      margin: 0;
      color: $dark-blue; }
    h4 {
      margin: 0;
      font-weight: normal; }

    img {
      float: left;
      margin-right: 10px;
      display: block; } } }

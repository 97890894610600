.breadcrumbs {
  margin-bottom: $module-spacing / 2;
  ul {
    @include outer-wrap;
    @include inner-wrap;
    @include margin(15px null 10px null);
    li {
      display: inline-block;
      margin-left: 10px;
      @include media($mobile) {
        margin-left: 5px; } }
    li:first-child {
      margin-left: 0; }
    li a {
      &:hover {
        text-decoration: none; }
      &:before {
        content: '>';
        float: right;
        color: $dark-blue;
        margin-left: 10px;
        @include media($mobile) {
          margin-left: 5px; } } }
    li .back-link {
      font-size: 15px;
      font-weight: bold;
      color: $dark-blue;

      @include media($tablet) {
        font-size: 17px; }
      &:before {
        content: '';
        background: url("../../../assets/icons/arrow-left.svg");
        width: 20px;
        height: 20px;
        float: left;
        @include margin(-2px 5px 0 0); } } } }

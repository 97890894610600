@charset "UTF-8";

/// Switches between two colors based on the lightness of a another color. Great
/// for building button styles.
///
/// @argument {color} $base-color
///   The color to evaluate lightness against.
///
/// @argument {color} $dark-color [#000]
///   The color to be output when `$base-color` is light.
///
/// @argument {color} $light-color [#fff]
///   The color to be output when `$base-color` is dark.
///
/// @return {color}
///
/// @example scss
///   .first-element {
///     color: contrast-switch(#bae6e6);
///   }
///
///   .second-element {
///     $button-color: #2d72d9;
///     background-color: $button-color;
///     color: contrast-switch($button-color, #222, #eee);
///   }
///
/// @example css
///   .first-element {
///     color: #000;
///   }
///
///   .second-element {
///     background-color: #2d72d9;
///     color: #eee;
///   }
///
/// @require {function} _is-light
///
/// @since 5.0.0

@function contrast-switch(
    $base-color,
    $dark-color: _bourbon-get-setting("contrast-switch-dark-color"),
    $light-color: _bourbon-get-setting("contrast-switch-light-color")
  ) {

  @return if(_is-light($base-color), $dark-color, $light-color);
}

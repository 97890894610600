.cta-card {
  @include outer-wrap;
  display: inline-flex;
  position: relative;
  width: 100%;
  margin-bottom: 100px;

  @include media($tablet) {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 42px;
	justify-content: center;
  }

  &-item {
    a {
      &:focus, &:active {
        outline: none;
      }
    }

    .card {
      position: relative;
      height: 100%;
      width: auto;

      &-header {
        display: table;
        color: $white;
        text-transform: uppercase;
        font-size: 12px;
        padding: 3px 7px;
        margin: -2px 0 0 -2px;
      }

      &-body {
        padding: 10px 50px 25px 18px;

        @include media($tablet) {
          padding: 25px 50px 45px 32px;
        }

        @include media($desktop-lg) {
          padding: 25px 75px 45px 32px;
        }
      }

      &-title {
        line-height: normal;
      }
    }

    .text-link {
      position: absolute;
      right: 0;
      bottom: 15px;
      padding-right: 24px;

      @include media($tablet) {
        padding-right: 32px;
      }

      @include media($desktop-lg) {
        bottom: 25px;
      }
    }
  }
}

/* Slider */
.cta-card-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;

  @include media($tablet) {
    overflow: visible;
  }

  @include media($desktop) {
    overflow: hidden;
  }

  .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    @include media($tablet) {
      overflow: visible;
    }

    &:focus {
      outline: none;
    }

    &.dragging {
      cursor: pointer;
      cursor: hand;
    }
  }

  .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: flex;
    overflow: hidden;

    @include media($tablet) {
      width: auto !important;
    }
  }

  .slick-slide {
    position: relative;
    width: 350px !important;
    margin: 0 5px;
    height: auto;
    border: 2px solid $dark-blue;

    @include media($mobile-sm) {
      width: 330px !important;
    }

    @include media($tablet) {
      width: 390px !important;
      margin: 0 10px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    @include media($desktop-lg) {
      margin: 0 16px;
    }
  }
}


$tooltip-icon-size: 20px !default;

$tooltip-close-color: #003C71 !default;
$tooltip-close-padding: 0.25rem 0.75rem !default;
$tooltip-close-font-size: 20px !default;

$tooltip-message-padding: 1.25rem 1.5rem !default;
$tooltip-message-bg-color: #FBFBFB !default;
$tooltip-message-border-color: #D9D9D9 !default;
$tooltip-message-box-shadow:	1px 1px 2px 1px rgba(black, 0.04) !default;
$tooltip-message-border-radius:	4px !default;
$tooltip-message-z-index: 900 !default;

$tooltip-arrow-size: 16px !default;

$transition-base: .2s ease;

.tooltip {
  display: inline-block;
  position: relative;
  vertical-align: middle;

  .icon {
    display: block;
  }

  &__icon,
  &__close {
    &,
    &:focus {
      outline: none;
    }
  }

  &__icon {
    border: 0;
    width: $tooltip-icon-size;
    height: $tooltip-icon-size;
    cursor: pointer;
    transition: $transition-base;
    font-weight: normal;
    padding: 0;
    position: relative;
    background: none;
    margin: 0;

    &:hover,
    &:focus {
      background: none;
      transform: scale(1.2);
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    background: none;
    cursor: pointer;
    color: $tooltip-close-color;
    padding: $tooltip-close-padding;
    font-size: $tooltip-close-font-size;
    margin: 0;

    &:hover {
      background: none;
      color: $tooltip-close-color;
    }
  }

  &__message {
    min-width: 200px;
    background-color: $tooltip-message-bg-color;
    border: 1px solid $tooltip-message-border-color;
    border-radius: $tooltip-message-border-radius;
    box-shadow: $tooltip-message-box-shadow;
    padding: $tooltip-message-padding;
    position: absolute;
    z-index: $tooltip-message-z-index;
    top: 100%;
    left: calc(100% + #{$tooltip-arrow-size});
    // Show/hide transition
    transition: $transition-base;
    opacity: 0;
    transform: translateY(30px);
    pointer-events: none;

    @include media($tablet) {
      top: -$tooltip-arrow-size;
      transform: translateX(30px);
    }

    &.show {
      opacity: 1;
      transform: translate(0);
      pointer-events: auto;
    }

    &:before {
      content: '';
      top: $tooltip-arrow-size;
      left: -($tooltip-arrow-size / 2);
      position: absolute;
      transform: rotate(45deg);
      width: $tooltip-arrow-size;
      height: $tooltip-arrow-size;
      background-color: $tooltip-message-bg-color;
      border: 1px solid $tooltip-message-border-color;
      border-top: 0;
      border-right: 0;
      border-bottom-left-radius: $tooltip-message-border-radius;
      z-index: $tooltip-message-z-index + 1;
      opacity: 0;

      @include media($tablet) {
        opacity: 1;
      }
    }
  }
}

.image-heading {
  background-color: $polar-blue;
  @include media($desktop) {
    margin-top: -36px; }
  @include media($desktop-lg) {
    margin-top: -53px; }

  .image {
    display: inline-block;
    @include media($desktop) {
        margin-top: -40px; }
    @include media($desktop-lg) {
        margin-top: -40px; } }

  .text {
    padding: 30px 20px;
    text-align: center;
    @include media($desktop) {
        max-width: 730px;
        margin: 0 auto; }
    @include media($desktop-lg) {
        padding-top: 38px;
        padding-bottom: 50px; } }

  h1 {
    color: $dark-blue;
    @include media($mobile) {
        font-size: 2.4em; } } }

.secondary-content-card {
  margin: 70px 0;
  position: relative;

  .title {
    margin-bottom: 54px;

    @include media($mobile) {
      padding-left: 0;
      margin-bottom: 15px;
      text-align: center; } }

  .container {
    &:hover {
      .slick-arrow {
        &.slick-next, &.slick-prev {
          transform: translateX(0%); } } } }

  .content-card {
    position: relative;
    width: auto !important;

    &__image {
      overflow: hidden;

      @include media($tablet-lg) {
        width: max-content; }

      img {
        width: 416px;
        height: 224px;
        object-fit: cover;
        object-position: top left;
        font-family: "object-fit: cover;object-position: top left;";

        -moz-transition: all 0.2s ease;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;

        @include media($mobile) {
          width: 320px; } } }

    a:hover img {
      @include media($tablet) {
        -moz-transform: scale(1.05);
        -webkit-transform: scale(1.05);
        transform: scale(1.05); } }

    &__card {
      background: $white;
      width: 85%;
      top: 82%;
      height: auto;
      position: absolute;

      &-header {
        text-transform: uppercase;
        display: table;

        span {
          padding: 2px 8px;
          color: $white;
          font-size: 12px;
          font-family: $albert-bold;
          letter-spacing: 1px; } }

      &-body {
        padding: 20px 30px;

        @include media($mobile) {
          padding: 16px; }

        @include media($tablet-lg) {
          padding: 20px; }

        .card-title {
          line-height: 1.1em;

          @include media($mobile) {
            line-height: 1.4em; } } } }

    @include media($mobile) {
      position: relative;
      margin-right: 12px;
      margin-left: 0; }

    @include media($tablet-lg) {
      margin: 0 16px 0 0; }

    @include media($desktop) {
      margin: 0 16px 70px; } }

  @include media($mobile) {
    padding: 0 0 0 20px;
    margin-top: 50px; } }

/* Slider */
.secondary-slider {
  display: flex;
  height: 330px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;

  @include media($desktop) {
    height: auto; }

  @include media($mobile) {
    height: 300px;
    padding-left: 0; }

  .slick-list {
    position: relative;
    overflow: visible;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
      outline: none; }

    &.dragging {
      cursor: pointer;
      cursor: hand; }

    @include media($mobile) {
      overflow: hidden;
      height: auto !important; } }

  .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: flex;

    .slick-loading & {
      visibility: hidden; } }

  .slick-arrow {
    background-color: $light-blue;
    padding: 0px;
    margin: auto;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    position: absolute;
    top: 41%;
    z-index: 9999;
    transition: all .5s ease-in-out;

    &.slick-next {
      right: -25px;
      transform: translate(25px,0);

      &::after {
        content: '';
        background: url('../../../assets/icons/chevron-right-white.svg');
        width: 24px;
        height: 24px;
        display: flex;
        background-repeat: no-repeat;
        margin-left: 5px;
        position: relative; } }

    &.slick-prev {
      left: -25px;
      transform: translate(-30px,0);

      &::before {
        content: '';
        background: url('../../../assets/icons/chevron-left-white.svg');
        width: 24px;
        height: 24px;
        display: flex;
        background-repeat: no-repeat;
        margin-left: 20px;
        position: relative; } }

    &.slick-disabled {
      display: none !important; }

    &:hover,&:focus {
      outline: none; } } }

.secondary-slider.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.content-card {
  float: left;
  height: 100%;
  min-height: 1px;

  img {
    display: block; }

  &.dragging img {
    pointer-events: none; }

  .slick-initialized & {
    display: block;
    outline: none; }

  .slick-loading & {
    visibility: hidden; } }

.slick-arrow.slick-hidden {
  display: none; }

.connection-process.appliances {
  margin-top: 50px;
  margin-bottom: 50px;

  @include media($tablet) {
    nav ul li {
      width: 50%; } }

  // Icons
  .icons {
    display: flex;
    display: -webkit-flex;
    justify-content: space-around;
    -webkit-justify-content: space-around;
    padding: 20px 0 0 0;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    &:after {
      display: none; } }
  .icons a {
    color: $dark-blue;
    font-size: 1.125em;
    font-family: $bree;
    display: inline-block;
    align-self: flex-end;
    -webkit-align-self: flex-end;
    text-align: center;
    @include media($mobile) {
      padding: 10px; }
    &:before {
      // +icon
      display: block;
      content: '';
      @include margin(0 auto 10px auto); } }

  .hot-water:before {
    @include retina-sprite($tab-shower-lg-on-group); }
  .heating-cooling:before {
    @include retina-sprite($tab-heating-lg-on-group); }
  .cooling:before {
    @include retina-sprite($tab-cooling-lg-on-group); }
  .cooking:before {
    @include retina-sprite($tab-cooking-lg-on-group); }
  .laundry:before {
    @include retina-sprite($tab-laundry-lg-on-group); }
  .outdoors:before {
    @include retina-sprite($tab-outdoors-lg-on-group); }


  @include media($mobile) {
    .content {
      background: #fff; }
    .icons {
      justify-content: initial; }
    .icons a {
      padding: 0;
      width: 50%;
      float: left;
      margin-bottom: 40px; } } }

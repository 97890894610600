.cost-pop-up {
  .container {
    margin: auto;
    display: flex;
    flex-direction: column;
    position: relative;

    @include media($tablet) {
      flex-direction: row;
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  .row {
    width: 100%;
    padding: 23px;
    margin-left: 0px;

    @include media($tablet) {
      @include span-columns(6 of 12);
      padding: 36px 32px;
    }

    &.first-column {
      background-color: $white;
      color: $dark-blue;
      margin-right: 0;

      @include media($tablet) {
        width: 50%;
      }

      @include media($desktop) {
        width: 50%;
      }

      .total-cost {
        display: inline-flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        &:last-child {
          margin-top: 30px;
        }

        p {
          @include p2;
          font-weight: bold;
          margin-right: 10px;
          margin-bottom: 0;
        }
      }
    }

    &.second-column {
      background-color: $light-blue;
      color: $white;

      @include media($tablet) {
        width: 50%;
      }
      
      @include media($desktop) {
        width: 50%;
      }

      h2, h3, h4 {
        color: $white;
      }
    }

    &-logo {
      width: 55px;
      height: 55px;
      margin-bottom: 5px;
      object-fit: contain;
      object-position: center;
      font-family: "object-fit: contain;object-position: center;";
    }

    & .desc {
      margin-bottom: 30px;
    }

    & h2, h4 {
      margin-bottom: 0;
    }

    & .gas-charges, .electricity-charges {
      justify-content: flex-end;

      h4 {
        line-height: normal;
      }
    }

    & .appliance-usage-charge {
      display: none;
      justify-content: space-between;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 20px;
      }

      .cost-usage-charges {
        position: relative;
        bottom: 0;
        right: 0;
      }
    }

    & .fixed-usage-charges {
      display: inline-flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 90px;

      & .charge {
        display: flex;
        font-weight: bold;
      }

      & .p2 {
        margin-bottom: 0;
      }
    }
  }

  &.modal {
    display: none;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }

    & .modal-content {
      position: absolute;
      margin: 60px auto 0;
      top: 0;
      padding: 0;
      width: 100%;
      max-height: calc(100vh - 60px);
      overflow-y: auto;
      -ms-overflow-style: none;

      @include media($tablet) {
        position: relative;
        width: 750px;
        max-height: calc(100vh - 200px);
        top: 100px;
        margin: 80px auto;
      }

      @include media($desktop) {
        width: 864px;
      }

      .close {
        position: absolute;
        color: $dark-blue;
        right: 16px;
        top: 20px;
        z-index: 99999;
        font-size: 30px;

        &:hover, &:focus {
          color: $dark-blue;
          text-decoration: none;
          cursor: pointer;
        }

        @include media($tablet) {
          color: $white;
          right: 32px;
          top: 0;

          &:hover, &:focus {
            color: $white;
          }
        }
      }

      &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
      }
    }
  }
}



.rebate-offer-cta {
  border-radius: 4px;
  border: solid 2px $light-blue;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 16px;
  margin-top: 24px;

  @include media($desktop) {
    padding: 24px;
    margin-top: 32px;
  }
}

.rebate-offer-cta__image {
  width: 25px;
  height: 25px;
  margin-right: 16px;
  flex-shrink: 0;

  @include media($desktop) {
    margin-right: 18px;
    width: 48px;
    height: 48px;
  }
}

.rebate-offer-cta__text-link {
  font-family: $albert-bold;

  span,
  &:after {
    vertical-align: middle;
  }
}
.filter {
  margin: 40px 0;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      background: #ffffff;
      border: 2px solid #efefef;
      border-right: 0;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      transition: background 0.2s;
      width: 25%;

      a {
        color: #003c71;
        display: block;
        padding: 5px 0;
        transition: color 0.2s;

        &:hover {
          color: #00a3e0;
        }
      }

      &.selected,
      &.selected:last-child {
        background: #003c71;
        border-color: #003c71;

        a {
          color: #ffffff;
        }
      }

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-right: 2px solid #efefef;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}

.promo-selection {
  .filter {
	  padding: 0;
    @include media($tablet) {
      margin-left: auto;
      margin-right: auto;
      width: 570px;
    }
    li {
      width: 50%;
    }
  }
  h1 {
    font-family: FSAlbert;
    font-size: 18px;
    font-weight: bold;
    color: #003c71;
  }
  .promos {
    display: none;
    .flex-grid {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -ms-flex-direction: column;
      -webkit-flex-direction: column;
      flex-direction: column;
      padding: 0;
      @include media($tablet) {
        -ms-flex-direction: row;
        -webkit-flex-direction: row;
        flex-direction: row;
        flex-wrap: wrap;
      }
      .promo-tile{
        border: 2px solid #efefef;
        font-family: "Bree Serif";
        font-size: 32px;
        font-weight: normal;
        padding: 30px 30px 50px 30px;
        text-align: center;
        line-height: 1.13;
        position: relative;
        margin-bottom: 30px;
        color: #003c71;

        @include media($tablet) {
          width: 30%;
          margin-right: 5%;
          &:nth-child(3n){
            margin-right: 0;
          }
        }
        .plus{
          color: green;
          display: block;
        }
        .link{
          text-transform: uppercase;
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 20px;
          font-size: 12px;
          font-family: "FSAlbert";
          font-weight: bold;
          color: #00a3e0;
        }
        .badge{
          text-transform: uppercase;
          position: absolute;
          right: -20px;
          top: -20px;
          width: 64px;
          height: 64px;
          font-size: 12px;
          font-family: "FSAlbert";
          color: #ffffff;
          background: #9e007e;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          &.orange{
            background: #e35205;
          }
          &.purple{
            background: #9e007e;
          }
          &.gold{
            background: #F2A900;
          }
        }
        &:hover{
          .link{
            color: #33b5e6;
          }
        }
      }
    }
    p {
      line-height: inherit;
    }
  }
}

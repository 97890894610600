.main-renewal-view {
  margin-bottom: $module-spacing;
  @include inner-wrap;

  p {
    margin-bottom: 0; }
  h4 {
    margin-top: 0.625em; }

  @include media($mobile) {
    margin: 10px; }

  @include media($tablet) {
    .info-left, .info-right {
      float: left;
      width: 50%; } }

  .info {
    padding: 10px;
    @include media($tablet) {
      padding: 20px; }
    @include media($desktop) {
      padding: 40px; }

    border: 1px solid #ccc;
    &:after {
      content: '';
      display: block;
      clear: both; } }

  .map {
    padding: 10px;
    @include media($tablet) {
      padding: 20px; }
    @include media($desktop) {
      padding: 40px; }

    background: #ccc;
    img {
      width: 100%;
      display: block; } }
  .attachments {
    a {
      color: $dark-blue;
      line-height: 22px;
      &:hover {
        color: $blue; } }
    a:before {
      content: 'PDF';
      background: $dark-blue;
      color: #fff;
      font-size: 12px;
      padding: 2px 4px;
      margin-right: 10px;
      font-family: $bree; }
    a.xls:before {
      content: 'XLS'; }
    a.doc:before {
      content: 'DOC'; }
    a.docx:before {
      content: 'DOCX'; }
    a.xlsx:before {
      content: 'XLSX'; } } }

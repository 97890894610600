.cost-calculator {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: fixed;
  margin-left: -25px;
  bottom: 0;
  z-index: 9999;

  @include media($tablet) {
    position: sticky;
    position: -webkit-sticky;
    width: auto;
    top: 15%;
    margin: 0 20px 0 0;
    padding-left: 20px;
    left: 62%;
    flex-direction: column;
  }

  @include media($desktop) {
    left: 60%;
  }

  .row {
    padding: 25px 18px 50px;
    width: 50%;
    display: flex;
    justify-content: space-between;

    @include media($tablet) {
      width: auto;
      flex-direction: column-reverse;
      padding: 25px 25px 50px;
    }

    @include media($desktop) {
      flex-direction: row;
    }

    @include media($desktop-lg) {
      padding: 35px 20px 35px 30px;
    }

    &-logo {
      display: none;

      @include media($tablet) {
        display: flex;
        width: 20%;
        object-fit: contain;
        object-position: right;
        font-family: "object-fit: contain;object-position: right;";
      }

      @include media($desktop-lg) {
        width: 64px;
      }

      &.bill {
        @include media($tablet) {
          width: 15%;
        }
      }
    }

    &.first-row {
      background-color: #eef5f8;
    }

    &.second-row {
      background-color: $light-blue;
      color: $white;

      h2, h4 {
        color: $white;
      }
    }

    .text {
      margin-bottom: 15px;
      width: 100%;

      @include media($tablet) {
        margin-bottom: 0;
        margin-right: 7px;
        padding: 10px;
      }
    }

    .p2 {
      font-weight: bold;
      line-height: normal;
      margin-bottom: 0;

      &-mobile {
        display: flex;
        font-weight: normal;
        margin-bottom: 5px;

        @include media($tablet) {
          display: none;
        }
      }

      &-desktop {
        display: none;

        @include media($tablet) {
          display: flex;
          margin-bottom: 20px;
        }
      }
    }

    & .p3 {
      display: none;

      @include media($tablet) {
        display: flex;
       
      }
    }

    & h2, h4 {
      margin-bottom: 0;
    }

    & .electricity {
      display: inline-flex;
    }

    & .electricity-costs {
      @include p3;
      display: block;
    }

    & .cost-usage-charges {
      position: relative;
      bottom: 0;
      right: 0;
    }
  }

  .cost-breakdown {
    position: absolute;
    bottom: 24px;
    left: 30%;
    display: none;
    background-color: $white;
    color: $light-blue;
    border: 2px solid #ececec;
    border-radius: 28px;
    font-weight: bold;
    font-size: 14px;
    text-transform: none;
    text-align: center;
    width: 185px;
    margin: auto;
    padding: 8px 20px;
    justify-content: center;

    &:active, &:focus, &:hover {
      background-color: $white;
      color: $light-blue;
      outline: none;
    }

    @include media($mobile-sm) {
      left: 25%;
    }

    @include media($tablet) {
      position: relative;
      bottom: 0;
      left: 0;
      margin: 10px auto;
    }
  }
  .subtext-p3{
    font-size: 14px;
    color:#003c71;
    line-height: 16px;
    margin-top: -14px;
  }
  .subtext-p3-white{
    font-size: 14px;
    line-height: 16px;
    margin-top: -14px;
  }
}

.cost-installation-fees {
  display: flex;
  align-items: center;
  span {
    display: none;

    @include media($tablet) {
      display: block;
      margin-left: 5px;
    }
  }

  img {
    margin-left: 5px;

    @include media($tablet) {
      display: none;
    }
  }
}

.cost-usage-charges {
  position: absolute;
  bottom: 4%;
  right: 32px;

  span {
    @include p2;
    margin-left: 5px;
  }

  .electricity-charges {
    display: flex;
    justify-content: space-between;
    @include media($desktop) {
      justify-content: normal;
    }

    img {
      width: 18px;
      height: 20px;
    }

    .electricity-mobile {
      display: none;

      @include media($tablet) {
        display: block;
      }
    }
    @include media($tablet) {
      .p3-electricity-price::before {
        width: 18px;
        height: 20px;
        background: {
          image: url('../../../assets/icons/bolt.svg');
          size: contain;
          repeat: no-repeat;
          position: center;
        }
        content: '';
        display: inline-block;
        @include media($desktop) {
          margin-right: 2.5px;
        }
      } 
    }
  }

  .gas-charges {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
}



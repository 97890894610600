.carousel-content {
  background: url('../../../assets/icons/carousel-temp.svg');
  background-repeat: no-repeat;
  height: 840px;
  overflow: hidden;
  position: relative;

  @include media($mobile) {
    height: 100%;
    margin-bottom: 20px;
  }

  @include media($tablet-lg) {
    height: 780px;
  }

  &:hover {
    .slick-arrow {
      &.slick-next,&.slick-prev {
        transform: translateX(0%);
      }
    }
  }

  .title {
    text-align: center;
    padding-top: 74px;

    @include media($mobile) {
      padding-top: 0;
      margin-bottom: 30px
    }
  }

  .col-12 {
    @include span-columns(12 of 12);
    background-color: $white;
    height: 100%;
    min-height: 1px;
    .col-4 {
      @include span-columns(4 of 12);
      padding: 40px 0 40px 32px;
      color: $dark-blue;
      outline: none;

      @include media($mobile) {
        width: 100%;
        padding: 24px 24px 40px;
      }

      @include media($tablet-lg) {
        width: 40%;
      }

      .slider-title {
        margin-bottom: 23px;
      }

      .slider-para {
        height: 155px;
        overflow: hidden;

        @include media($tablet-lg) {
          height: 125px;
        }

        @include media($mobile) {
          height: 88px;
        }
      }

      .text-link {
        font-family: $albert-bold;
        display: flex;
        align-items: center;
      }
    }

    .col-8 {
      @include span-columns(8 of 12);
      float: none;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      margin-left: 20px;

      @include media($mobile) {
        width: 100%;
        margin-left: 0;
      }

      @include media($tablet-lg) {
        width: 60%;
      }

      .slider-img {
        display: block;
        height: 513px;
        width: 100%;
        object-fit: cover;
        object-position: top;
        font-family: "object-fit: cover;object-position: top;";

        -moz-transition: all 0.2s ease;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;

        @include media($mobile) {
          height: 250px;
        }

        @include media($tablet-lg) {
          height: 485px;
        }
      }

      a:hover {
        @include media($tablet) {
          .slider-img {
            -moz-transform: scale(1.05);
            -webkit-transform: scale(1.05);
            transform: scale(1.05);
          }
        }
      }

      &.dragging .slider-img {
        pointer-events: none;
      }
    }
  }
}

/* Slider */
.carousel-container {
  @include outer-wrap;
  display: block;
  padding: 54px 10px 0;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;

  @include media($mobile) {
    padding: 0 0 0 24px;
    margin-right: 5px;
  }

  @include media($desktop-lg) {
    padding: 27px 0 0;
  }

  @media screen and(max-width: 1410px) and (min-width: 1024px) {
    margin-right: 63px;
    margin-left: 63px;
  }

  .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    padding: 0;

    &:focus {
      outline: none;
    }

    &.dragging {
      cursor: pointer;
      cursor: hand;
    }
  }

  .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: flex;
    margin-left: auto;
    margin-right: auto;

    .slick-loading & {
        visibility: hidden;
    }
  }

  .slick-arrow {
    background-color: $light-blue;
    padding: 0px;
    margin: auto;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    position: absolute;
    top: 0;
    bottom: -80px;
    transition: all .5s ease-in-out;

    &:hover, &:active, &:focus {
      background-color: $light-blue;
      outline: none;
    }

    &.slick-next {
      right: -45px;
      transform: translate(100px,0);

      &::after {
        content: '';
        background: url('../../../assets/icons/chevron-right-white.svg');
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        margin-left: 12px;
        position: relative;
        display: flex;
      }

      &:hover {
        transform: translateX(-10px);
        transition: all .2s ease-in;
      }
    }

    &.slick-prev {
      left: -45px;
      transform: translate(-50px,0);

      &::before {
        content: '';
        background: url('../../../assets/icons/chevron-left-white.svg');
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        margin-left: 43px;
        position: relative;
        display: flex;
      }

      &:hover {
        transform: translateX(10px);
        transition: all .2s ease-in;
      }
    }

    &.slick-disabled {
      display: none !important;
    }
  }

  .slick-dots {
    display: flex;
    position: absolute;
    left: 48.6%;
    margin: 45px auto;

    @include media($mobile) {
      position: relative;
      justify-content: center;
      align-content: center;
      left: 0;
      margin: 17px auto;
    }

    .slick-active {
      .dots {
        background-color: $light-blue;
        opacity: 1;
      }
    }

    .dots {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $dark-blue;
      opacity: 0.07;
      padding: 0;
      margin: 0 8px 0 0;

      &:hover, &:focus {
        background-color: $light-blue;
        opacity: 1;
        outline: none;
      }
    }
  }
}

.carousel-container .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  @include media($mobile) {
    transform: none;
    padding: 0 20px 0 0 !important;
  }
}

.carousel-item {
  .slick-initialized & {
    display: flex !important;
    margin: 0;
    position: relative;

    @include media($mobile) {
      flex-direction: column-reverse;
      margin-right: 10px !important;
      width: auto !important;
    }
  }
}


// Header
$header-spacing: 30px;

header {

  // All Media
  position: absolute;
  width: 100%;
  color: #fff;
  z-index: 2;
  background: linear-gradient(rgba(0,40,77,0.65),rgba(0,40,77,0));

  .main {
    @include inner-wrap;
    background: #FFF;
    position: relative;
    height: 100px; }
  .logo {
    width: 157px;
    height: 40px;
    display: inline-block;
    position: absolute;
    z-index: 20; }
  .contact-line {
    @include inner-wrap;
    .mob-phone {
      display: none; }
    .desk-phone {
      display: inline-block; } }
  .search {
    position: relative;
    z-index: 20;
    input[type=search] {
      padding: 10px; }
    input[type=submit] {
      @include retina-sprite($search-btn-group);
      // +icon
      display: block;
      background-color: transparent;
      text-indent: -999999px;
      padding: 0;
      margin: 0;
      position: absolute;
      right: 9px;
      top: 9px; } }
  .contact-line-search-form {
    display: inline-block;
    input[type=submit] {
      @include retina-sprite($search-btn-white-group); } }
  .contact-line-search, .contact-line-search:focus {
    background: transparent;
    border: 1px solid $white;
    color: $white;
    margin-top: 0; }
  .mobile-nav-search {
    display: inline-block; }
  //////// Tablet and Up ////////
  @include media($desktop-lg) {

    .logo {
      float: left;
      margin: $header-spacing 0; }
    .search {
      float: right;
      margin: 33px 0 0 0;
      width: 100px;
      input {
        box-sizing: border-box; } }
    .contact-line-search-form {
      display: block;
      margin: 0 auto;
      float: left;
      width: 300px;
      .contact-line-search {
        border: 1px solid white;
        color: white;
        width: 300px; } }
    .mobile-nav-search {
      display: none; }
    // Contact dropdown styles
    ul.contact {
      li:first-child {
        margin-bottom: 30px;
        a {
          color: white; }
        a:hover {
          color: $light-blue; } }
      li a {
        font-size: 21px;
        @include media($desktop-lg) {
          font-size: 24px; }
        display: block;
        color: $light-blue;
        line-height: 26px;
        margin-bottom: 14px;
        font-family: $bree; } }

    nav {
      display: inline-block;

      // #1 Level Nav
      > ul {
        padding: 40px 0;
        position: absolute;
        top: 0;
        left: 0;
        padding-left: 200px;
        padding-right: 200px;
        width: 100%;
        justify-content: space-around;
        -webkit-justify-content: space-around;
        display: flex;
        display: -webkit-flex;
        text-align: center;
        > li {
          display: inline-block;
          > a, > span {
            font-weight: bold;
            font-size: 	12px;
            color: $dark-blue; }
          > a:before, > span:before {
            content: '';
            @include retina-sprite($arrow-down-group);
            // +icon
            display: block;
            float: right;
            margin: 8px 5px 0 8px; }
          > a.current, > span.current {
            color: $light-blue; }
          &.show-nav > a:before, &.show-nav > span:before {
            @include retina-sprite($arrow-up-group); } }
        > li.no-arrow {
            a::before, span::before {
              display: none; } } }

      // #2 Level Nav (Dropdown)
      > ul > li > div {
        display: none;
        background: $dark-blue url('../img/have-your-say-bg.png') no-repeat bottom left;
        position: absolute;
        width: 100%;
        left: 0;
        padding: 100px 75px 20px 75px;
        box-sizing: content-box;
        margin-left: -75px;
        z-index: -1;
        min-height: 300px;
        // Standard Dropdown columns (4 elements)
        ul {
          text-align: left;
          @include span-columns(3 of 12);
          li {
            &:first-child {
              font-size: 1.5em;
              font-family: $bree;
              margin-bottom: 20px;
              a {
                font-size: 24px !important; } }
            margin-bottom: 20px;
            a {
              color: #fff;
              line-height: 20px;
              font-size: 18px; }
            a.current {
              color: #33b5e6; } } }
        // Promo dropdown (5 elements)
        &.dropdown-promo {
          ul {
            @include span-columns(4.5 of 24); }
          .promo-banner {
            @include span-columns(6 of 24);
            margin-left: 0;
            float: right; }
          .image {
            display: block; }
          .content {
            background-image: none;
            margin: 0;
            text-align: center;
            padding: 10px;
            h3 {
              font-weight: normal;
              color: #fff; }
            .btn {
              font-size: 1.125em;
              @include padding(null 0);
              width: 100%; } } } }

      > ul > li.show-nav > div {
        display: block; } }

    $gutter: 20px;
    .contact-line {
      line-height: 40px;
      vertical-align: -1px;
      font-size: 0.875em;
      padding-left: $gutter-tablet / 2;
      padding-right: $gutter-tablet / 2;
      // Left side contact phone numbers
      strong {
        font-weight: normal;
        font-size: 1.125em;
        &+ a {
          font-size: 1.250em;
          margin-right: 30px; } }

      // State Locater Dropdown, Login
      .location-dropdown, .login-dropdown {
        background: $dark-blue;
        position: absolute;
        display: none;
        z-index: 99;
        right: 0;
        top: 47px;
        li a {
          color: #fff;
          display: block;
          text-align: center;
          width: 150px;
          &:hover {
            text-decoration: none;
            color: $light-blue; } }
        &.show {
          display: block; } }


      .login-dropdown {
        right: 60px;
        &:before {
          content: '';
          display: block;
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 6px solid $dark-blue;
          margin-top: -6px;
          margin-left: 68px; } }
      // State Locator toggle
      .location {
        position: relative;
        float: right;
        clear: right;
        .divider.login {
          display: none; }
        > a, > span {
          color: #fff;
          text-decoration: none;
          margin-right: 5px;
          margin-left: 5px;
          &.location-toggle,&.login-menu {
            display: inline-block;
            span {
              font-weight: bold; }
            &:after {
              content: '';
              @include retina-sprite($arrow-down-group);
              // +icon
              display: block;
              float: right;
              margin-top: 17px;
              margin-left: 6px; }
            &:focus:after {
              @include retina-sprite($arrow-up-group); } }
          &:hover {
            text-decoration: underline; }
          &.login-menu {
            display: none; } } }

      .mob-phone {
        display: none; }

      .desk-phone {
        display: inline-block;
        &:hover {
          color: $cyan; } } } }
  ////// Desktop and Up /////
  @include media($desktop-lg) {
    nav > ul {
      @include padding(null 230px null 230px);
      li a {
        font-size: 1.125em; } }
    .search {
      width: 140px; }
    .contact-line-search-form {
      margin: 0 auto;
      width: 300px; }
    .contact-line {
      padding-left: $gutter-desktop;
      padding-right: $gutter-desktop;

      .location {
        .divider.login {
          display: inline-block; }
        > a {
          &.login-menu {
            display: inline-block; } } } }


    div.dropdown-promo .content {
      padding: 20px !important; } }


  @include media($desktop-lg) {
    nav > ul {
      @include padding(55px 360px null 360px); }
    .main {
      height: 130px; }
    .logo {
      width: 239px;
      height: 60px; }
    .search {
      margin-top: 46px;
      width: 280px; }
    .contact-line-search-form {
      display: inline-block;
      float: right;
      margin: 0;
      width: 200px;
      .contact-line-search {
        width: 200px; } }
    .contact-line {
      padding-left: $gutter-desktop-lg;
      padding-right: $gutter-desktop-lg;
      span:last-of-type {
        float: none; } }

    .twitter-typeahead {
      width: 100%; } }

  //////// Mobile Only ////////
  @include media($mobile) {

    .logo {
      margin-left: $gutter/2; }

    .main {
      min-height: 70px;
      height: auto;
      padding: $header-spacing/2 0 0 0; }

    .mobile {
      color: $dark-blue;
      text-align: center;
      position: absolute; }

    .mobile.location-toggle {
      top: 12px;
      right: 71px;
      text-transform: uppercase;
      &:before {
        content: '';
        @include retina-sprite($header-location-group);
        // +icon
        display: block;
        margin: 0 5px; } }

    .mobile.hamburger {
      top: 13px;
      right: 13px;
      text-transform: uppercase;
      &:before {
        content: '';
        @include retina-sprite($hamburger-group);
        // +icon
        display: block;
        margin-bottom: 6px;
        margin-left: 4px; } }


    .promo-banner {
      display: none; }

    // Mobile Contact line and location selector
    .contact-line {
      background: $dark-blue;
      text-align: center;
      padding: 10px 0;
      position: absolute;
      top: 70px;
      width: 100%;

      .mob-phone {
        display: inline-block; }

      .desk-phone {
        display: none; }

      strong {
        font-weight: normal; }
      > span:first-child {
        margin-right: 6px; }
      // Mobile Location Selector
      .location {
        > a, > .divider {
          display: none; }
        .location-dropdown {
          display: none;
          &.show {
            display: block; }
          position: absolute;
          z-index: 99;
          background: $light-blue;
          right: 0;
          top: 0;
          width: 210px;
          padding: 10px 10px 0 10px;
          box-sizing: content-box;
          a {
            color: #fff;
            width: 100px;
            margin: 0 10px 10px 0;
            display: block;
            float: left;
            background: $dark-blue;
            padding: 10px 0;
            font-family: $bree; }
          a.active {
            background: $blue; }
          li:nth-child(2n+0) a {
            margin-right: 0; } }
        .login-dropdown {
          display: none; } }
      .mob-phone {
        display: inline-block; }
      .desk-phone {
        display: none; } }
    .search {
      display: none;
      background: lightgrey;
      padding: 20px 20px 10px 20px;
      margin-top: 55px;
      input[type=search] {
        box-sizing: border-box; }
      input[type=submit] {
        top: 30px;
        right: 32px; } }
    // Mobile Navigation
    nav {
      display: none;

      // #1 Level Nav
      > ul > li > a, > ul > li > span {
        background: $light-blue;
        color: #fff;
        display: block;
        padding: 0 $gutter;
        line-height: 60px;
        width: 100%;
        text-transform: uppercase;
        font-weight: bold;
        border-bottom: 1px solid #0093cf;
        &:active, &:hover, &:focus {
          color: #fff; }
        &:after {
          content: '';
          @include retina-sprite($arrow-down-white-group);
          // +icon
          display: block;
          float: right;
          margin: 24px 0 0 0; } }
      > ul > li.show-nav > a:after, > ul > li.show-nav > span:after {
        @include retina-sprite($arrow-up-white-group); }
      > ul > li:last-child a {
        border-bottom: 0; }
      // #2 Level Nav
      > ul > li > div {
        background: url(../img/menu-bg.png) bottom right #003C6A no-repeat;
        background-size: 320px 310px;
        > ul {
          display: none;
          padding: 20px $gutter 0 $gutter;
          &:last-of-type {
            padding-bottom: 20px; }
          > li:first-child {
            font-size: 1.4em;
            font-family: $bree;
            margin-bottom: 10px; }
          a {
            color: #fff;
            line-height: 2em; }
          a.current {
            color: #33b5e6; } } }
      > ul > li.show-nav > div > ul {
        display: block; } } }

  // On toggle, show the dropdown nav
  &.show-nav {
    nav,.search {
      display: block; }
    .contact-line-search-form {
      display: none; }
    .mobile.location-toggle {
      display: none; }
    .mobile.hamburger {
      @include hide-text; } }

  // Mobile only tail
  .mobile-tail {
    display: none;
    box-shadow: 0px 3px 3px rgba(0,0,0,0.4);
    a {
      border-bottom: 1px solid #cbd4dc;
      line-height: 74px;
      text-decoration: none;
      display: block;
      color: $dark-blue; }
    li:last-child a {
      border-bottom: none; }
    // Icons
    a:before {
      content: '';
      // +icon
      display: block;
      float: left;
      margin: 24px 10px 0 20px; }
    .home a:before {
      @include retina-sprite($dot-home-group); }
    .contact a:before {
      @include retina-sprite($dot-phone-group); }
    .login a:before {
      @include retina-sprite($dot-login-group); } }
  @include media($mobile) {
    &.show-nav .mobile-tail {
      display: block; } }

  // Mobile Tweaks
  @include media($mobile-sm) {
    .contact-line > span {
      font-size: 0.75em; } } }

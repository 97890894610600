$container-width: 1312px;

// Breakpoints
$mobile-screen: 480px;
$tablet-screen: 768px;
$desktop-screen: 1024px;
$desktop-lg-screen: 1260px;
$desktop-extra-lg-screen: 1500px;

/// Small media query only for smaller than normal mobile devices
/// @example +media($mobile-sm)
$mobile-sm: new-breakpoint(max-width 400px);

/// Mobile media query for mobile devices with height less than 600px
$mobile-h: new-breakpoint(max-height 600px);

/// Mobile media query only for mobile devices
/// @example +media($mobile)
$mobile: new-breakpoint(max-width $tablet-screen - 1 1);

/// Media query for tablet devices and larger
/// @example +media($tablet)
$tablet: new-breakpoint(min-width $tablet-screen 4);

/// Media query for devices between 768px and 1024px
$tablet-lg: new-breakpoint(min-width $tablet-screen max-width 1023.98px);

/// Media query for tablet portrait only
/// @example +media($tablet-portrait)
$tablet-portrait: new-breakpoint(min-width $tablet-screen orientation portrait 12);

/// Device media query for minimun desktop viewport and below
/// @example +media($desktop)
$desktop-lg-down: new-breakpoint(max-width $desktop-lg-screen - 1);

/// Desktop media query for minimun desktop viewport and above
/// @example +media($desktop)
$desktop: new-breakpoint(min-width $desktop-screen);

/// Large Desktop media query for the largest desktop size
/// @example +media($desktop-lg)
$desktop-lg: new-breakpoint(min-width $desktop-lg-screen);

/// Extra Large Desktop media query for the largest desktop size used in featured-link sass only
/// @example +media($desktop-lg)
$desktop-extra-lg: new-breakpoint(min-width $desktop-extra-lg-screen);

// Gutters
// Used mainly with +inner-wrap and +outer-wrap
$gutter-mobile: 10px;
$gutter-tablet: 39px;
$gutter-desktop: 63px;
$gutter-desktop-lg: 76px;

// The spacing used to seperate modules vertically
$module-spacing: 70px;

// Containers
$max-width: $container-width;

// Buttons)
$all-buttons: append($all-buttons, '.btn');
$all-buttons: append($all-buttons, '.btn-primary');

// Typography
$base-font-family: $albert !default;
$heading-font-family: $bree !default;

// Font Sizes
$base-font-size: 1em;

// Line height
$base-line-height: 1.25; //20px
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 4px;
$base-spacing: $base-line-height * 1em; //20px
$small-spacing: $base-spacing / 2; //10px
$base-z-index: 0;
$mobile-gutter: $base-spacing / 2; //10px

// Colors
$blue: #1565c0;
$dark-blue: #003c71;
$light-blue: #00a3e0;
$error: #e35206;

// Brand Colors
$orange: #e35205;
$yellow: #f2a900;
$green: #43b02a;
$teal: #008c95;
$cyan: $light-blue;
$fuchsia: #9e007e;
$purple: #615e9b;
$white: #fff;
$polar-blue: #f5f9fb;
$grey: #ececec;

$grey-gallery: #efefef;
$grey-alto: #e0e0e0;

$light-organge: #f1Be48;
$light-yellow: #f3D53e;
$light-green: #dbe442;
$light-teal: #9cdbd9;
$light-fuchsia: #dfa0C9;
$light-purple: #b4B5df;
$light-grey: #e9e9e9;

$white-alpha-1: rgba(255, 255, 255, 0.1);
$white-alpha-2: rgba(255, 255, 255, 0.2);

// Font Colors
$base-font-color: $dark-blue !default;
$action-color: $dark-blue;

// Border
$base-border-color: #e9e9e9;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: #fff;
$secondary-background-color: tint($base-border-color, 75%);

// Forms
$form-box-shadow: inset 0 0 0 rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// Animations
$base-duration: 150ms;
$base-timing: ease;

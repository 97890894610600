@media screen and (max-width: 280px) {
	.site-navigation-light {
		ul {
			li {
				margin-left: -2px !important;
			}
		}
	}
}
@media screen and (max-width: 768px) {

li.tab-all.active {
  padding-top: 5px;
}
	.cta-card-slider {
		margin: 0 auto !important;
		.slick-track {
			display: contents !important;
		}
		.slick-slide {
			margin: 20px 5px !important;
		}
	}
	.cta-card-item {
		.card {
			height: 120px !important;
		}
	}
	.site-navigation {
		a {
			line-height: 40px !important;
			font-size: 12px !important;
		}
		ul {
			li {
				display: grid;
			}
		}
	}
	.site-navigation-light {
		ul {
			text-align: center;
			background: #003c71;
			a {
				display: inline-grid ;
				margin: 0 -2px;
				padding: 0;
				border-top: none ;
				width: 25% ;
				&:not(:first-of-type) {
					border-left: 1px solid white;
				}
			}
			li {
				&::before {
					margin: 0 auto !important;
				}
			}
		}
		margin: 0 0px !important;
	}
}
@media screen and (min-width: 768px) {
	.site-navigation-light {
		ul {
			a {
				li {
					padding: 0 30px;
				}
			}
			li {
				&::before {
					content: "";
					display: inline-block !important;
					margin-right: 0 px;
					transform: translateY(5px);
				}
			}
		}
	}
}

.featured-link {

  margin-bottom: $module-spacing;

  &:after {
    content: '';
    clear: both;
    display: block; }

  &.teal {
    .inner {
      background: $teal; }
    .link {
      color: #fff;
      &:after {
        @include retina-sprite($arrow-right-white-group); } } }
  &.cyan {
    .inner {
      background: $cyan; } }

  .inner,.image {
    @include media($desktop) {
      width: 50%;
      float: left; } }

  .link {
    @include media($mobile) {
      display: block;
      color: $light-blue;
      padding: 0 15px 20px; }
    @include media($tablet) {
      display: block;
      color: $light-blue;
      padding: 0 15px 20px;
      // padding: 0px 0px 0px 10%
      // font-size: 1.125em
 }      // width: 80%
    @include media($desktop) {
      padding: 0px 20px 0px 10%;
      font-size: 1em; }
    @include media($desktop-extra-lg) {
      font-size: 1.125em; }



    &:after {
      content: '';
      @include retina-sprite($arrow-right-group);
      display: inline-block;
      margin-left: 10px; } }

  .image {
    img {
      width: 100%;
      display: block; } }

  .inner {
    background: $dark-blue;
    p:last-child {
      margin-bottom: 0; }

    @include media($desktop) {
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      -webkit-flex-direction: column;
      justify-content: center;
      -webkit-justify-content: center; }

    h1,h2,h3 {
      color: white;
      margin: 0;
      padding: 15px;
      @include media($tablet) {
        // padding: 20px 0px 0px 10%
        // width: 80%
        // padding-left: 10%
        color: white;
        margin: 0;
        padding: 15px; }
      @include media($desktop) {
        padding-bottom: 15px;
        padding-top: 0px;
        padding-right: 0px;
        width: 80%;
        padding-left: 10%;
        font-size: 1.375em; }
      @include media($desktop-extra-lg) {
        padding-bottom: 20px;
        font-size: 1.625em;
        padding-top: 0px; } }

    ul {
      margin: 0 0 0 16px;
      padding: 0; }

    p,li {
      color: white;
      margin-bottom: 0;
      line-height: 1.575em;
      @include media($tablet) {
        color: white;
        margin-bottom: 0;
        line-height: 1.575em; }
      @include media($desktop) {
        line-height: 1.575em;
        width: 80%;
        font-size: 1em; }
      @include media($desktop-extra-lg) {
        font-size: 1.125em; } }

    li {
      padding-bottom: 10px; }

    ul,p {
      padding: 0 10px 15px 15px;
      @include media($tablet) {
        padding: 0 10px 15px 15px; }
      @include media($desktop) {
        padding-top: 0px;
        @include padding(null 0px 15px 10%); }
      @include media($desktop-extra-lg) {
        padding-bottom: 20px;
        padding-top: 0px; } } } }

.modern-appliances {
    padding-top: 30px;
    text-align: center;
    padding: 54px 20px 64px;

    @include media($tablet) {
        padding-right: 0;
        padding-left: 0; }

    .text {
        margin-bottom: 46px;

        @include media($desktop) {
            max-width: 882px;
            margin-right: auto;
            margin-left: auto; } }

    h2 {
        font-size: 2.4em;
        @include media($mobile) {
            font-size: 2em; } }

    p {
        @include media($desktop-lg) {
            font-size: 1.18em; } }

    ul,
    li {
        border-style: solid;
        border-color: $grey-gallery; }

    ul {
        border-width: 2px 0 0 2px; }

    li {
        padding: 40px 35px;
        border-width: 0 2px 2px 0;

        &:last-child {
            border-bottom-width: 2px; }

        p {
            margin-bottom: 18px; } }

    .icon {
        display: block;
        @include margin(0 auto 12px auto);
        min-height: 90px;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%); }

        &.hot-water:before {
            @include retina-sprite($tab-shower-lg-on-group); }
        &.heating-cooling:before {
            @include retina-sprite($tab-heating-lg-on-group); }
        &.cooking:before {
            @include retina-sprite($tab-cooking-lg-on-group); }
        &.outdoors:before {
            @include retina-sprite($tab-outdoors-lg-on-group); } }

    .link {
        position: relative;
        font-weight: 700;

        &:after {
            position: absolute;
            top: 0;
            content: '';
            top: 50%;
            transform: translateY(-50%);
            margin-left: 8px;
            @include retina-sprite($arrow-right-group); } }

    @include media($tablet) {
        ul {
            display: flex;
            flex-wrap: wrap; }

        li {
            width: 50%; } }


    @include media($desktop-lg) {
        li {
            width: 25%; } } }

.launchpad {
  @include outer-wrap;
  @include inner-wrap;
  border: 2px solid #e5ebf1;
  margin-bottom: $module-spacing;
  @include padding(40px null);

  @include media($mobile) {
    @include margin(null 10px);
    @include padding(null 20px); }

  &-title {
    font-size: 2.2em; }

  ul {
    display: inline-flex;
    display: -webkit-inline-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    justify-content: space-around;
    -webkit-justify-content: space-around;
    width: 100%; }

  li {
    margin-bottom: 40px;
    width: 100%;
    @include media($tablet) {
      width: 50%;
      padding-right: 20px;
      &:nth-child(4n+0) {
        margin-right: 0; } }
    @include media($desktop) {
      width: 25%;
      padding-right: 20px;
      &:nth-child(4n+0) {
        margin-right: 0; } }
    span {
      display: inline-block;
      margin-bottom: 1em; }
    span.icon:before {
      content: '';
      display: block; } }

  // use generated var name from `_sprites.scss`
  span.icon.fridge:before {
    @include retina-sprite($heading-fridge-group); }
  span.icon.shower:before {
    @include retina-sprite($heading-shower-group); }
  span.icon.washer:before {
    @include retina-sprite($heading-washer-group); }
  span.icon.light:before {
    @include retina-sprite($heading-light-group); }
  span.icon.heater:before {
    @include retina-sprite($heading-heater-group); }
  span.icon.wrench:before {
    @include retina-sprite($heading-wrench-group); }
  span.icon.home:before {
    @include retina-sprite($heading-home-group); }
  span.icon.report:before {
    @include retina-sprite($heading-report-group); }
  span.icon.magnifier:before {
    @include retina-sprite($heading-magnifier-group); }
  span.icon.environment:before {
    @include retina-sprite($environment-group); }
  span.icon.rebates:before {
    @include retina-sprite($rebates-group); }
  span.icon.savings:before {
    @include retina-sprite($savings-group); } }

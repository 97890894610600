@charset "UTF-8";

/// Provides a quick method for targeting `border-radius` on both corners on the
/// side of a box.
///
/// @argument {number} $radii
///
/// @example scss
///   .element-one {
///     @include border-top-radius(5px);
///   }
///
/// @example css
///   .element-one {
///     border-top-left-radius: 5px;
///     border-top-right-radius: 5px;
///   }
///
/// @example scss
///   .element-two {
///     @include border-left-radius(3px);
///   }
///
/// @example css
///   .element-two {
///     border-bottom-left-radius: 3px;
///     border-top-left-radius: 3px;
///   }

@mixin border-top-radius($radii) {
  border-top-left-radius: $radii;
  border-top-right-radius: $radii;
}

@mixin border-right-radius($radii) {
  border-bottom-right-radius: $radii;
  border-top-right-radius: $radii;
}

@mixin border-bottom-radius($radii) {
  border-bottom-left-radius: $radii;
  border-bottom-right-radius: $radii;
}

@mixin border-left-radius($radii) {
  border-bottom-left-radius: $radii;
  border-top-left-radius: $radii;
}

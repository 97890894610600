@charset "UTF-8";

/// Truncates text and adds an ellipsis to represent overflow.
///
/// @argument {number} $width [100%]
///   The `max-width` for the string to respect before being truncated.
///
/// @argument {string} $display [inline-block]
///   Sets the display-value of the element.
///
/// @example scss
///   .element {
///     @include ellipsis;
///   }
///
/// @example css
///   .element {
///     display: inline-block;
///     max-width: 100%;
///     overflow: hidden;
///     text-overflow: ellipsis;
///     white-space: nowrap;
///     word-wrap: normal;
///   }

@mixin ellipsis(
    $width: 100%,
    $display: inline-block
  ) {

  display: $display;
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

.mini-connection-process {
  @include outer-wrap;
  @include inner-wrap;
  background: #efefef;
  @include padding(20px null);
  margin-bottom: $module-spacing;
  @include media($mobile) {
    @include margin(null 10px);
    @include padding(null 20px); }

  a {
    background: $dark-blue;
    color: #fff;
    display: block;
    text-align: center;
    @include padding(30px null);
    font-size: 1.250em;
    font-family: $albert;
    margin-bottom: 10px; }

  @include media($tablet) {
    @include padding(50px null);
    a {
      width: 46%;
      float: left; }
    a:first-of-type {
      margin-right: 8%; } }

  // Icons
  a:before {
    // +icon
    display: block;
    content: '';
    margin: 0 auto 10px auto; }
  a.home:before {
    @include retina-sprite($tab-home-lg-group); }
  a.business:before {
    @include retina-sprite($tab-business-lg-group); } }

.tt-menu {
  width: 100%;
  margin-top: -10px;
  .tt-suggestion {
    background: #FFF;
    border-bottom: 1px solid $dark-blue;
    padding: 4px 6px;
    border: 1px solid #CCC;
    cursor: pointer;
    color: $dark-blue;
    &:hover {
      background: #DDD; }
    .tt-highlight {
      color: $dark-blue; } } }

@include media($mobile) {
  .twitter-typeahead {
    width: 100%; } }

@charset "UTF-8";

/// Generates variables for all buttons. Please note that you must use
/// interpolation on the variable: `#{$all-buttons}`.
///
/// @type list
///
/// @example scss
///   #{$all-buttons} {
///     background-color: #f00;
///   }
///
///   #{$all-buttons-focus},
///   #{$all-buttons-hover} {
///     background-color: #0f0;
///   }
///
///   #{$all-buttons-active} {
///     background-color: #00f;
///   }
///
/// @example css
///   button,
///   [type='button'],
///   [type='reset'],
///   [type='submit'] {
///     background-color: #f00;
///   }
///
///   button:focus,
///   [type='button']:focus,
///   [type='reset']:focus,
///   [type='submit']:focus,
///   button:hover,
///   [type='button']:hover,
///   [type='reset']:hover,
///   [type='submit']:hover {
///     background-color: #0f0;
///   }
///
///   button:active,
///   [type='button']:active,
///   [type='reset']:active,
///   [type='submit']:active {
///     background-color: #00f;
///   }
///
/// @require {function} _assign-inputs

$buttons-list: (
  "button",
  "[type='button']",
  "[type='reset']",
  "[type='submit']",
);

$all-buttons:        _assign-inputs($buttons-list);
$all-buttons-active: _assign-inputs($buttons-list, active);
$all-buttons-focus:  _assign-inputs($buttons-list, focus);
$all-buttons-hover:  _assign-inputs($buttons-list, hover);

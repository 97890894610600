@charset "UTF-8";

/// Mixes a color with black.
///
/// @argument {color} $color
///
/// @argument {number (percentage)} $percent
///   The amount of black to be mixed in.
///
/// @return {color}
///
/// @example scss
///   .element {
///     background-color: shade(#ffbb52, 60%);
///   }
///
/// @example css
///   .element {
///     background-color: #664a20;
///   }

@function shade(
    $color,
    $percent
  ) {

  @return mix(#000, $color, $percent);
}

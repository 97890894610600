.description-card-container {
  height: 200px;
  box-shadow: 0 -1px 0 0 #e7e7e7;

  @include media($mobile) {
    height: auto; }

  .description-card {
    @include outer-wrap;

    @include media($tablet-lg) {
      margin: 0 20px; }

    @include media($mobile) {
      margin-left: 24px; }

    &__header {
      text-transform: uppercase;
      display: table;

      span {
        padding: 2px 8px;
        color: $white;
        font-size: 12px;
        font-family: $albert-bold;
        letter-spacing: 1px; } }

    &__body {
      display: flex;
      height: auto;
      margin-top: 50px;

      @include media($mobile) {
        flex-direction: column;
        margin-top: 25px; }

      .col-4 {
        @include span-columns(4 of 12);

        .mob-phone {
          color: $dark-blue;
          display: flex;
          align-items: center;

          &:hover, &:focus {
            outline: none; }

          @include media($tablet) {
            cursor: default;
            pointer-events: none; } }

        @include media($tablet-lg) {
          width: 33%;
          margin-right: 0; }

        @include media($mobile) {
          width: 100%;
          margin-bottom: 30px; }

        img {
          margin-right: 25px;

          @include media($tablet-lg) {
            width: 50px;
            height: auto;
            margin-right: 10px; }

          @include media($mobile) {
            width: 40px;
            margin-right: 16px; } }

        .body-text {
          display: flex;
          align-items: baseline;

          .title {
            margin: 0 15px 0 0;

            @include media($tablet-lg) {
              margin-right: 8px; } }

          .para {
            margin-bottom: 0; } } } } } }


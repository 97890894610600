.tab-accordion {
    .main-heading {
        text-align: center;
        padding: 30px;
        margin-bottom: 0;
        @include media($mobile) {
            font-size: 1.8em; } }

    .nav-list-image-wrap {
        position: relative; }

    .nav-list-image {
        display: none; }

    .nav-list-image-overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: $white-alpha-2;
        display: none; }

    .nav-list-item {
        border: 1px solid $grey-alto;
        border-right-width: 0;
        border-left-width: 0;
        border-bottom-width: 0;

        &:last-child {
            border-bottom-width: 1px; } }

    .nav-list-link,
    .nav-list-link:visited {
        font-size: 23px;
        color: $dark-blue;
        font-weight: 700;
        padding: 22px 20px;
        display: block;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            @include retina-sprite($arrow-down-blue-group); }

        &.active:after {
            @include retina-sprite($arrow-up-blue-group); } }

    .tab-content-item {
        display: none;
        padding: 0 20px; }

    .tab-content-text {
        padding: 20px 0; }
    .tab-content-heading {
        display: none; }

    @include media($tablet) {
        .main-heading {
            padding-top: 56px;
            padding-bottom: 52px; }

        .nav-list {
            display: flex;
            margin-bottom: 80px; }

        .nav-list-item {
            margin-right: 40px;
            flex: 1;
            border: none;

            &:last-child {
                margin-right: 0; } }

        .nav-list-text {
            padding: 10px 20px 45px; }

        .nav-list-image {
            display: block;
            height: auto; }

        .nav-list-link,
        .nav-list-link:visited {
            font-size: 18px;
            text-align: center;
            padding: 0;

            &:after {
                display: none; }

            &.active {
                background-color: $polar-blue;
                border-bottom: 5px solid $light-blue; }

            &:hover {
                .nav-list-image-overlay {
                    display: block; } } }

        .tab-content-heading {
            display: block;
            font-size: 2.3em;
            margin-bottom: 28px; }

        .tab-content-item {
            padding-right: 0;
            padding-left: 0;
            &.show {
                display: block !important; } }

        .tab-content-inner {
            display: flex;
            align-items: start; }

        .tab-content-image {
            max-width: 350px;
            order: 2; }

        .tab-content-text {
            order: 1;
            padding-right: 60px;
            padding-top: 0; } }

    @include media($desktop) {
        .tab-content-image {
            max-width: 483px; } } }

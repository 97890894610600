$col-5-max-width: 420px;

.rebate-forms {
  margin: 24px 24px 70px;
  background: $white;
  position: relative;
  width: auto;

  @include media($tablet) {
    @include outer-wrap;
  }

  .twitter-typeahead {
    width: 100%;
  }

  .tt-menu {
    height: auto;
    max-height: 250px;
    overflow-y: auto;
    top: 109% !important;
    border: solid 1px #f1f1f1;

    .tt-suggestion {
      padding: 22px 32px;
      border: none;

      &:hover,
      &:focus {
        background: #eef5f8;
        color: $light-blue;
        outline: none;
      }
    }
  }

  .row {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;

    .form-label {
      margin: 0 0 15px;
      display: flex;
      width: 100%;
      justify-content: space-between;
            
      label {
        margin: 0;
        margin-right: 8px;
        display: inline-block;
      }
    }

    .label {
      @include p2;
      text-align: center;
      cursor: pointer;

      @include media($tablet) {
        padding: 5px;
        font-size: 14px;
      }

      @include media($desktop) {
        font-size: 17px;
      }
    }
  }

  .col-5 {
    width: 100%;

    @include media($tablet) {
      @include span-columns(4 of 12);
    }
  }

  .rebate-forms-wrapper {
    border: 1px solid $grey;
    border-radius: 4px;
    padding: 30px 24px;

    @include media($tablet-lg) {
      padding: 30px 25px;
    }

    @include media($desktop) {
      max-width: $col-5-max-width;
      padding: 40px;
    }
  }

  .postcode {
    padding: 22px 24px;
    border-radius: 4px;
    border: solid 2px $grey;
    width: 100%;
    outline: none;
  }

  .validation {
    @include p3;
    position: relative !important;
    margin-bottom: 0 !important;
    color: $error;
    font-weight: bold;
  }

  .validation-border {
    border: 2px solid $error !important;
  }

  input[type="radio"] {
    &:focus + .label {
      outline: 1px dotted $light-blue;
      outline-offset: -1px;
    }
  }

  input[type="checkbox"] {
    width: 1px;
    height: 1px; 
    opacity: 0;
    overflow: hidden;
    position: absolute;
    left: -99999px;

    &:checked+.checkbox-label {
      outline: none;
      border: none;
      position: relative;
      margin: 0;
    }
    
    &:focus + .checkbox-label {
      outline: 1px dotted $light-blue;
      outline-offset: -1px;
    }

    &:checked+.checkbox-label:after {
      content: '';
      background: url("../../../assets/icons/selected-black.png") no-repeat;
      background-size: 100%;
      width: 22px;
      height: 22px;
      position: absolute;
      left: 0;
    }
  }

  .input-wrapper {
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    width: 100%;

    &:last-child:after {
      content: '';
      display: flex;
      width: 50%;
      max-width: 100px;
      height: 1px;
    }

    @include media($desktop-lg) {
      justify-content: start;
    }
  }

  .checkbox {
    flex-wrap: wrap;
    padding-bottom: 0;

    &-wrapper {
      @include p2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 0 18px;
      margin: 15px 0;
      width: 50%;
      max-width: 100px;
      text-align: center;
      
      @include media($desktop-lg) {
        width: 33.332%;
        max-width: none;
      }

      img {
        max-width: 64px;
        height: 64px;
        margin-bottom: 16px;
      }
    }

    &-label {
      cursor: pointer;
      display: block;
      width: 22px;
      height: 22px;
      border: 2px solid $grey;
      border-radius: 4px;
      margin: 10px 0 0 !important;

      &::after {
        content: '';
        width: 22px;
        height: 22px;
      }
    }
  }

  .success-btn {
    @include p2;
    border-radius: 30px;
    background: $light-blue;
    color: $white;
    font-weight: bold;
    letter-spacing: normal;
    text-transform: none;
    width: 100%;
    outline: none;
    margin: 0;
    padding: 12px;
  }

  .reset-button {
    background-color: $white;
    color: $light-blue;
    font-family: $albert-bold;
    border-radius: 28px;
    border: 2px solid $grey;
    display: flex;
    margin: 32px auto;
    padding: 8px 20px;
    text-transform: none;

    &:focus,
    &:hover {
      outline: none;
      background-color: $white;
      color: $light-blue;
    }
  }

  .error {
    @include p3;
    color: $error;
    line-height: 1.23;
    font-weight: bold;
    display: none;
    margin-top: 8px;
  }

  .col-7 {
    width: 100%;

    @include media($tablet) {
      @include span-columns(8 of 12);
    }

    .great-news {
      color: $blue;
    }

    @include media($desktop-lg) {
      width: calc(100% - #{$col-5-max-width} - 2.35765%); // minus grid margin
    }
  }
}

.hero-banner {
  height: 800px;
  padding-top: 64px;
  padding-bottom: 180px;
  z-index: 1;
  overflow: hidden;
  position: relative;

  @include media($mobile) {
    height: 500px; }

  @include media($desktop-lg) {
    padding-top: 100px; }

  // Responsive image
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    left: 0;
    position: absolute;
    z-index: -5;

    @include media($mobile) {
      display: none; } }

  .card {
    width: 530px;
    height: auto;
    background: white;
    position: relative;
    top: 65px;
    float: right;

    .card-header {
      display: table;
      padding: 0 8px;
      span {
        font-size: 12px;
        letter-spacing: 1px;
        color: #fff;
        font-family: $albert-bold;
        text-transform: uppercase; } }

    .card-body {
      padding: 22px 100px 0 32px;

      .card-title {
        margin-bottom: 18px; }

      .card-text {
        margin-bottom: 0;
        color: #003c71; } }

    .text-link {
      float: right;
      margin-bottom: 32px;
      padding-right: 32px;

      @include media($mobile) {
        float: left; } }

    @include media($mobile) {
      width: 82%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      float: left;
      top: 0;
      right: 0;

      .card-header {
        margin-left: 23px; }

      .card-body {
        padding: 12px 24px; }

      .text-link {
        &:after {
          margin-left: 23px; } } } } }

.hero-banner-agn {
  &:after {
    // width: 350px
    // height: 720px
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -2;

    @include media($mobile) {
      content: '';
      background: url(../../../assets/img/agn-circular-graphic.png) no-repeat bottom right;
      width: 18%;
      height: 100%;
      background-size: 200% 100%;
      background-position: left; } } }

.tertiary {
  ul {
    list-style-type: none; }
  li {
    border-bottom: 1px solid $light-grey; }
  a {
    display: block;
    @include padding(15px 10px);
    color: $dark-blue;
    &:hover {
      color: $light-blue; } }
  li.active a, a.active {
    color: $light-blue; }
  @include media($mobile) {
    display: none; } }

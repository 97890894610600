#environment-module {
  margin: 0 auto;
  position: relative;
  display: none;
  @include media($tablet) {
    display: block; }
  section {
    padding: 0; }
  .stats {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    position: absolute;
    right: 15px;
    top: 15px;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: column;
    .number {
      display: block;
      font-weight: 700;
      font-size: 75px;
      text-align: center;
      sup {
        font-size: 55%; }
      sub {
        bottom: .2em; }
      &.small {
        font-size: 60px;
        margin-top: 10px; } }
    .text {
      display: block;
      font-size: 18px;
      text-align: center;
      &.solar {
        position: relative;
        top: 20px; }
      &.natural-gas {
        position: relative;
        top: 40px; }
      &.wood {
        text-align: center;
        top: 10px;
        font-size: 16px;
        position: relative;
        .large {
          font-weight: 700;
          font-size: 250%;
          display: block; } } } }
  .land {
    position: relative;
    svg {
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      transition-property: opacity;
      transition-duration: 500ms;
      &.show {
        opacity: 1; } } }
  nav {
    display: none;
    &.show {
      display: block; }
    .nav-item {
      cursor: pointer;
      &:hover,
      &.active {
        fill: #14a2dc; } } }
  img.placeholder {
    width: 100%;
    opacity: 0;
    z-index: -1;
    position: relative; } }

#environment-module-mobile {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  display: block;
  @include media($tablet) {
    display: none; }

  section {
    padding: 0; }
  .stats {
    box-sizing: border-box;
    padding: 5px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 78px;
    .number {
      display: block;
      flex-grow: 0;
      font-weight: 700;
      font-size: 55px;
      sup {
        font-size: 55%; }
      sub {
        bottom: .2em; }
      &.small {
        font-size: 45px; } }
    .text {
      display: block;
      flex-grow: 1;
      font-size: 18px;
      padding-left: 10px;
      &.wood {
        text-align: center;
        .large {
          font-weight: 700; } } } }
  .link {
    position: relative;
    overflow: hidden;
    svg {
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      transition-property: opacity;
      transition-duration: 500ms; } }
  nav {
    position: relative;
    top: -20px;
    display: none;
    &.show {
      display: block; } }
  .heading {
    position: relative;
    top: -20px;
    svg {
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      &.show {
        opacity: 1; } } }
  .land {
    position: relative;
    overflow: hidden;
    .land-wrapper {
      transition-property: transform;
      transition-duration: 500ms; }
    svg {
      position: absolute;
      left: 0;
      top: 0;
      &.show {
        opacity: 1; }
      &:nth-child(2) {
        transform: translateX(100%); }
      &:nth-child(3) {
        transform: translateX(200%); }
      &:nth-child(4) {
        transform: translateX(300%); }
      &:nth-child(5) {
        transform: translateX(400%); }
      &:nth-child(6) {
        transform: translateX(500%); } } }
  .info {
    font-size: 16px;
    font-weight: 700;
    text-align: center; }
  img.placeholder {
    width: 100%;
    opacity: 0;
    z-index: -1;
    position: relative; } }

.svg-container {
  margin: 0 auto;
  position: relative;

  .d {
    padding: 0;
    display: none;
    @include media($tablet) {
      display: block; } }
  .m {
    padding: 0;
    display: block;
    @include media($tablet) {
      display: none; } }
  svg {
    position: absolute;
    left: 0;
    top: 0; }
  img.placeholder {
    width: 100%;
    opacity: 0;
    z-index: -1;
    position: relative; } }

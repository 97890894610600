.line-breaker {
  margin-bottom: 28px;

  @include media($tablet) {
    margin-bottom: 56px;
  }

  .visual-icon {
    &::after {
      content: '';
      background: url(../../../assets/icons/section-intro-icon.svg);
      width: 202px;
      height: 12px;
      display: flex;
      background-repeat: no-repeat;
      position: relative;
      bottom: 0;
      margin: auto;
    }
  }
}

// promo
// promos are shared across pages
// can be themed as
// * navy (default)
// * cyan
// * teal

.promo {
  background-color: $dark-blue;
  color: #fff;
  overflow: hidden;
  margin-bottom: $module-spacing;
  &.t-navy {
    background-color: $dark-blue;
  }
  &.t-cyan {
  }
  &.t-teal {
    background-color: $teal;
  }
  &.hide-image-mobile {
    .image {
      display: none;
    }
    @media screen and (min-width: 1130px) {
      .image {
        display: block;
      }
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: #fff;
  }
  @media screen and (min-width: 1130px) {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
  }

  .content {
    margin: 0;
    padding: $mobile-gutter;
    @include media($mobile) {
      padding: 1.5em 0.5em;
    }
    @include media($tablet) {
      padding: 2.5em;
    }
    @media screen and (min-width: 1130px) {
      box-sizing: border-box;
      float: left;
      padding: 0 75px;
      width: 50%;
      h3,
      p {
        clear: right;
        float: right;
        max-width: 500px;
      }
      h4 {
        @include h3;
      }
    }
    @include media($desktop-lg) {
      * {
        float: right;
        width: 300px;
        clear: both;
        width: 480px;
      }
    }
    ul {
      list-style: none;
    }
  }
  .image {
    background: linear-gradient(to bottom, #003c71 50%,#fff 50%);

    @media screen and (min-width: 1130px) {
      background: linear-gradient(to right, #003c71 50%,#fff 50%);
    }

    @media screen and (min-width: 2520px) {
      background: none;
    }

    img {
      display: block;
      max-width: none;
      width: 100%;
    }

    @media screen and (min-width: 480px) {
      img {
        height: auto;
        margin-left: 0;
        width: 100%;
      }
    }

    @media screen and (min-width: 1130px) {
      float: right;
      width: 50%;
    }
  }
}

.rebate-offer-warning {
  border-radius: 4px;
  border: solid 2px $light-blue;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  margin-top: 24px;
  margin-bottom: 24px;

  @include media($desktop) {
    padding: 24px;
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

.rebate-offer-warning__heading {
  font-size: 13px;
  line-height: 20px;
  
  p {
    margin-bottom: 0;
  }

  @include media($desktop) {
    font-size: 14px;
  }
}

.rebate-offer-warning__image {
  width: 25px;
  height: 25px;
  margin-right: 16px;
  flex-shrink: 0;

  @include media($desktop) {
    margin-right: 18px;
    width: 32px;
    height: 32px;
  }
}

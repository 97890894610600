.static-banner {
  @include outer-wrap;
  background-color: $light-blue;
  margin-bottom: 100px;
  position: relative;

  @include media($mobile) {
    margin: 0 25px 50px; }

  .container {
    position: relative;
    margin: 0 auto;

    &::before {
      content: '';
      background: url('../../../assets/icons/group.svg');
      width: 170px;
      height: 70px;
      display: flex;
      background-repeat: no-repeat;
      position: absolute;
      top: -30px;
      right: 32px;
      z-index: -999;

      @include media($mobile) {
        top: -20px;
        right: 15px; } }

    &::after {
      content: '';
      background: url('../../../assets/icons/group-8.svg');
      width: 500px;
      height: 240px;
      display: flex;
      background-repeat: no-repeat;
      background-size: 98%;
      position: absolute;
      z-index: -9999;
      bottom: -30px;
      right: -3%;

      @include media($tablet-lg) {
        width: 335px;
        height: 180px;
        background-size: 105%;
        background-position: bottom;
        right: -2%;
        bottom: -20px; }

      @include media($desktop-lg) {
        width: 554px;
        bottom: -20px;
        right: -2%; }

      @include media($mobile) {
        width: 317px;
        bottom: -102px;
        background-size: 105%; }

      @include media($mobile-h) {
        width: 255px;
        bottom: -125px;
        right: -4%; } }

    .card-header {
      text-transform: uppercase;
      display: table;

      span {
        padding: 2px 8px;
        color: $white;
        font-size: 12px;
        font-family: $albert-bold;
        letter-spacing: 1px; } }

    .row {
      position: relative;
      display: flex;

      @include media($mobile) {
        flex-direction: column; }

      .col-7 {
        @include span-columns(7 of 12);
        padding: 35px 60px 60px;
        position: relative;

        @include media($mobile) {
          width: 100%;
          padding: 20px 32px; }

        @include media($desktop-lg) {
          padding: 45px 81px 76px; }

        h2 {
          color: $white;
          margin: 0;

          @include media($desktop) {
            font-size: 46px; } }

        &::after {
          content: '';
          background: url('../../../assets/icons/yellow-oval.svg');
          width: 85px;
          height: 85px;
          display: flex;
          background-repeat: no-repeat;
          position: absolute;
          top: 80%;
          right: 40px;
          background-size: 100%;

          @include media($mobile) {
            background-size: 90%;
            right: 0;
            top: 120%;
            left: -35px; }

          @include media($mobile-h) {
            top: 105%;
            background-size: 80%; }

          @include media($desktop) {
            width: 100px;
            height: 100px; } } }

      .col-5 {
        @include span-columns(5 of 12);
        display: flex;
        position: relative;

        @include media($mobile) {
          width: 100%;
          justify-content: center; }

        img {
          position: absolute;
          bottom: 0;
          width: 95%;
          height: 95%;

          @include media($mobile) {
            position: relative;
            width: 60%;
            height: 60%;
            bottom: 0; } }

        &::after {
          content: '';
          background: url('../../../assets/icons/blue-oval.svg');
          width: 85px;
          height: 85px;
          display: flex;
          background-repeat: no-repeat;
          background-size: 100%;
          position: absolute;
          top: 60px;
          right: -65px;

          @include media($mobile) {
            top: -40px;
            right: -25px;
            background-size: 90%; }

          @include media($mobile-h) {
            top: -20px;
            right: -30px;
            background-size: 80%; }

          @include media($desktop) {
            width: 100px;
            height: 100px; } } }

      .text-link:after {
        content: '';
        background: url('../../../assets/icons/arrow-right-white.svg');
        background-repeat: no-repeat;
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 40px 32px;

        @include media($mobile) {
          margin: 15px; }

        @include media($tablet-lg) {
          margin: 30px; } } } } }

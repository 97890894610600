@mixin contact-sizing {
  width: 75%;
  margin: 0 auto;
  max-width: 500px; }

.make-an-enquiry {

  .island {
    margin-bottom: 20px;
    padding: 10px; }

  h2 {
    margin: 0; }

  @include media($tablet) {
    .contact-left {
      @include span-columns(4 of 6); }
    .contact-right {
      @include span-columns(2 of 6); } }

  @include media($desktop) {
    margin-top: $gutter-desktop; }

  .inner {
    @include inner-wrap; }

  .btn, button, input[type="submit"] {
    float: right;
    @include media($mobile) {
      @include margin(null 0);
      width: 100%; } }

  textarea {
    min-height: 200px; }

  @include media($mobile) {
    & + .content {
      .have-your-say-wrap .have-your-say {
        margin-bottom: -20px; } } }

  @include media($desktop) {
    & + .content {
      margin-top: $gutter-desktop-lg;
      padding-left: 0;
      padding-right: 0; } }

  .contact-content div {
    margin: 25px 0; }

  .contact-center + .contact-right {
    display: none; }

  @include media($desktop) {
    .contact-center .contact-content {
      @include contact-sizing;
      margin: 20px auto; }

    .contact-left .contact-content {
      margin: 40px auto;
      max-width: 500px; }

    .contact-right {
      padding-top: 50px; } } }

.get-in-touch {
  @include clearfix;

  @include media($desktop) {
    @include contact-sizing;
    margin: 40px auto 20px; }

  label {
    @include media($desktop) {
      font-size: 18px;
      margin-top: 14px;
      margin-bottom: 8px; } }

  .btn, button, input[type="submit"] {
    @include media($mobile) {
      @include margin(null 0);
      width: 100%;
      margin-bottom: 10px; }
    @include media($tablet) {
      float: right;
      margin-bottom: 16px; } } }

.disclaimer {
  clear: both; }

.contact-details {
  @include media($mobile) {
    padding-top: 40px;
    li a {
      color: $dark-blue;
      font-size: 1.5em;
      line-height: 1.3em; }
    .mail p {
      font-size: 1.2em;
      line-height: 1.5em; }
    li h3 {
      margin-bottom: 0; }
    .phone p {
      line-height: 1.4em; } }

  li {
    position: relative;
    padding-left: 55px;
    margin-bottom: 35px; }

  li:before {
    content: '';
    display: inline-block;
    position: absolute;
    left: 0; }
  .phone:before {
    @include retina-sprite($dot-phone-lg-group); }
  .email:before {
    @include retina-sprite($dot-cursor-lg-group); }
  .mail:before {
    @include retina-sprite($dot-mail-lg-group); }

  h3 {
    @include margin(0 0 10px 0); } }

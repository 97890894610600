.toolbox {
  @include clearfix;

  &.span3 li {
    @include media($desktop) {
      width: 33.3%; } }

  li a {
    background: $light-blue;
    display: block;
    color: #fff;
    line-height: 60px;
    border-bottom: 1px solid #40aed8; }
  li:last-child a {
    border-bottom: none; }

  li a:before {
    // +icon
    display: block;
    content: '';
    float: left;
    margin-top: 18px;
    margin-right: 10px;
    @include media($mobile) {
      margin-left: 20px; } }
  li.home a:before {
    @include retina-sprite($tab-home-group); }
  li.appliance a:before {
    @include retina-sprite($tab-appliance-group); }
  li.wrench a:before {
    @include retina-sprite($tab-wrench-group); }
  li.report a:before {
    @include retina-sprite($tab-report-group); }

  // Copied from footer
  li.top a {
    background: #FFF;
    color: $dark-blue;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 100px;
    text-align: center;
    &:after {
      content: '';
      @include retina-sprite($back-to-top-group);
      display: inline-block;
      margin: 0 0 3px 10px; }
    @include media($desktop-lg) {
      display: none; } }
  li.top {
    @include media($desktop) {
      width: 100%; } }


  @include media($tablet) {
    li a {
      padding: 0 20px; } }
  @include media($desktop) {
    li {
      width: 25%;
      float: left; }
    li a {
      border: none;
      border-left: 2px solid #008abe; }
    ul li:first-child a {
      border-left: none; } } }

.primary-content-card {
  @include outer-wrap;
  margin-bottom: 70px;

  @include media($mobile) {
    margin-bottom: 25px; }

  .prime {
    @include span-columns(12 of 12);
    display: flex;
    justify-content: space-between;
    position: relative;

    .content-card {
      @include span-columns(6 of 6);

      &__card {
        background: $white;
        width: 90%;
        height: 280px;
        position: relative;
        margin: -34% 0 0 0;

        &-header {
          text-transform: uppercase;
          display: table;

          span {
            padding: 2px 8px;
            color: $white;
            font-size: 12px;
            font-family: $albert-bold;
            letter-spacing: 1px; } }

        &-body {
          padding: 20px 32px;

          .card-text {
            color: $dark-blue; }

          @include media($mobile) {
            padding: 20px 20px 20px 16px; }

          @include media($tablet-lg) {
            padding: 20px; } }

        &-link {
          display: flex;
          margin-left: 32px;
          position: absolute;
          bottom: 0;
          font-size: 17px;
          font-family: $albert-bold;
          color: $dark-blue;

          @include media($mobile) {
            position: relative;
            margin-left: 16px; }

          @include media($tablet-lg) {
            margin-left: 20px; } }

        @include media($mobile) {
          height: auto;
          margin: -140px -0.6px 40px;
          z-index: 9999; }

        @include media($tablet-lg) {
          margin-top: -40%; }

        @include media($desktop-lg) {
          width: 32%;
          position: absolute;
          top: 53%;
          margin: 0; } }

      @include media($mobile) {
        width: auto;
        position: relative;
        margin-right: auto; }

      @include media($tablet-lg) {
        margin: 0 auto;
        overflow: hidden;

        &:first-child {
          margin-right: 0; }

        &:last-child {
          margin-left: 40px; } }

      @include media($desktop) {
        overflow: hidden;
        margin: 0 0 70px;

        &:last-child {
          margin-left: 40px; } } }

    @include media($mobile) {
      flex-direction: column;
      height: auto;
      padding: 0 20px; }

    img {
      width: 100%;
      object-fit: cover;
      font-family: "object-fit: cover;";
      -moz-transition: all 0.2s ease;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;

      @include media($mobile) {
        height: 350px;
        object-position: top left;
        font-family: "object-position: top left;"; }

      @include media($tablet-lg) {
        height: 400px; }

      @media screen and (max-width: 1260px) and (min-width: 1024px) {
        height: 480px; }

      @include media($desktop-lg) {
        width: 640px;
        height: 600px; } }

    a:hover img {
      @include media($tablet) {
        -moz-transform: scale(1.05);
        -webkit-transform: scale(1.05);
        transform: scale(1.05); } } } }

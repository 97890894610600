.headlines {
  margin-bottom: $module-spacing;

  @include media($mobile) {
    margin-left: 10px;
    margin-right: 10px; }

  h3 {
    margin-top: 20px; }

  article {
    margin-bottom: 30px;
    &:after {
      display: block;
      content: '';
      clear: both; }
    img {
      width: 100%;
      @include media($tablet) {
        width: auto;
        float: left;
        margin-right: 30px;
        display: block; } }
    p {
      margin-bottom: 1em; }
    .link {
      font-size: 1.1em;
      &:after {
        content: '';
        @include retina-sprite($arrow-right-group);
        display: inline-block;
        margin-left: 10px; } } } }

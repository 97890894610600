
$bree: "Bree Serif";
$heading-font: $bree;
$albert: "FSAlbert";
$albert-bold: "FSAlbertBold";
$albert-extra-bold: "FSAlbertExtraBold";

// Need to check (think it's bree)
//@import "http://fast.fonts.net/t/1.css?apiType=css&projectid=c2fa0a79-c33b-4119-bf6a-00c064266d16"

@include font-face($bree, "../fonts/bree-serif");
@include font-face($albert, "../fonts/FSAlbertWeb-Regular", $file-formats: eot woff);
@include font-face($albert-bold, "../fonts/FSAlbertWeb-Bold", $file-formats: woff woff2);
@include font-face($albert-extra-bold, "../fonts/FSAlbertWeb-ExtraBold", $file-formats: woff woff2);

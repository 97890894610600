.steps-card-container {
  @include outer-wrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 70px auto;

  .steps-wrapper {
    width: 100%;
    position: relative;

    &:last-child {
      margin-right: 32px;
    }

    @include media($tablet) {
      width: 60%;
      margin: 0;
    }

    @include media($desktop) {
      @include span-columns(6 of 12);
      margin: 0;
    }

    @include media($desktop-lg) {
      width: 529px;
    }
  }

  .steps-card {
    position: relative;

    &-item {
      position: relative;
      overflow: hidden;
      border: 2px solid $dark-blue;
      background-color: $white;
      margin-bottom: 32px;

      &.expandable {
        overflow-y: visible;
      }
    }

    &-header {
      display: table;
      line-height: 1;
      padding: 2px 6px;
      position: absolute;
      z-index: 999;

      span {
        font-size: 12px;
        line-height: 1;
        letter-spacing: 1px;
        color: $white;
        text-transform: uppercase;
        font-family: $albert-bold;
      }
    }

    &-body {
      height: inherit;
      display: flex;
      flex-direction: column;

      .card-body {
        overflow: hidden;
        padding: 40px 17px;

        @include media($tablet) {
          margin-bottom: 0;
          padding: 40px 32px;
        }

        h3 {
          margin-bottom: 8px;
        }

        .card-text {
          padding: 10px 0;
          position: relative;

          p {
            margin-bottom: 0;
          }
        }

        p {
          margin-bottom: 25px;
        }

        h4 {
          margin-bottom: 12px;
        }

        ol {
          clear: both;
          list-style: none;
          display: flex;
          flex-direction: column;
          padding-left: 3.5%;

          @include media($tablet) {
            padding-left: 0;
          }

          @include media($desktop) {
            padding-left: 3px;
          }

          li {
            position: relative;
            display: inline-flex;
            align-items: center;
            margin: 10px 0 25px 5px;
            counter-increment: inst;

            @include media($tablet) {
              margin: 10px 0 25px 15px;
            }

            &::before {
              content: counter(inst);
              height: 40px;
              min-width: 40px;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              background: $light-blue;
              color: $white;
              font-family: $albert-bold;
              border-radius: 50%;
              font-size: 17px;
              left: -5%;
              z-index: 999;

              @include media($tablet) {
                height: 48px;
                min-width: 48px;
              }
            }
          }
        }
      }
    }

    .card-link {
      position: absolute;
      width: 100%;
      bottom: 0;
      background: white;
      padding: 8px 0 8px 17px;
      margin-left: -17px;
      font-family: $albert-bold;
      z-index: 999;

      @include media($tablet) {
        position: absolute;
        width: 100%;
        padding: 8px 0 10px 17px;
      }

      span {
        cursor: pointer;
      }
    }

    &-image {
      width: auto;
      margin: 0 32px;
      padding: 30px;

      @include media($tablet) {
        @include span-columns(6 of 12);
        padding: 0 30px 0 0;
      }

      @include media($desktop-lg) {
        padding: 0;
      }
    }
  }
}

.image-left {
  @include media($tablet) {
    flex-direction: row-reverse;
    align-items: flex-start;

    .steps-wrapper {
      @include media($desktop-lg) {
        margin-right: 0;
        margin-left: 110px;
      }
    }
  }
}

.image-right {
  @include media($tablet) {
    flex-direction: row;
    align-items: flex-start;
  }

  .steps-wrapper {
    @include media($desktop-lg) {
      margin-right: 110px;
    }
  }
}

.collapse-steps {
  display: none;
  justify-content: center;
  color: $light-blue;
  text-decoration: underline;

  .expandable & {
    display: flex;
    margin-left: -32px;
  }
}

.expand-steps {
  display: inline-flex;
  align-items: center;

  &::after {
    content: '';
    background: url('../../../assets/icons/chevron-down.svg');
    width: 24px;
    height: 24px;
    display: flex;
    position: relative;
    background-repeat: no-repeat;
  }

  .expandable & {
    display: none;
  }
}

.tell-me-more-text {
  display: none;

  .expandable & {
    display: block
  }
}

.first-variation {
  &::before {
    content: '';
    background: url('../../../assets/icons/group.svg');
    width: 170px;
    height: 70px;
    display: flex;
    position: absolute;
    background-repeat: no-repeat;
    top: -25px;
    right: -15px;
    z-index: -999;

    @include media($tablet) {
      top: -43px;
      right: -40px;
    }
  }

  &::after {
    content: '';
    background: url('../../../assets/icons/yellow-oval.svg');
    width: 80px;
    height: 80px;
    display: flex;
    position: absolute;
    background-repeat: no-repeat;
    background-size: 90%;
    bottom: -32px;
    background-position: bottom left;
    left: -40px;
    z-index: -999;

    @include media($tablet) {
      width: 85px;
      height: 85px;
      background-size: 100%;
      left: -44px;
    }
  }
}

.second-variation {
  &::after {
    content: '';
    background: url('../../../assets/icons/group-8.svg');
    width: 300px;
    height: 188px;
    display: flex;
    position: absolute;
    background-repeat: no-repeat;
    background-size: 105%;
    background-position: bottom right;
    z-index: -999;
    bottom: -20px;
    right: -16px;

    @media screen and (max-width: 350px) {
      bottom: -18px;
    }

    @include media($mobile-sm) {
      width: 260px;
      height: 180px;
    }

    @include media($desktop) {
      width: 500px;
      height: 270px;
      background-size: 80%;
      bottom: -25px;
      right: -30px;
    }
  }
}

.third-variation {
  &::before {
    content: '';
    background: url('../../../assets/icons/blue-oval.svg');
    width: 85px;
    height: 85px;
    display: flex;
    position: absolute;
    background-repeat: no-repeat;
    background-size: 90%;
    top: -50px;
    right: -35px;
    z-index: -999;

    @include media($tablet) {
      background-size: 100%;
      top: -30px;
    }
  }

  &::after {
    content: '';
    background: url('../../../assets/icons/group.svg');
    width: 170px;
    height: 70px;
    display: flex;
    position: absolute;
    background-repeat: no-repeat;
    bottom: -28px;
    background-position: bottom center;
    left: 48px;
    z-index: -999;

    @include media($tablet) {
      left: 48px;
    }
  }
}

.fourth-variation {
  &::before {
    content: '';
    background: url('../../../assets/icons/group.svg');
    width: 170px;
    height: 70px;
    display: flex;
    position: absolute;
    background-repeat: no-repeat;
    top: -20px;
    left: 75px;
    z-index: -999;

    @include media($tablet) {
      top: -42px;
      left: 120px;
    }
  }
}

.ios {
  .location-search {
    .filter {
      letter-spacing: -0.5px;
      .toggle {
        letter-spacing: -1px; } } } }

.location-search {
  margin-bottom: $module-spacing;

  @include media($mobile) {
    .map {
      display: none; }
    @include margin(null 16px); }

  .search {
    padding: 20px;
    background: #efefef;
    position: relative;
    border-top: 2px solid #e5ebf1;
    border-left: 2px solid #e5ebf1;
    border-right: 2px solid #e5ebf1;
    @include media($desktop) {
      border-right: none; }
    &:after {
      content: '';
      display: block;
      @include retina-sprite($search-btn-group);
      position: absolute;
      bottom: 40px;
      right: 35px;
      cursor: pointer; }
    input[type="search"] {
      background: #fff;
      box-sizing: border-box;
      margin-bottom: 0; } }

  .btn-reset-search {
    display: none; }


  .search-active.search:after {
    visibility: hidden; }

  .search-active {
    .btn-reset-search {
      display: block;
      margin-right: 30px; } }

  .results {
    padding: 20px;
    overflow-y: auto;
    border-left: 2px solid #e5ebf1;
    border-bottom: 2px solid #e5ebf1;
    border-top: 2px solid #e5ebf1;
    border-right: 2px solid #e5ebf1;
    transition: all 0.4s linear;
    @include media($desktop) {
      border-right: none; }
    h4 {
      margin: 0;
      font-weight: normal;
      display: inline; }
    em {
      display: inline-block;
      color: #003c71;
      font-family: $bree;
      font-size: 24px;
      font-style: normal;
      //width: 30px
      margin-right: 7px;
      &:after {
        content: '.'; } }

    .location {
      display: block;
      font-size: 12px;
      color: $dark-blue; }

    .web {
      display: block; }

    .phone {
      display: block;
      color: $dark-blue; }

    .mob-phone {
      display: block; }

    .desk-phone {
      display: none; }

    @include media($desktop) {
      .mob-phone {
        display: none; }

      .desk-phone {
        display: block; } }

    & > a {
      margin-bottom: 20px;
      display: block;
      position: relative; } }
  // Active state for results
  .results > a:hover:before, .results > a.active:before {
    display: block;
    background: #00a2df;
    width: 4px;
    height: 100%;
    content: '';
    position: absolute;
    left: -20px; }

  .filter {
    border-left: 2px solid #e5ebf1;
    border-top: 2px solid #e5ebf1;
    border-right: 2px solid #e5ebf1;
    @include media($desktop) {
      border-right: none; }
    padding: 20px;
    .toggle {
      color: $dark-blue;
      font-weight: 600;
      display: block;
      &:after {
        content: '';
        @include retina-sprite($arrow-down-blue-group);
        display: block;
        float: right;
        margin: 6px 0 0 0; }
      &.open:after {
        @include retina-sprite($arrow-up-blue-group); } } }
  .filter ul {
    margin-top: 20px;
    display: none;
    &:after {
      content: '';
      display: block;
      clear: both; }
    li {
      width: 50%;
      display: block;
      float: left; }
    input[type="checkbox"], label {
      display: inline-block;
      font-weight: normal;
      font-size: 13px; }

    input[type="checkbox"] {
      margin-right: 0;
      @include media($desktop-lg) {
        margin-right: .3125em; } } }

  @include media($tablet) {
    .info {
      height: 900px; }
    .results {
      height: 712px; }
    .results.small {
      height: 600px; }
    #googleMap {
      min-height: 900px; } }

  @include media($tablet-portrait) {
    .map {
      display: none; } }

  @include media($desktop) {
    .info {
      width: 30%;
      float: left; }
    .map {
      width: 70%;
      float: left; } } }

.page-consumption .location-search {
  .results {
    height: 400px; }
  @include media($tablet) {
    .info {
      height: 600px; }
    .results {
      height: 412px; }
    .results.small {
      height: 252px; }
    #googleMap {
      min-height: 600px; } } }

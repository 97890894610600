fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-weight: 600;
  margin-bottom: $small-spacing / 2;
  padding: 0;
}

label {
  display: block;
  font-weight: 600;
  margin-bottom: $small-spacing / 2;
  margin-top: 1em;
}

input,
select,
textarea {
  display: block;
  font-family: $base-font-family;
  font-size: $base-font-size;
}

#{$all-text-inputs},
select[multiple] {
  background-color: $base-background-color;
  border: $base-border;
  border-radius: $base-border-radius;
  box-shadow: $form-box-shadow;
  box-sizing: border-box;
  margin-bottom: $small-spacing;
  padding: $base-spacing / 3;
  transition: border-color $base-duration $base-timing;
  width: 100%;
  &:hover {
    border-color: shade($base-border-color, 20%);
  }
  &:focus {
    border-color: $action-color;
    box-shadow: $form-box-shadow-focus;
    outline: none;
  }
  &:disabled {
    background-color: shade($base-background-color, 5%);
    cursor: not-allowed;
    &:hover {
      border: $base-border;
    }
  }
}

textarea {
  resize: vertical;
}

[type="search"] {
  appearance: none;
}

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: $small-spacing / 2;
}

[type="file"] {
  margin-bottom: $small-spacing;
  width: 100%;
}

// AGN Style
[type="text"],
[type="email"],
[type="tel"],
[type="search"],
[type="url"],
select,
textarea,
[type="password"] {
  color: $base-font-color;
  background-color: #efefef;
  border: none;
  padding: 20px 30px;
  
  &:-ms-input-placeholder,
  &::placeholder {
    color: #757575;
  }

  .get-in-touch & {
    padding: 20px;
  }
}

[type="search"].contact-line-search {
  height: 30px;
}

select {
  margin-bottom: $small-spacing;
  max-width: 100%;
  width: auto;
}

.captcha{
	color:red;
	font-weight:normal;
}
.form-light {
	background-color: white;
	outline: 1px solid #dedede !important;
	border-radius: 3px !important;
}
.form-ddl {
	padding-bottom:10px;
}
.button--ghost {
	background: transparent;
	border: 1px solid #00a3e0;
}
.button--ghost:hover, .button--ghost:focus {
	background: #00a3e0;
	color: #fff;
}
.button, .sitecore-form input[type='submit'] {
	font-weight: 600;
	font-size: 17px;
	line-height: 17px;
	background: #e0f6ff;
	color: #003c71;
	padding: 14px 18px;
	border-radius: 30px;
	cursor: pointer;
	transition: all 150ms ease;
	text-align: center;
	display: inline-flex;
}
input[type='file'] {
	overflow: hidden;
	left: -9999px;
	position: absolute;
}
.featured-image {
    .image {
        font-size: 0; }

    img {
        width: 100%; }

    .text {
        background-color: $polar-blue;
        padding: 30px 20px;
        text-align: center; }

    @include media($desktop) {
        display: flex;
        align-items: stretch;

        .image,
        .text {
            width: 50%; }

        .text {
            order: 1;
            text-align: left;
            padding-right: 5.61%;
            padding-left: 10%;
            display: flex;
            flex-direction: column;
            justify-content: center; }

        .image {
            order: 2; } } }

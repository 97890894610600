.promo-launchpad {
  padding: 30px 5px;
  border: 2px solid #e5ebf1;
  margin-bottom: $module-spacing;
  text-align: center;

  @include media($mobile) {
    @include margin(null 10px);
    @include padding(null 20px); }

  ul {
    display: inline-flex;
    display: -webkit-inline-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    width: 100%;
    padding: 0;
    margin: 0 0 30px 0;
    justify-content: center; }

  li {
    list-style: none;
    width: 100%;
    text-align: left;
    position: relative;
    margin-bottom: 30px;
    @include media($tablet) {
      max-width: 25%;
      padding: 0 10px;
      margin-bottom: 0;
      &:last-child {
        &::after {
          display: none; } } }
    @include media($desktop) {
      padding: 0 25px;
      &::after {
        content: ' ';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 12px;
        height: 19px;
        background: url(../img/chevron-right.svg); } }

    span {
      display: inline-block;
      margin-bottom: 1em; }
    span.icon:before {
      content: '';
      display: block; }
    .link {
      font-size: 13px;
      font-family: "FSAlbert";
      font-weight: bold; } }

  span.icon.fridge:before {
    @include retina-sprite($heading-fridge-group); }
  span.icon.shower:before {
    @include retina-sprite($heading-shower-group); }
  span.icon.washer:before {
    @include retina-sprite($heading-washer-group); }
  span.icon.light:before {
    @include retina-sprite($heading-light-group); }
  span.icon.heater:before {
    @include retina-sprite($heading-heater-group); }
  span.icon.wrench:before {
    @include retina-sprite($heading-wrench-group); }
  span.icon.home:before {
    @include retina-sprite($heading-home-group); }
  span.icon.report:before {
    @include retina-sprite($heading-report-group); }
  span.icon.magnifier:before {
    @include retina-sprite($heading-magnifier-group); }
  span.icon.environment:before {
    @include retina-sprite($environment-group); }
  span.icon.rebates:before {
    @include retina-sprite($rebates-group); }
  span.icon.savings:before {
    @include retina-sprite($savings-group); } }

.accordion {
  margin-bottom: 50px;

  li {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include media($tablet) {
    margin-bottom: 100px;
  }
}

.rebate-offer-cost {
  display: none;
  border-radius: 4px;
  border: solid 2px #e5e5e5;
  text-align: center;
  padding: 25px;
  margin-bottom: 32px;
  margin-top: 32px;

  @include media($tablet) {
    padding: 30px 50px 55px;
    margin-top: 12px;

    &:first-child,
    &:first-child[style*="display: none"] + .rebate-offer-cost {
      margin-top: 0;
    }
  }

  @include media($desktop) {
    padding: 30px 80px 55px;
  }

  &__title {
    margin-bottom: 10px;
  }

  &__summary {
    margin-bottom: 14px;

    @include media($tablet) {
      margin-bottom: 28px;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;

    @include media($tablet) {
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  .text-white {
    color: $white;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1px;

    @include media($tablet) {
      font-size: 12px;
    }
  }

  .total-rebate,
  .upfront-offer {
    width: 100%;
    padding: 15px;

    img {
      width: 24px;
    }

    @include media($tablet) {
      width: 304px;
      padding: 22px 5px;

      img {
        width: 48px;
      }
    }
  }

  .price {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    margin-top: -5px;

    &::before {
      content: '$';
      font-size: 34px;
      margin-top: 2px;
    }
  }

  .addition {
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
    border: solid 2px #e5e5e5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto;

    img {
      width: 12px;
      height: 12px;
    }

    @include media($tablet) {
      min-width: 48px;
      height: 48px;
      margin: auto 24px;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.no-result {
  padding: 24px 16px;

  @include media($tablet) {
    padding: 30px 50px 45px;
  }

  @include media($desktop) {
    padding: 30px 66px 48px;
  }

  &__title {
    margin-bottom: 10px;
  }

  &__summary {
    margin-bottom: 0;
  }
}

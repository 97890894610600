.rebate-offer-cta-banner {
    align-items: center;
    border: solid 1px $grey;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px;
    text-align: center;
    border-radius: 4px;
    display: none;

    @include media($desktop) {
        padding: 48px;
    }
}

.rebate-offer-cta__button {
    @include p2;
    background: $light-blue;
    border-radius: 30px;
    color: $white;
    font-weight: bold;
    letter-spacing: normal;
    margin: 0;
    outline: none;
    padding: 12px;
    text-transform: none;
    width: 100%;

    &:hover {
        color: $white;
    }

    @include media($desktop) {
        padding: 12px 32px;
        width: auto;
    }
}
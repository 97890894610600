// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$advice-tab-name: 'advice-tab';
$advice-tab-x: 426px;
$advice-tab-y: 211px;
$advice-tab-offset-x: -426px;
$advice-tab-offset-y: -211px;
$advice-tab-width: 29px;
$advice-tab-height: 28px;
$advice-tab-total-width: 471px;
$advice-tab-total-height: 406px;
$advice-tab-image: '../img/sprite.png';
$advice-tab: (426px, 211px, -426px, -211px, 29px, 28px, 471px, 406px, '../img/sprite.png', 'advice-tab', );
$alert-name: 'alert';
$alert-x: 204px;
$alert-y: 87px;
$alert-offset-x: -204px;
$alert-offset-y: -87px;
$alert-width: 22px;
$alert-height: 22px;
$alert-total-width: 471px;
$alert-total-height: 406px;
$alert-image: '../img/sprite.png';
$alert: (204px, 87px, -204px, -87px, 22px, 22px, 471px, 406px, '../img/sprite.png', 'alert', );
$arrow-down-blue-name: 'arrow-down-blue';
$arrow-down-blue-x: 457px;
$arrow-down-blue-y: 194px;
$arrow-down-blue-offset-x: -457px;
$arrow-down-blue-offset-y: -194px;
$arrow-down-blue-width: 14px;
$arrow-down-blue-height: 8px;
$arrow-down-blue-total-width: 471px;
$arrow-down-blue-total-height: 406px;
$arrow-down-blue-image: '../img/sprite.png';
$arrow-down-blue: (457px, 194px, -457px, -194px, 14px, 8px, 471px, 406px, '../img/sprite.png', 'arrow-down-blue', );
$arrow-down-white-sm-name: 'arrow-down-white-sm';
$arrow-down-white-sm-x: 455px;
$arrow-down-white-sm-y: 311px;
$arrow-down-white-sm-offset-x: -455px;
$arrow-down-white-sm-offset-y: -311px;
$arrow-down-white-sm-width: 11px;
$arrow-down-white-sm-height: 7px;
$arrow-down-white-sm-total-width: 471px;
$arrow-down-white-sm-total-height: 406px;
$arrow-down-white-sm-image: '../img/sprite.png';
$arrow-down-white-sm: (455px, 311px, -455px, -311px, 11px, 7px, 471px, 406px, '../img/sprite.png', 'arrow-down-white-sm', );
$arrow-down-white-name: 'arrow-down-white';
$arrow-down-white-x: 203px;
$arrow-down-white-y: 117px;
$arrow-down-white-offset-x: -203px;
$arrow-down-white-offset-y: -117px;
$arrow-down-white-width: 21px;
$arrow-down-white-height: 12px;
$arrow-down-white-total-width: 471px;
$arrow-down-white-total-height: 406px;
$arrow-down-white-image: '../img/sprite.png';
$arrow-down-white: (203px, 117px, -203px, -117px, 21px, 12px, 471px, 406px, '../img/sprite.png', 'arrow-down-white', );
$arrow-down-name: 'arrow-down';
$arrow-down-x: 457px;
$arrow-down-y: 221px;
$arrow-down-offset-x: -457px;
$arrow-down-offset-y: -221px;
$arrow-down-width: 13px;
$arrow-down-height: 7px;
$arrow-down-total-width: 471px;
$arrow-down-total-height: 406px;
$arrow-down-image: '../img/sprite.png';
$arrow-down: (457px, 221px, -457px, -221px, 13px, 7px, 471px, 406px, '../img/sprite.png', 'arrow-down', );
$arrow-left-name: 'arrow-left';
$arrow-left-x: 464px;
$arrow-left-y: 271px;
$arrow-left-offset-x: -464px;
$arrow-left-offset-y: -271px;
$arrow-left-width: 7px;
$arrow-left-height: 13px;
$arrow-left-total-width: 471px;
$arrow-left-total-height: 406px;
$arrow-left-image: '../img/sprite.png';
$arrow-left: (464px, 271px, -464px, -271px, 7px, 13px, 471px, 406px, '../img/sprite.png', 'arrow-left', );
$arrow-right-white-name: 'arrow-right-white';
$arrow-right-white-x: 455px;
$arrow-right-white-y: 323px;
$arrow-right-white-offset-x: -455px;
$arrow-right-white-offset-y: -323px;
$arrow-right-white-width: 7px;
$arrow-right-white-height: 13px;
$arrow-right-white-total-width: 471px;
$arrow-right-white-total-height: 406px;
$arrow-right-white-image: '../img/sprite.png';
$arrow-right-white: (455px, 323px, -455px, -323px, 7px, 13px, 471px, 406px, '../img/sprite.png', 'arrow-right-white', );
$arrow-right-name: 'arrow-right';
$arrow-right-x: 464px;
$arrow-right-y: 323px;
$arrow-right-offset-x: -464px;
$arrow-right-offset-y: -323px;
$arrow-right-width: 7px;
$arrow-right-height: 13px;
$arrow-right-total-width: 471px;
$arrow-right-total-height: 406px;
$arrow-right-image: '../img/sprite.png';
$arrow-right: (464px, 323px, -464px, -323px, 7px, 13px, 471px, 406px, '../img/sprite.png', 'arrow-right', );
$arrow-up-blue-name: 'arrow-up-blue';
$arrow-up-blue-x: 457px;
$arrow-up-blue-y: 211px;
$arrow-up-blue-offset-x: -457px;
$arrow-up-blue-offset-y: -211px;
$arrow-up-blue-width: 14px;
$arrow-up-blue-height: 8px;
$arrow-up-blue-total-width: 471px;
$arrow-up-blue-total-height: 406px;
$arrow-up-blue-image: '../img/sprite.png';
$arrow-up-blue: (457px, 211px, -457px, -211px, 14px, 8px, 471px, 406px, '../img/sprite.png', 'arrow-up-blue', );
$arrow-up-white-sm-name: 'arrow-up-white-sm';
$arrow-up-white-sm-x: 455px;
$arrow-up-white-sm-y: 338px;
$arrow-up-white-sm-offset-x: -455px;
$arrow-up-white-sm-offset-y: -338px;
$arrow-up-white-sm-width: 11px;
$arrow-up-white-sm-height: 7px;
$arrow-up-white-sm-total-width: 471px;
$arrow-up-white-sm-total-height: 406px;
$arrow-up-white-sm-image: '../img/sprite.png';
$arrow-up-white-sm: (455px, 338px, -455px, -338px, 11px, 7px, 471px, 406px, '../img/sprite.png', 'arrow-up-white-sm', );
$arrow-up-white-name: 'arrow-up-white';
$arrow-up-white-x: 159px;
$arrow-up-white-y: 139px;
$arrow-up-white-offset-x: -159px;
$arrow-up-white-offset-y: -139px;
$arrow-up-white-width: 21px;
$arrow-up-white-height: 12px;
$arrow-up-white-total-width: 471px;
$arrow-up-white-total-height: 406px;
$arrow-up-white-image: '../img/sprite.png';
$arrow-up-white: (159px, 139px, -159px, -139px, 21px, 12px, 471px, 406px, '../img/sprite.png', 'arrow-up-white', );
$arrow-up-name: 'arrow-up';
$arrow-up-x: 457px;
$arrow-up-y: 230px;
$arrow-up-offset-x: -457px;
$arrow-up-offset-y: -230px;
$arrow-up-width: 13px;
$arrow-up-height: 7px;
$arrow-up-total-width: 471px;
$arrow-up-total-height: 406px;
$arrow-up-image: '../img/sprite.png';
$arrow-up: (457px, 230px, -457px, -230px, 13px, 7px, 471px, 406px, '../img/sprite.png', 'arrow-up', );
$australia-icon-name: 'australia-icon';
$australia-icon-x: 350px;
$australia-icon-y: 358px;
$australia-icon-offset-x: -350px;
$australia-icon-offset-y: -358px;
$australia-icon-width: 49px;
$australia-icon-height: 44px;
$australia-icon-total-width: 471px;
$australia-icon-total-height: 406px;
$australia-icon-image: '../img/sprite.png';
$australia-icon: (350px, 358px, -350px, -358px, 49px, 44px, 471px, 406px, '../img/sprite.png', 'australia-icon', );
$back-to-top-name: 'back-to-top';
$back-to-top-x: 456px;
$back-to-top-y: 258px;
$back-to-top-offset-x: -456px;
$back-to-top-offset-y: -258px;
$back-to-top-width: 12px;
$back-to-top-height: 7px;
$back-to-top-total-width: 471px;
$back-to-top-total-height: 406px;
$back-to-top-image: '../img/sprite.png';
$back-to-top: (456px, 258px, -456px, -258px, 12px, 7px, 471px, 406px, '../img/sprite.png', 'back-to-top', );
$check-mark-name: 'check-mark';
$check-mark-x: 57px;
$check-mark-y: 301px;
$check-mark-offset-x: -57px;
$check-mark-offset-y: -301px;
$check-mark-width: 51px;
$check-mark-height: 51px;
$check-mark-total-width: 471px;
$check-mark-total-height: 406px;
$check-mark-image: '../img/sprite.png';
$check-mark: (57px, 301px, -57px, -301px, 51px, 51px, 471px, 406px, '../img/sprite.png', 'check-mark', );
$close-large-name: 'close-large';
$close-large-x: 426px;
$close-large-y: 381px;
$close-large-offset-x: -426px;
$close-large-offset-y: -381px;
$close-large-width: 24px;
$close-large-height: 24px;
$close-large-total-width: 471px;
$close-large-total-height: 406px;
$close-large-image: '../img/sprite.png';
$close-large: (426px, 381px, -426px, -381px, 24px, 24px, 471px, 406px, '../img/sprite.png', 'close-large', );
$close-name: 'close';
$close-x: 457px;
$close-y: 180px;
$close-offset-x: -457px;
$close-offset-y: -180px;
$close-width: 12px;
$close-height: 12px;
$close-total-width: 471px;
$close-total-height: 406px;
$close-image: '../img/sprite.png';
$close: (457px, 180px, -457px, -180px, 12px, 12px, 471px, 406px, '../img/sprite.png', 'close', );
$desktop-logo-name: 'desktop-logo';
$desktop-logo-x: 0px;
$desktop-logo-y: 0px;
$desktop-logo-offset-x: 0px;
$desktop-logo-offset-y: 0px;
$desktop-logo-width: 239px;
$desktop-logo-height: 60px;
$desktop-logo-total-width: 471px;
$desktop-logo-total-height: 406px;
$desktop-logo-image: '../img/sprite.png';
$desktop-logo: (0px, 0px, 0px, 0px, 239px, 60px, 471px, 406px, '../img/sprite.png', 'desktop-logo', );
$dot-cursor-lg-name: 'dot-cursor-lg';
$dot-cursor-lg-x: 426px;
$dot-cursor-lg-y: 0px;
$dot-cursor-lg-offset-x: -426px;
$dot-cursor-lg-offset-y: 0px;
$dot-cursor-lg-width: 45px;
$dot-cursor-lg-height: 45px;
$dot-cursor-lg-total-width: 471px;
$dot-cursor-lg-total-height: 406px;
$dot-cursor-lg-image: '../img/sprite.png';
$dot-cursor-lg: (426px, 0px, -426px, 0px, 45px, 45px, 471px, 406px, '../img/sprite.png', 'dot-cursor-lg', );
$dot-email-xl-name: 'dot-email-xl';
$dot-email-xl-x: 0px;
$dot-email-xl-y: 244px;
$dot-email-xl-offset-x: 0px;
$dot-email-xl-offset-y: -244px;
$dot-email-xl-width: 55px;
$dot-email-xl-height: 55px;
$dot-email-xl-total-width: 471px;
$dot-email-xl-total-height: 406px;
$dot-email-xl-image: '../img/sprite.png';
$dot-email-xl: (0px, 244px, 0px, -244px, 55px, 55px, 471px, 406px, '../img/sprite.png', 'dot-email-xl', );
$dot-email-name: 'dot-email';
$dot-email-x: 426px;
$dot-email-y: 294px;
$dot-email-offset-x: -426px;
$dot-email-offset-y: -294px;
$dot-email-width: 27px;
$dot-email-height: 27px;
$dot-email-total-width: 471px;
$dot-email-total-height: 406px;
$dot-email-image: '../img/sprite.png';
$dot-email: (426px, 294px, -426px, -294px, 27px, 27px, 471px, 406px, '../img/sprite.png', 'dot-email', );
$dot-home-name: 'dot-home';
$dot-home-x: 426px;
$dot-home-y: 323px;
$dot-home-offset-x: -426px;
$dot-home-offset-y: -323px;
$dot-home-width: 27px;
$dot-home-height: 27px;
$dot-home-total-width: 471px;
$dot-home-total-height: 406px;
$dot-home-image: '../img/sprite.png';
$dot-home: (426px, 323px, -426px, -323px, 27px, 27px, 471px, 406px, '../img/sprite.png', 'dot-home', );
$dot-login-name: 'dot-login';
$dot-login-x: 426px;
$dot-login-y: 352px;
$dot-login-offset-x: -426px;
$dot-login-offset-y: -352px;
$dot-login-width: 27px;
$dot-login-height: 27px;
$dot-login-total-width: 471px;
$dot-login-total-height: 406px;
$dot-login-image: '../img/sprite.png';
$dot-login: (426px, 352px, -426px, -352px, 27px, 27px, 471px, 406px, '../img/sprite.png', 'dot-login', );
$dot-mail-lg-name: 'dot-mail-lg';
$dot-mail-lg-x: 426px;
$dot-mail-lg-y: 47px;
$dot-mail-lg-offset-x: -426px;
$dot-mail-lg-offset-y: -47px;
$dot-mail-lg-width: 45px;
$dot-mail-lg-height: 45px;
$dot-mail-lg-total-width: 471px;
$dot-mail-lg-total-height: 406px;
$dot-mail-lg-image: '../img/sprite.png';
$dot-mail-lg: (426px, 47px, -426px, -47px, 45px, 45px, 471px, 406px, '../img/sprite.png', 'dot-mail-lg', );
$dot-mail-xl-name: 'dot-mail-xl';
$dot-mail-xl-x: 57px;
$dot-mail-xl-y: 244px;
$dot-mail-xl-offset-x: -57px;
$dot-mail-xl-offset-y: -244px;
$dot-mail-xl-width: 55px;
$dot-mail-xl-height: 55px;
$dot-mail-xl-total-width: 471px;
$dot-mail-xl-total-height: 406px;
$dot-mail-xl-image: '../img/sprite.png';
$dot-mail-xl: (57px, 244px, -57px, -244px, 55px, 55px, 471px, 406px, '../img/sprite.png', 'dot-mail-xl', );
$dot-mail-name: 'dot-mail';
$dot-mail-x: 380px;
$dot-mail-y: 62px;
$dot-mail-offset-x: -380px;
$dot-mail-offset-y: -62px;
$dot-mail-width: 27px;
$dot-mail-height: 27px;
$dot-mail-total-width: 471px;
$dot-mail-total-height: 406px;
$dot-mail-image: '../img/sprite.png';
$dot-mail: (380px, 62px, -380px, -62px, 27px, 27px, 471px, 406px, '../img/sprite.png', 'dot-mail', );
$dot-phone-alt-name: 'dot-phone-alt';
$dot-phone-alt-x: 114px;
$dot-phone-alt-y: 244px;
$dot-phone-alt-offset-x: -114px;
$dot-phone-alt-offset-y: -244px;
$dot-phone-alt-width: 55px;
$dot-phone-alt-height: 55px;
$dot-phone-alt-total-width: 471px;
$dot-phone-alt-total-height: 406px;
$dot-phone-alt-image: '../img/sprite.png';
$dot-phone-alt: (114px, 244px, -114px, -244px, 55px, 55px, 471px, 406px, '../img/sprite.png', 'dot-phone-alt', );
$dot-phone-large-name: 'dot-phone-large';
$dot-phone-large-x: 171px;
$dot-phone-large-y: 244px;
$dot-phone-large-offset-x: -171px;
$dot-phone-large-offset-y: -244px;
$dot-phone-large-width: 55px;
$dot-phone-large-height: 55px;
$dot-phone-large-total-width: 471px;
$dot-phone-large-total-height: 406px;
$dot-phone-large-image: '../img/sprite.png';
$dot-phone-large: (171px, 244px, -171px, -244px, 55px, 55px, 471px, 406px, '../img/sprite.png', 'dot-phone-large', );
$dot-phone-lg-name: 'dot-phone-lg';
$dot-phone-lg-x: 426px;
$dot-phone-lg-y: 94px;
$dot-phone-lg-offset-x: -426px;
$dot-phone-lg-offset-y: -94px;
$dot-phone-lg-width: 45px;
$dot-phone-lg-height: 45px;
$dot-phone-lg-total-width: 471px;
$dot-phone-lg-total-height: 406px;
$dot-phone-lg-image: '../img/sprite.png';
$dot-phone-lg: (426px, 94px, -426px, -94px, 45px, 45px, 471px, 406px, '../img/sprite.png', 'dot-phone-lg', );
$dot-phone-xl-name: 'dot-phone-xl';
$dot-phone-xl-x: 228px;
$dot-phone-xl-y: 244px;
$dot-phone-xl-offset-x: -228px;
$dot-phone-xl-offset-y: -244px;
$dot-phone-xl-width: 55px;
$dot-phone-xl-height: 55px;
$dot-phone-xl-total-width: 471px;
$dot-phone-xl-total-height: 406px;
$dot-phone-xl-image: '../img/sprite.png';
$dot-phone-xl: (228px, 244px, -228px, -244px, 55px, 55px, 471px, 406px, '../img/sprite.png', 'dot-phone-xl', );
$dot-phone-name: 'dot-phone';
$dot-phone-x: 380px;
$dot-phone-y: 91px;
$dot-phone-offset-x: -380px;
$dot-phone-offset-y: -91px;
$dot-phone-width: 27px;
$dot-phone-height: 27px;
$dot-phone-total-width: 471px;
$dot-phone-total-height: 406px;
$dot-phone-image: '../img/sprite.png';
$dot-phone: (380px, 91px, -380px, -91px, 27px, 27px, 471px, 406px, '../img/sprite.png', 'dot-phone', );
$dot-question-large-name: 'dot-question-large';
$dot-question-large-x: 285px;
$dot-question-large-y: 244px;
$dot-question-large-offset-x: -285px;
$dot-question-large-offset-y: -244px;
$dot-question-large-width: 55px;
$dot-question-large-height: 55px;
$dot-question-large-total-width: 471px;
$dot-question-large-total-height: 406px;
$dot-question-large-image: '../img/sprite.png';
$dot-question-large: (285px, 244px, -285px, -244px, 55px, 55px, 471px, 406px, '../img/sprite.png', 'dot-question-large', );
$dot-success-large-name: 'dot-success-large';
$dot-success-large-x: 0px;
$dot-success-large-y: 301px;
$dot-success-large-offset-x: 0px;
$dot-success-large-offset-y: -301px;
$dot-success-large-width: 55px;
$dot-success-large-height: 55px;
$dot-success-large-total-width: 471px;
$dot-success-large-total-height: 406px;
$dot-success-large-image: '../img/sprite.png';
$dot-success-large: (0px, 301px, 0px, -301px, 55px, 55px, 471px, 406px, '../img/sprite.png', 'dot-success-large', );
$environment-name: 'environment';
$environment-x: 110px;
$environment-y: 301px;
$environment-offset-x: -110px;
$environment-offset-y: -301px;
$environment-width: 48px;
$environment-height: 48px;
$environment-total-width: 471px;
$environment-total-height: 406px;
$environment-image: '../img/sprite.png';
$environment: (110px, 301px, -110px, -301px, 48px, 48px, 471px, 406px, '../img/sprite.png', 'environment', );
$fb-name: 'fb';
$fb-x: 426px;
$fb-y: 141px;
$fb-offset-x: -426px;
$fb-offset-y: -141px;
$fb-width: 37px;
$fb-height: 37px;
$fb-total-width: 471px;
$fb-total-height: 406px;
$fb-image: '../img/sprite.png';
$fb: (426px, 141px, -426px, -141px, 37px, 37px, 471px, 406px, '../img/sprite.png', 'fb', );
$footer-logo-name: 'footer-logo';
$footer-logo-x: 0px;
$footer-logo-y: 62px;
$footer-logo-offset-x: 0px;
$footer-logo-offset-y: -62px;
$footer-logo-width: 202px;
$footer-logo-height: 53px;
$footer-logo-total-width: 471px;
$footer-logo-total-height: 406px;
$footer-logo-image: '../img/sprite.png';
$footer-logo: (0px, 62px, 0px, -62px, 202px, 53px, 471px, 406px, '../img/sprite.png', 'footer-logo', );
$gas-tab-name: 'gas-tab';
$gas-tab-x: 380px;
$gas-tab-y: 120px;
$gas-tab-offset-x: -380px;
$gas-tab-offset-y: -120px;
$gas-tab-width: 24px;
$gas-tab-height: 28px;
$gas-tab-total-width: 471px;
$gas-tab-total-height: 406px;
$gas-tab-image: '../img/sprite.png';
$gas-tab: (380px, 120px, -380px, -120px, 24px, 28px, 471px, 406px, '../img/sprite.png', 'gas-tab', );
$hamburger-name: 'hamburger';
$hamburger-x: 426px;
$hamburger-y: 271px;
$hamburger-offset-x: -426px;
$hamburger-offset-y: -271px;
$hamburger-width: 36px;
$hamburger-height: 21px;
$hamburger-total-width: 471px;
$hamburger-total-height: 406px;
$hamburger-image: '../img/sprite.png';
$hamburger: (426px, 271px, -426px, -271px, 36px, 21px, 471px, 406px, '../img/sprite.png', 'hamburger', );
$header-location-name: 'header-location';
$header-location-x: 426px;
$header-location-y: 241px;
$header-location-offset-x: -426px;
$header-location-offset-y: -241px;
$header-location-width: 28px;
$header-location-height: 28px;
$header-location-total-width: 471px;
$header-location-total-height: 406px;
$header-location-image: '../img/sprite.png';
$header-location: (426px, 241px, -426px, -241px, 28px, 28px, 471px, 406px, '../img/sprite.png', 'header-location', );
$heading-fridge-name: 'heading-fridge';
$heading-fridge-x: 160px;
$heading-fridge-y: 301px;
$heading-fridge-offset-x: -160px;
$heading-fridge-offset-y: -301px;
$heading-fridge-width: 48px;
$heading-fridge-height: 48px;
$heading-fridge-total-width: 471px;
$heading-fridge-total-height: 406px;
$heading-fridge-image: '../img/sprite.png';
$heading-fridge: (160px, 301px, -160px, -301px, 48px, 48px, 471px, 406px, '../img/sprite.png', 'heading-fridge', );
$heading-heater-name: 'heading-heater';
$heading-heater-x: 210px;
$heading-heater-y: 301px;
$heading-heater-offset-x: -210px;
$heading-heater-offset-y: -301px;
$heading-heater-width: 48px;
$heading-heater-height: 48px;
$heading-heater-total-width: 471px;
$heading-heater-total-height: 406px;
$heading-heater-image: '../img/sprite.png';
$heading-heater: (210px, 301px, -210px, -301px, 48px, 48px, 471px, 406px, '../img/sprite.png', 'heading-heater', );
$heading-home-name: 'heading-home';
$heading-home-x: 260px;
$heading-home-y: 301px;
$heading-home-offset-x: -260px;
$heading-home-offset-y: -301px;
$heading-home-width: 48px;
$heading-home-height: 48px;
$heading-home-total-width: 471px;
$heading-home-total-height: 406px;
$heading-home-image: '../img/sprite.png';
$heading-home: (260px, 301px, -260px, -301px, 48px, 48px, 471px, 406px, '../img/sprite.png', 'heading-home', );
$heading-light-name: 'heading-light';
$heading-light-x: 310px;
$heading-light-y: 301px;
$heading-light-offset-x: -310px;
$heading-light-offset-y: -301px;
$heading-light-width: 48px;
$heading-light-height: 48px;
$heading-light-total-width: 471px;
$heading-light-total-height: 406px;
$heading-light-image: '../img/sprite.png';
$heading-light: (310px, 301px, -310px, -301px, 48px, 48px, 471px, 406px, '../img/sprite.png', 'heading-light', );
$heading-magnifier-name: 'heading-magnifier';
$heading-magnifier-x: 0px;
$heading-magnifier-y: 358px;
$heading-magnifier-offset-x: 0px;
$heading-magnifier-offset-y: -358px;
$heading-magnifier-width: 48px;
$heading-magnifier-height: 48px;
$heading-magnifier-total-width: 471px;
$heading-magnifier-total-height: 406px;
$heading-magnifier-image: '../img/sprite.png';
$heading-magnifier: (0px, 358px, 0px, -358px, 48px, 48px, 471px, 406px, '../img/sprite.png', 'heading-magnifier', );
$heading-report-name: 'heading-report';
$heading-report-x: 50px;
$heading-report-y: 358px;
$heading-report-offset-x: -50px;
$heading-report-offset-y: -358px;
$heading-report-width: 48px;
$heading-report-height: 48px;
$heading-report-total-width: 471px;
$heading-report-total-height: 406px;
$heading-report-image: '../img/sprite.png';
$heading-report: (50px, 358px, -50px, -358px, 48px, 48px, 471px, 406px, '../img/sprite.png', 'heading-report', );
$heading-shower-name: 'heading-shower';
$heading-shower-x: 100px;
$heading-shower-y: 358px;
$heading-shower-offset-x: -100px;
$heading-shower-offset-y: -358px;
$heading-shower-width: 48px;
$heading-shower-height: 48px;
$heading-shower-total-width: 471px;
$heading-shower-total-height: 406px;
$heading-shower-image: '../img/sprite.png';
$heading-shower: (100px, 358px, -100px, -358px, 48px, 48px, 471px, 406px, '../img/sprite.png', 'heading-shower', );
$heading-washer-name: 'heading-washer';
$heading-washer-x: 150px;
$heading-washer-y: 358px;
$heading-washer-offset-x: -150px;
$heading-washer-offset-y: -358px;
$heading-washer-width: 48px;
$heading-washer-height: 48px;
$heading-washer-total-width: 471px;
$heading-washer-total-height: 406px;
$heading-washer-image: '../img/sprite.png';
$heading-washer: (150px, 358px, -150px, -358px, 48px, 48px, 471px, 406px, '../img/sprite.png', 'heading-washer', );
$heading-wrench-name: 'heading-wrench';
$heading-wrench-x: 200px;
$heading-wrench-y: 358px;
$heading-wrench-offset-x: -200px;
$heading-wrench-offset-y: -358px;
$heading-wrench-width: 48px;
$heading-wrench-height: 48px;
$heading-wrench-total-width: 471px;
$heading-wrench-total-height: 406px;
$heading-wrench-image: '../img/sprite.png';
$heading-wrench: (200px, 358px, -200px, -358px, 48px, 48px, 471px, 406px, '../img/sprite.png', 'heading-wrench', );
$mobile-logo-name: 'mobile-logo';
$mobile-logo-x: 0px;
$mobile-logo-y: 117px;
$mobile-logo-offset-x: 0px;
$mobile-logo-offset-y: -117px;
$mobile-logo-width: 157px;
$mobile-logo-height: 40px;
$mobile-logo-total-width: 471px;
$mobile-logo-total-height: 406px;
$mobile-logo-image: '../img/sprite.png';
$mobile-logo: (0px, 117px, 0px, -117px, 157px, 40px, 471px, 406px, '../img/sprite.png', 'mobile-logo', );
$news-tab-name: 'news-tab';
$news-tab-x: 380px;
$news-tab-y: 150px;
$news-tab-offset-x: -380px;
$news-tab-offset-y: -150px;
$news-tab-width: 21px;
$news-tab-height: 28px;
$news-tab-total-width: 471px;
$news-tab-total-height: 406px;
$news-tab-image: '../img/sprite.png';
$news-tab: (380px, 150px, -380px, -150px, 21px, 28px, 471px, 406px, '../img/sprite.png', 'news-tab', );
$phone-btn-name: 'phone-btn';
$phone-btn-x: 426px;
$phone-btn-y: 180px;
$phone-btn-offset-x: -426px;
$phone-btn-offset-y: -180px;
$phone-btn-width: 29px;
$phone-btn-height: 29px;
$phone-btn-total-width: 471px;
$phone-btn-total-height: 406px;
$phone-btn-image: '../img/sprite.png';
$phone-btn: (426px, 180px, -426px, -180px, 29px, 29px, 471px, 406px, '../img/sprite.png', 'phone-btn', );
$question-collapse-name: 'question-collapse';
$question-collapse-x: 456px;
$question-collapse-y: 241px;
$question-collapse-offset-x: -456px;
$question-collapse-offset-y: -241px;
$question-collapse-width: 15px;
$question-collapse-height: 15px;
$question-collapse-total-width: 471px;
$question-collapse-total-height: 406px;
$question-collapse-image: '../img/sprite.png';
$question-collapse: (456px, 241px, -456px, -241px, 15px, 15px, 471px, 406px, '../img/sprite.png', 'question-collapse', );
$question-expand-name: 'question-expand';
$question-expand-x: 455px;
$question-expand-y: 294px;
$question-expand-offset-x: -455px;
$question-expand-offset-y: -294px;
$question-expand-width: 15px;
$question-expand-height: 15px;
$question-expand-total-width: 471px;
$question-expand-total-height: 406px;
$question-expand-image: '../img/sprite.png';
$question-expand: (455px, 294px, -455px, -294px, 15px, 15px, 471px, 406px, '../img/sprite.png', 'question-expand', );
$rebates-name: 'rebates';
$rebates-x: 250px;
$rebates-y: 358px;
$rebates-offset-x: -250px;
$rebates-offset-y: -358px;
$rebates-width: 48px;
$rebates-height: 48px;
$rebates-total-width: 471px;
$rebates-total-height: 406px;
$rebates-image: '../img/sprite.png';
$rebates: (250px, 358px, -250px, -358px, 48px, 48px, 471px, 406px, '../img/sprite.png', 'rebates', );
$savings-name: 'savings';
$savings-x: 300px;
$savings-y: 358px;
$savings-offset-x: -300px;
$savings-offset-y: -358px;
$savings-width: 48px;
$savings-height: 48px;
$savings-total-width: 471px;
$savings-total-height: 406px;
$savings-image: '../img/sprite.png';
$savings: (300px, 358px, -300px, -358px, 48px, 48px, 471px, 406px, '../img/sprite.png', 'savings', );
$search-btn-white-name: 'search-btn-white';
$search-btn-white-x: 159px;
$search-btn-white-y: 117px;
$search-btn-white-offset-x: -159px;
$search-btn-white-offset-y: -117px;
$search-btn-white-width: 20px;
$search-btn-white-height: 20px;
$search-btn-white-total-width: 471px;
$search-btn-white-total-height: 406px;
$search-btn-white-image: '../img/sprite.png';
$search-btn-white: (159px, 117px, -159px, -117px, 20px, 20px, 471px, 406px, '../img/sprite.png', 'search-btn-white', );
$search-btn-name: 'search-btn';
$search-btn-x: 181px;
$search-btn-y: 117px;
$search-btn-offset-x: -181px;
$search-btn-offset-y: -117px;
$search-btn-width: 20px;
$search-btn-height: 20px;
$search-btn-total-width: 471px;
$search-btn-total-height: 406px;
$search-btn-image: '../img/sprite.png';
$search-btn: (181px, 117px, -181px, -117px, 20px, 20px, 471px, 406px, '../img/sprite.png', 'search-btn', );
$tab-appliance-on-name: 'tab-appliance-on';
$tab-appliance-on-x: 380px;
$tab-appliance-on-y: 180px;
$tab-appliance-on-offset-x: -380px;
$tab-appliance-on-offset-y: -180px;
$tab-appliance-on-width: 24px;
$tab-appliance-on-height: 24px;
$tab-appliance-on-total-width: 471px;
$tab-appliance-on-total-height: 406px;
$tab-appliance-on-image: '../img/sprite.png';
$tab-appliance-on: (380px, 180px, -380px, -180px, 24px, 24px, 471px, 406px, '../img/sprite.png', 'tab-appliance-on', );
$tab-appliance-name: 'tab-appliance';
$tab-appliance-x: 380px;
$tab-appliance-y: 310px;
$tab-appliance-offset-x: -380px;
$tab-appliance-offset-y: -310px;
$tab-appliance-width: 25px;
$tab-appliance-height: 23px;
$tab-appliance-total-width: 471px;
$tab-appliance-total-height: 406px;
$tab-appliance-image: '../img/sprite.png';
$tab-appliance: (380px, 310px, -380px, -310px, 25px, 23px, 471px, 406px, '../img/sprite.png', 'tab-appliance', );
$tab-business-large-on-name: 'tab-business-large-on';
$tab-business-large-on-x: 380px;
$tab-business-large-on-y: 0px;
$tab-business-large-on-offset-x: -380px;
$tab-business-large-on-offset-y: 0px;
$tab-business-large-on-width: 44px;
$tab-business-large-on-height: 60px;
$tab-business-large-on-total-width: 471px;
$tab-business-large-on-total-height: 406px;
$tab-business-large-on-image: '../img/sprite.png';
$tab-business-large-on: (380px, 0px, -380px, 0px, 44px, 60px, 471px, 406px, '../img/sprite.png', 'tab-business-large-on', );
$tab-business-lg-name: 'tab-business-lg';
$tab-business-lg-x: 317px;
$tab-business-lg-y: 92px;
$tab-business-lg-offset-x: -317px;
$tab-business-lg-offset-y: -92px;
$tab-business-lg-width: 54px;
$tab-business-lg-height: 74px;
$tab-business-lg-total-width: 471px;
$tab-business-lg-total-height: 406px;
$tab-business-lg-image: '../img/sprite.png';
$tab-business-lg: (317px, 92px, -317px, -92px, 54px, 74px, 471px, 406px, '../img/sprite.png', 'tab-business-lg', );
$tab-business-name: 'tab-business';
$tab-business-x: 452px;
$tab-business-y: 381px;
$tab-business-offset-x: -452px;
$tab-business-offset-y: -381px;
$tab-business-width: 17px;
$tab-business-height: 23px;
$tab-business-total-width: 471px;
$tab-business-total-height: 406px;
$tab-business-image: '../img/sprite.png';
$tab-business: (452px, 381px, -452px, -381px, 17px, 23px, 471px, 406px, '../img/sprite.png', 'tab-business', );
$tab-cooking-lg-on-name: 'tab-cooking-lg-on';
$tab-cooking-lg-on-x: 241px;
$tab-cooking-lg-on-y: 85px;
$tab-cooking-lg-on-offset-x: -241px;
$tab-cooking-lg-on-offset-y: -85px;
$tab-cooking-lg-on-width: 74px;
$tab-cooking-lg-on-height: 66px;
$tab-cooking-lg-on-total-width: 471px;
$tab-cooking-lg-on-total-height: 406px;
$tab-cooking-lg-on-image: '../img/sprite.png';
$tab-cooking-lg-on: (241px, 85px, -241px, -85px, 74px, 66px, 471px, 406px, '../img/sprite.png', 'tab-cooking-lg-on', );
$tab-cooling-lg-on-name: 'tab-cooling-lg-on';
$tab-cooling-lg-on-x: 211px;
$tab-cooling-lg-on-y: 159px;
$tab-cooling-lg-on-offset-x: -211px;
$tab-cooling-lg-on-offset-y: -159px;
$tab-cooling-lg-on-width: 75px;
$tab-cooling-lg-on-height: 52px;
$tab-cooling-lg-on-total-width: 471px;
$tab-cooling-lg-on-total-height: 406px;
$tab-cooling-lg-on-image: '../img/sprite.png';
$tab-cooling-lg-on: (211px, 159px, -211px, -159px, 75px, 52px, 471px, 406px, '../img/sprite.png', 'tab-cooling-lg-on', );
$tab-heating-lg-on-name: 'tab-heating-lg-on';
$tab-heating-lg-on-x: 241px;
$tab-heating-lg-on-y: 0px;
$tab-heating-lg-on-offset-x: -241px;
$tab-heating-lg-on-offset-y: 0px;
$tab-heating-lg-on-width: 74px;
$tab-heating-lg-on-height: 83px;
$tab-heating-lg-on-total-width: 471px;
$tab-heating-lg-on-total-height: 406px;
$tab-heating-lg-on-image: '../img/sprite.png';
$tab-heating-lg-on: (241px, 0px, -241px, 0px, 74px, 83px, 471px, 406px, '../img/sprite.png', 'tab-heating-lg-on', );
$tab-home-large-on-name: 'tab-home-large-on';
$tab-home-large-on-x: 317px;
$tab-home-large-on-y: 168px;
$tab-home-large-on-offset-x: -317px;
$tab-home-large-on-offset-y: -168px;
$tab-home-large-on-width: 58px;
$tab-home-large-on-height: 61px;
$tab-home-large-on-total-width: 471px;
$tab-home-large-on-total-height: 406px;
$tab-home-large-on-image: '../img/sprite.png';
$tab-home-large-on: (317px, 168px, -317px, -168px, 58px, 61px, 471px, 406px, '../img/sprite.png', 'tab-home-large-on', );
$tab-home-lg-name: 'tab-home-lg';
$tab-home-lg-x: 71px;
$tab-home-lg-y: 159px;
$tab-home-lg-offset-x: -71px;
$tab-home-lg-offset-y: -159px;
$tab-home-lg-width: 70px;
$tab-home-lg-height: 74px;
$tab-home-lg-total-width: 471px;
$tab-home-lg-total-height: 406px;
$tab-home-lg-image: '../img/sprite.png';
$tab-home-lg: (71px, 159px, -71px, -159px, 70px, 74px, 471px, 406px, '../img/sprite.png', 'tab-home-lg', );
$tab-home-on-name: 'tab-home-on';
$tab-home-on-x: 380px;
$tab-home-on-y: 206px;
$tab-home-on-offset-x: -380px;
$tab-home-on-offset-y: -206px;
$tab-home-on-width: 24px;
$tab-home-on-height: 24px;
$tab-home-on-total-width: 471px;
$tab-home-on-total-height: 406px;
$tab-home-on-image: '../img/sprite.png';
$tab-home-on: (380px, 206px, -380px, -206px, 24px, 24px, 471px, 406px, '../img/sprite.png', 'tab-home-on', );
$tab-home-name: 'tab-home';
$tab-home-x: 204px;
$tab-home-y: 62px;
$tab-home-offset-x: -204px;
$tab-home-offset-y: -62px;
$tab-home-width: 22px;
$tab-home-height: 23px;
$tab-home-total-width: 471px;
$tab-home-total-height: 406px;
$tab-home-image: '../img/sprite.png';
$tab-home: (204px, 62px, -204px, -62px, 22px, 23px, 471px, 406px, '../img/sprite.png', 'tab-home', );
$tab-laundry-lg-on-name: 'tab-laundry-lg-on';
$tab-laundry-lg-on-x: 143px;
$tab-laundry-lg-on-y: 159px;
$tab-laundry-lg-on-offset-x: -143px;
$tab-laundry-lg-on-offset-y: -159px;
$tab-laundry-lg-on-width: 66px;
$tab-laundry-lg-on-height: 66px;
$tab-laundry-lg-on-total-width: 471px;
$tab-laundry-lg-on-total-height: 406px;
$tab-laundry-lg-on-image: '../img/sprite.png';
$tab-laundry-lg-on: (143px, 159px, -143px, -159px, 66px, 66px, 471px, 406px, '../img/sprite.png', 'tab-laundry-lg-on', );
$tab-outdoors-lg-on-name: 'tab-outdoors-lg-on';
$tab-outdoors-lg-on-x: 317px;
$tab-outdoors-lg-on-y: 0px;
$tab-outdoors-lg-on-offset-x: -317px;
$tab-outdoors-lg-on-offset-y: 0px;
$tab-outdoors-lg-on-width: 61px;
$tab-outdoors-lg-on-height: 90px;
$tab-outdoors-lg-on-total-width: 471px;
$tab-outdoors-lg-on-total-height: 406px;
$tab-outdoors-lg-on-image: '../img/sprite.png';
$tab-outdoors-lg-on: (317px, 0px, -317px, 0px, 61px, 90px, 471px, 406px, '../img/sprite.png', 'tab-outdoors-lg-on', );
$tab-report-on-name: 'tab-report-on';
$tab-report-on-x: 380px;
$tab-report-on-y: 232px;
$tab-report-on-offset-x: -380px;
$tab-report-on-offset-y: -232px;
$tab-report-on-width: 24px;
$tab-report-on-height: 24px;
$tab-report-on-total-width: 471px;
$tab-report-on-total-height: 406px;
$tab-report-on-image: '../img/sprite.png';
$tab-report-on: (380px, 232px, -380px, -232px, 24px, 24px, 471px, 406px, '../img/sprite.png', 'tab-report-on', );
$tab-report-name: 'tab-report';
$tab-report-x: 403px;
$tab-report-y: 150px;
$tab-report-offset-x: -403px;
$tab-report-offset-y: -150px;
$tab-report-width: 20px;
$tab-report-height: 23px;
$tab-report-total-width: 471px;
$tab-report-total-height: 406px;
$tab-report-image: '../img/sprite.png';
$tab-report: (403px, 150px, -403px, -150px, 20px, 23px, 471px, 406px, '../img/sprite.png', 'tab-report', );
$tab-shower-lg-on-name: 'tab-shower-lg-on';
$tab-shower-lg-on-x: 0px;
$tab-shower-lg-on-y: 159px;
$tab-shower-lg-on-offset-x: 0px;
$tab-shower-lg-on-offset-y: -159px;
$tab-shower-lg-on-width: 69px;
$tab-shower-lg-on-height: 83px;
$tab-shower-lg-on-total-width: 471px;
$tab-shower-lg-on-total-height: 406px;
$tab-shower-lg-on-image: '../img/sprite.png';
$tab-shower-lg-on: (0px, 159px, 0px, -159px, 69px, 83px, 471px, 406px, '../img/sprite.png', 'tab-shower-lg-on', );
$tab-wrench-on-name: 'tab-wrench-on';
$tab-wrench-on-x: 380px;
$tab-wrench-on-y: 258px;
$tab-wrench-on-offset-x: -380px;
$tab-wrench-on-offset-y: -258px;
$tab-wrench-on-width: 24px;
$tab-wrench-on-height: 24px;
$tab-wrench-on-total-width: 471px;
$tab-wrench-on-total-height: 406px;
$tab-wrench-on-image: '../img/sprite.png';
$tab-wrench-on: (380px, 258px, -380px, -258px, 24px, 24px, 471px, 406px, '../img/sprite.png', 'tab-wrench-on', );
$tab-wrench-name: 'tab-wrench';
$tab-wrench-x: 380px;
$tab-wrench-y: 284px;
$tab-wrench-offset-x: -380px;
$tab-wrench-offset-y: -284px;
$tab-wrench-width: 24px;
$tab-wrench-height: 24px;
$tab-wrench-total-width: 471px;
$tab-wrench-total-height: 406px;
$tab-wrench-image: '../img/sprite.png';
$tab-wrench: (380px, 284px, -380px, -284px, 24px, 24px, 471px, 406px, '../img/sprite.png', 'tab-wrench', );
$tick-blue-name: 'tick-blue';
$tick-blue-x: 380px;
$tick-blue-y: 335px;
$tick-blue-offset-x: -380px;
$tick-blue-offset-y: -335px;
$tick-blue-width: 24px;
$tick-blue-height: 18px;
$tick-blue-total-width: 471px;
$tick-blue-total-height: 406px;
$tick-blue-image: '../img/sprite.png';
$tick-blue: (380px, 335px, -380px, -335px, 24px, 18px, 471px, 406px, '../img/sprite.png', 'tick-blue', );
$tick-white-name: 'tick-white';
$tick-white-x: 406px;
$tick-white-y: 120px;
$tick-white-offset-x: -406px;
$tick-white-offset-y: -120px;
$tick-white-width: 17px;
$tick-white-height: 13px;
$tick-white-total-width: 471px;
$tick-white-total-height: 406px;
$tick-white-image: '../img/sprite.png';
$tick-white: (406px, 120px, -406px, -120px, 17px, 13px, 471px, 406px, '../img/sprite.png', 'tick-white', );
$advice-tab-2x-name: 'advice-tab@2x';
$advice-tab-2x-x: 852px;
$advice-tab-2x-y: 422px;
$advice-tab-2x-offset-x: -852px;
$advice-tab-2x-offset-y: -422px;
$advice-tab-2x-width: 58px;
$advice-tab-2x-height: 56px;
$advice-tab-2x-total-width: 942px;
$advice-tab-2x-total-height: 812px;
$advice-tab-2x-image: '../img/sprite@2x.png';
$advice-tab-2x: (852px, 422px, -852px, -422px, 58px, 56px, 942px, 812px, '../img/sprite@2x.png', 'advice-tab@2x', );
$alert-2x-name: 'alert@2x';
$alert-2x-x: 408px;
$alert-2x-y: 174px;
$alert-2x-offset-x: -408px;
$alert-2x-offset-y: -174px;
$alert-2x-width: 44px;
$alert-2x-height: 44px;
$alert-2x-total-width: 942px;
$alert-2x-total-height: 812px;
$alert-2x-image: '../img/sprite@2x.png';
$alert-2x: (408px, 174px, -408px, -174px, 44px, 44px, 942px, 812px, '../img/sprite@2x.png', 'alert@2x', );
$arrow-down-blue-2x-name: 'arrow-down-blue@2x';
$arrow-down-blue-2x-x: 914px;
$arrow-down-blue-2x-y: 388px;
$arrow-down-blue-2x-offset-x: -914px;
$arrow-down-blue-2x-offset-y: -388px;
$arrow-down-blue-2x-width: 28px;
$arrow-down-blue-2x-height: 16px;
$arrow-down-blue-2x-total-width: 942px;
$arrow-down-blue-2x-total-height: 812px;
$arrow-down-blue-2x-image: '../img/sprite@2x.png';
$arrow-down-blue-2x: (914px, 388px, -914px, -388px, 28px, 16px, 942px, 812px, '../img/sprite@2x.png', 'arrow-down-blue@2x', );
$arrow-down-white-sm-2x-name: 'arrow-down-white-sm@2x';
$arrow-down-white-sm-2x-x: 910px;
$arrow-down-white-sm-2x-y: 622px;
$arrow-down-white-sm-2x-offset-x: -910px;
$arrow-down-white-sm-2x-offset-y: -622px;
$arrow-down-white-sm-2x-width: 22px;
$arrow-down-white-sm-2x-height: 14px;
$arrow-down-white-sm-2x-total-width: 942px;
$arrow-down-white-sm-2x-total-height: 812px;
$arrow-down-white-sm-2x-image: '../img/sprite@2x.png';
$arrow-down-white-sm-2x: (910px, 622px, -910px, -622px, 22px, 14px, 942px, 812px, '../img/sprite@2x.png', 'arrow-down-white-sm@2x', );
$arrow-down-white-2x-name: 'arrow-down-white@2x';
$arrow-down-white-2x-x: 406px;
$arrow-down-white-2x-y: 234px;
$arrow-down-white-2x-offset-x: -406px;
$arrow-down-white-2x-offset-y: -234px;
$arrow-down-white-2x-width: 42px;
$arrow-down-white-2x-height: 24px;
$arrow-down-white-2x-total-width: 942px;
$arrow-down-white-2x-total-height: 812px;
$arrow-down-white-2x-image: '../img/sprite@2x.png';
$arrow-down-white-2x: (406px, 234px, -406px, -234px, 42px, 24px, 942px, 812px, '../img/sprite@2x.png', 'arrow-down-white@2x', );
$arrow-down-2x-name: 'arrow-down@2x';
$arrow-down-2x-x: 914px;
$arrow-down-2x-y: 442px;
$arrow-down-2x-offset-x: -914px;
$arrow-down-2x-offset-y: -442px;
$arrow-down-2x-width: 26px;
$arrow-down-2x-height: 14px;
$arrow-down-2x-total-width: 942px;
$arrow-down-2x-total-height: 812px;
$arrow-down-2x-image: '../img/sprite@2x.png';
$arrow-down-2x: (914px, 442px, -914px, -442px, 26px, 14px, 942px, 812px, '../img/sprite@2x.png', 'arrow-down@2x', );
$arrow-left-2x-name: 'arrow-left@2x';
$arrow-left-2x-x: 928px;
$arrow-left-2x-y: 542px;
$arrow-left-2x-offset-x: -928px;
$arrow-left-2x-offset-y: -542px;
$arrow-left-2x-width: 14px;
$arrow-left-2x-height: 26px;
$arrow-left-2x-total-width: 942px;
$arrow-left-2x-total-height: 812px;
$arrow-left-2x-image: '../img/sprite@2x.png';
$arrow-left-2x: (928px, 542px, -928px, -542px, 14px, 26px, 942px, 812px, '../img/sprite@2x.png', 'arrow-left@2x', );
$arrow-right-white-2x-name: 'arrow-right-white@2x';
$arrow-right-white-2x-x: 910px;
$arrow-right-white-2x-y: 646px;
$arrow-right-white-2x-offset-x: -910px;
$arrow-right-white-2x-offset-y: -646px;
$arrow-right-white-2x-width: 14px;
$arrow-right-white-2x-height: 26px;
$arrow-right-white-2x-total-width: 942px;
$arrow-right-white-2x-total-height: 812px;
$arrow-right-white-2x-image: '../img/sprite@2x.png';
$arrow-right-white-2x: (910px, 646px, -910px, -646px, 14px, 26px, 942px, 812px, '../img/sprite@2x.png', 'arrow-right-white@2x', );
$arrow-right-2x-name: 'arrow-right@2x';
$arrow-right-2x-x: 928px;
$arrow-right-2x-y: 646px;
$arrow-right-2x-offset-x: -928px;
$arrow-right-2x-offset-y: -646px;
$arrow-right-2x-width: 14px;
$arrow-right-2x-height: 26px;
$arrow-right-2x-total-width: 942px;
$arrow-right-2x-total-height: 812px;
$arrow-right-2x-image: '../img/sprite@2x.png';
$arrow-right-2x: (928px, 646px, -928px, -646px, 14px, 26px, 942px, 812px, '../img/sprite@2x.png', 'arrow-right@2x', );
$arrow-up-blue-2x-name: 'arrow-up-blue@2x';
$arrow-up-blue-2x-x: 914px;
$arrow-up-blue-2x-y: 422px;
$arrow-up-blue-2x-offset-x: -914px;
$arrow-up-blue-2x-offset-y: -422px;
$arrow-up-blue-2x-width: 28px;
$arrow-up-blue-2x-height: 16px;
$arrow-up-blue-2x-total-width: 942px;
$arrow-up-blue-2x-total-height: 812px;
$arrow-up-blue-2x-image: '../img/sprite@2x.png';
$arrow-up-blue-2x: (914px, 422px, -914px, -422px, 28px, 16px, 942px, 812px, '../img/sprite@2x.png', 'arrow-up-blue@2x', );
$arrow-up-white-sm-2x-name: 'arrow-up-white-sm@2x';
$arrow-up-white-sm-2x-x: 910px;
$arrow-up-white-sm-2x-y: 676px;
$arrow-up-white-sm-2x-offset-x: -910px;
$arrow-up-white-sm-2x-offset-y: -676px;
$arrow-up-white-sm-2x-width: 22px;
$arrow-up-white-sm-2x-height: 14px;
$arrow-up-white-sm-2x-total-width: 942px;
$arrow-up-white-sm-2x-total-height: 812px;
$arrow-up-white-sm-2x-image: '../img/sprite@2x.png';
$arrow-up-white-sm-2x: (910px, 676px, -910px, -676px, 22px, 14px, 942px, 812px, '../img/sprite@2x.png', 'arrow-up-white-sm@2x', );
$arrow-up-white-2x-name: 'arrow-up-white@2x';
$arrow-up-white-2x-x: 318px;
$arrow-up-white-2x-y: 278px;
$arrow-up-white-2x-offset-x: -318px;
$arrow-up-white-2x-offset-y: -278px;
$arrow-up-white-2x-width: 42px;
$arrow-up-white-2x-height: 24px;
$arrow-up-white-2x-total-width: 942px;
$arrow-up-white-2x-total-height: 812px;
$arrow-up-white-2x-image: '../img/sprite@2x.png';
$arrow-up-white-2x: (318px, 278px, -318px, -278px, 42px, 24px, 942px, 812px, '../img/sprite@2x.png', 'arrow-up-white@2x', );
$arrow-up-2x-name: 'arrow-up@2x';
$arrow-up-2x-x: 914px;
$arrow-up-2x-y: 460px;
$arrow-up-2x-offset-x: -914px;
$arrow-up-2x-offset-y: -460px;
$arrow-up-2x-width: 26px;
$arrow-up-2x-height: 14px;
$arrow-up-2x-total-width: 942px;
$arrow-up-2x-total-height: 812px;
$arrow-up-2x-image: '../img/sprite@2x.png';
$arrow-up-2x: (914px, 460px, -914px, -460px, 26px, 14px, 942px, 812px, '../img/sprite@2x.png', 'arrow-up@2x', );
$australia-icon-2x-name: 'australia-icon@2x';
$australia-icon-2x-x: 700px;
$australia-icon-2x-y: 716px;
$australia-icon-2x-offset-x: -700px;
$australia-icon-2x-offset-y: -716px;
$australia-icon-2x-width: 98px;
$australia-icon-2x-height: 88px;
$australia-icon-2x-total-width: 942px;
$australia-icon-2x-total-height: 812px;
$australia-icon-2x-image: '../img/sprite@2x.png';
$australia-icon-2x: (700px, 716px, -700px, -716px, 98px, 88px, 942px, 812px, '../img/sprite@2x.png', 'australia-icon@2x', );
$back-to-top-2x-name: 'back-to-top@2x';
$back-to-top-2x-x: 912px;
$back-to-top-2x-y: 516px;
$back-to-top-2x-offset-x: -912px;
$back-to-top-2x-offset-y: -516px;
$back-to-top-2x-width: 24px;
$back-to-top-2x-height: 14px;
$back-to-top-2x-total-width: 942px;
$back-to-top-2x-total-height: 812px;
$back-to-top-2x-image: '../img/sprite@2x.png';
$back-to-top-2x: (912px, 516px, -912px, -516px, 24px, 14px, 942px, 812px, '../img/sprite@2x.png', 'back-to-top@2x', );
$check-mark-2x-name: 'check-mark@2x';
$check-mark-2x-x: 114px;
$check-mark-2x-y: 602px;
$check-mark-2x-offset-x: -114px;
$check-mark-2x-offset-y: -602px;
$check-mark-2x-width: 102px;
$check-mark-2x-height: 102px;
$check-mark-2x-total-width: 942px;
$check-mark-2x-total-height: 812px;
$check-mark-2x-image: '../img/sprite@2x.png';
$check-mark-2x: (114px, 602px, -114px, -602px, 102px, 102px, 942px, 812px, '../img/sprite@2x.png', 'check-mark@2x', );
$close-large-2x-name: 'close-large@2x';
$close-large-2x-x: 852px;
$close-large-2x-y: 762px;
$close-large-2x-offset-x: -852px;
$close-large-2x-offset-y: -762px;
$close-large-2x-width: 48px;
$close-large-2x-height: 48px;
$close-large-2x-total-width: 942px;
$close-large-2x-total-height: 812px;
$close-large-2x-image: '../img/sprite@2x.png';
$close-large-2x: (852px, 762px, -852px, -762px, 48px, 48px, 942px, 812px, '../img/sprite@2x.png', 'close-large@2x', );
$close-2x-name: 'close@2x';
$close-2x-x: 914px;
$close-2x-y: 360px;
$close-2x-offset-x: -914px;
$close-2x-offset-y: -360px;
$close-2x-width: 24px;
$close-2x-height: 24px;
$close-2x-total-width: 942px;
$close-2x-total-height: 812px;
$close-2x-image: '../img/sprite@2x.png';
$close-2x: (914px, 360px, -914px, -360px, 24px, 24px, 942px, 812px, '../img/sprite@2x.png', 'close@2x', );
$desktop-logo-2x-name: 'desktop-logo@2x';
$desktop-logo-2x-x: 0px;
$desktop-logo-2x-y: 0px;
$desktop-logo-2x-offset-x: 0px;
$desktop-logo-2x-offset-y: 0px;
$desktop-logo-2x-width: 478px;
$desktop-logo-2x-height: 120px;
$desktop-logo-2x-total-width: 942px;
$desktop-logo-2x-total-height: 812px;
$desktop-logo-2x-image: '../img/sprite@2x.png';
$desktop-logo-2x: (0px, 0px, 0px, 0px, 478px, 120px, 942px, 812px, '../img/sprite@2x.png', 'desktop-logo@2x', );
$dot-cursor-lg-2x-name: 'dot-cursor-lg@2x';
$dot-cursor-lg-2x-x: 852px;
$dot-cursor-lg-2x-y: 0px;
$dot-cursor-lg-2x-offset-x: -852px;
$dot-cursor-lg-2x-offset-y: 0px;
$dot-cursor-lg-2x-width: 90px;
$dot-cursor-lg-2x-height: 90px;
$dot-cursor-lg-2x-total-width: 942px;
$dot-cursor-lg-2x-total-height: 812px;
$dot-cursor-lg-2x-image: '../img/sprite@2x.png';
$dot-cursor-lg-2x: (852px, 0px, -852px, 0px, 90px, 90px, 942px, 812px, '../img/sprite@2x.png', 'dot-cursor-lg@2x', );
$dot-email-xl-2x-name: 'dot-email-xl@2x';
$dot-email-xl-2x-x: 0px;
$dot-email-xl-2x-y: 488px;
$dot-email-xl-2x-offset-x: 0px;
$dot-email-xl-2x-offset-y: -488px;
$dot-email-xl-2x-width: 110px;
$dot-email-xl-2x-height: 110px;
$dot-email-xl-2x-total-width: 942px;
$dot-email-xl-2x-total-height: 812px;
$dot-email-xl-2x-image: '../img/sprite@2x.png';
$dot-email-xl-2x: (0px, 488px, 0px, -488px, 110px, 110px, 942px, 812px, '../img/sprite@2x.png', 'dot-email-xl@2x', );
$dot-email-2x-name: 'dot-email@2x';
$dot-email-2x-x: 852px;
$dot-email-2x-y: 588px;
$dot-email-2x-offset-x: -852px;
$dot-email-2x-offset-y: -588px;
$dot-email-2x-width: 54px;
$dot-email-2x-height: 54px;
$dot-email-2x-total-width: 942px;
$dot-email-2x-total-height: 812px;
$dot-email-2x-image: '../img/sprite@2x.png';
$dot-email-2x: (852px, 588px, -852px, -588px, 54px, 54px, 942px, 812px, '../img/sprite@2x.png', 'dot-email@2x', );
$dot-home-2x-name: 'dot-home@2x';
$dot-home-2x-x: 852px;
$dot-home-2x-y: 646px;
$dot-home-2x-offset-x: -852px;
$dot-home-2x-offset-y: -646px;
$dot-home-2x-width: 54px;
$dot-home-2x-height: 54px;
$dot-home-2x-total-width: 942px;
$dot-home-2x-total-height: 812px;
$dot-home-2x-image: '../img/sprite@2x.png';
$dot-home-2x: (852px, 646px, -852px, -646px, 54px, 54px, 942px, 812px, '../img/sprite@2x.png', 'dot-home@2x', );
$dot-login-2x-name: 'dot-login@2x';
$dot-login-2x-x: 852px;
$dot-login-2x-y: 704px;
$dot-login-2x-offset-x: -852px;
$dot-login-2x-offset-y: -704px;
$dot-login-2x-width: 54px;
$dot-login-2x-height: 54px;
$dot-login-2x-total-width: 942px;
$dot-login-2x-total-height: 812px;
$dot-login-2x-image: '../img/sprite@2x.png';
$dot-login-2x: (852px, 704px, -852px, -704px, 54px, 54px, 942px, 812px, '../img/sprite@2x.png', 'dot-login@2x', );
$dot-mail-lg-2x-name: 'dot-mail-lg@2x';
$dot-mail-lg-2x-x: 852px;
$dot-mail-lg-2x-y: 94px;
$dot-mail-lg-2x-offset-x: -852px;
$dot-mail-lg-2x-offset-y: -94px;
$dot-mail-lg-2x-width: 90px;
$dot-mail-lg-2x-height: 90px;
$dot-mail-lg-2x-total-width: 942px;
$dot-mail-lg-2x-total-height: 812px;
$dot-mail-lg-2x-image: '../img/sprite@2x.png';
$dot-mail-lg-2x: (852px, 94px, -852px, -94px, 90px, 90px, 942px, 812px, '../img/sprite@2x.png', 'dot-mail-lg@2x', );
$dot-mail-xl-2x-name: 'dot-mail-xl@2x';
$dot-mail-xl-2x-x: 114px;
$dot-mail-xl-2x-y: 488px;
$dot-mail-xl-2x-offset-x: -114px;
$dot-mail-xl-2x-offset-y: -488px;
$dot-mail-xl-2x-width: 110px;
$dot-mail-xl-2x-height: 110px;
$dot-mail-xl-2x-total-width: 942px;
$dot-mail-xl-2x-total-height: 812px;
$dot-mail-xl-2x-image: '../img/sprite@2x.png';
$dot-mail-xl-2x: (114px, 488px, -114px, -488px, 110px, 110px, 942px, 812px, '../img/sprite@2x.png', 'dot-mail-xl@2x', );
$dot-mail-2x-name: 'dot-mail@2x';
$dot-mail-2x-x: 760px;
$dot-mail-2x-y: 124px;
$dot-mail-2x-offset-x: -760px;
$dot-mail-2x-offset-y: -124px;
$dot-mail-2x-width: 54px;
$dot-mail-2x-height: 54px;
$dot-mail-2x-total-width: 942px;
$dot-mail-2x-total-height: 812px;
$dot-mail-2x-image: '../img/sprite@2x.png';
$dot-mail-2x: (760px, 124px, -760px, -124px, 54px, 54px, 942px, 812px, '../img/sprite@2x.png', 'dot-mail@2x', );
$dot-phone-alt-2x-name: 'dot-phone-alt@2x';
$dot-phone-alt-2x-x: 228px;
$dot-phone-alt-2x-y: 488px;
$dot-phone-alt-2x-offset-x: -228px;
$dot-phone-alt-2x-offset-y: -488px;
$dot-phone-alt-2x-width: 110px;
$dot-phone-alt-2x-height: 110px;
$dot-phone-alt-2x-total-width: 942px;
$dot-phone-alt-2x-total-height: 812px;
$dot-phone-alt-2x-image: '../img/sprite@2x.png';
$dot-phone-alt-2x: (228px, 488px, -228px, -488px, 110px, 110px, 942px, 812px, '../img/sprite@2x.png', 'dot-phone-alt@2x', );
$dot-phone-large-2x-name: 'dot-phone-large@2x';
$dot-phone-large-2x-x: 342px;
$dot-phone-large-2x-y: 488px;
$dot-phone-large-2x-offset-x: -342px;
$dot-phone-large-2x-offset-y: -488px;
$dot-phone-large-2x-width: 110px;
$dot-phone-large-2x-height: 110px;
$dot-phone-large-2x-total-width: 942px;
$dot-phone-large-2x-total-height: 812px;
$dot-phone-large-2x-image: '../img/sprite@2x.png';
$dot-phone-large-2x: (342px, 488px, -342px, -488px, 110px, 110px, 942px, 812px, '../img/sprite@2x.png', 'dot-phone-large@2x', );
$dot-phone-lg-2x-name: 'dot-phone-lg@2x';
$dot-phone-lg-2x-x: 852px;
$dot-phone-lg-2x-y: 188px;
$dot-phone-lg-2x-offset-x: -852px;
$dot-phone-lg-2x-offset-y: -188px;
$dot-phone-lg-2x-width: 90px;
$dot-phone-lg-2x-height: 90px;
$dot-phone-lg-2x-total-width: 942px;
$dot-phone-lg-2x-total-height: 812px;
$dot-phone-lg-2x-image: '../img/sprite@2x.png';
$dot-phone-lg-2x: (852px, 188px, -852px, -188px, 90px, 90px, 942px, 812px, '../img/sprite@2x.png', 'dot-phone-lg@2x', );
$dot-phone-xl-2x-name: 'dot-phone-xl@2x';
$dot-phone-xl-2x-x: 456px;
$dot-phone-xl-2x-y: 488px;
$dot-phone-xl-2x-offset-x: -456px;
$dot-phone-xl-2x-offset-y: -488px;
$dot-phone-xl-2x-width: 110px;
$dot-phone-xl-2x-height: 110px;
$dot-phone-xl-2x-total-width: 942px;
$dot-phone-xl-2x-total-height: 812px;
$dot-phone-xl-2x-image: '../img/sprite@2x.png';
$dot-phone-xl-2x: (456px, 488px, -456px, -488px, 110px, 110px, 942px, 812px, '../img/sprite@2x.png', 'dot-phone-xl@2x', );
$dot-phone-2x-name: 'dot-phone@2x';
$dot-phone-2x-x: 760px;
$dot-phone-2x-y: 182px;
$dot-phone-2x-offset-x: -760px;
$dot-phone-2x-offset-y: -182px;
$dot-phone-2x-width: 54px;
$dot-phone-2x-height: 54px;
$dot-phone-2x-total-width: 942px;
$dot-phone-2x-total-height: 812px;
$dot-phone-2x-image: '../img/sprite@2x.png';
$dot-phone-2x: (760px, 182px, -760px, -182px, 54px, 54px, 942px, 812px, '../img/sprite@2x.png', 'dot-phone@2x', );
$dot-question-large-2x-name: 'dot-question-large@2x';
$dot-question-large-2x-x: 570px;
$dot-question-large-2x-y: 488px;
$dot-question-large-2x-offset-x: -570px;
$dot-question-large-2x-offset-y: -488px;
$dot-question-large-2x-width: 110px;
$dot-question-large-2x-height: 110px;
$dot-question-large-2x-total-width: 942px;
$dot-question-large-2x-total-height: 812px;
$dot-question-large-2x-image: '../img/sprite@2x.png';
$dot-question-large-2x: (570px, 488px, -570px, -488px, 110px, 110px, 942px, 812px, '../img/sprite@2x.png', 'dot-question-large@2x', );
$dot-success-large-2x-name: 'dot-success-large@2x';
$dot-success-large-2x-x: 0px;
$dot-success-large-2x-y: 602px;
$dot-success-large-2x-offset-x: 0px;
$dot-success-large-2x-offset-y: -602px;
$dot-success-large-2x-width: 110px;
$dot-success-large-2x-height: 110px;
$dot-success-large-2x-total-width: 942px;
$dot-success-large-2x-total-height: 812px;
$dot-success-large-2x-image: '../img/sprite@2x.png';
$dot-success-large-2x: (0px, 602px, 0px, -602px, 110px, 110px, 942px, 812px, '../img/sprite@2x.png', 'dot-success-large@2x', );
$environment-2x-name: 'environment@2x';
$environment-2x-x: 220px;
$environment-2x-y: 602px;
$environment-2x-offset-x: -220px;
$environment-2x-offset-y: -602px;
$environment-2x-width: 96px;
$environment-2x-height: 96px;
$environment-2x-total-width: 942px;
$environment-2x-total-height: 812px;
$environment-2x-image: '../img/sprite@2x.png';
$environment-2x: (220px, 602px, -220px, -602px, 96px, 96px, 942px, 812px, '../img/sprite@2x.png', 'environment@2x', );
$fb-2x-name: 'fb@2x';
$fb-2x-x: 852px;
$fb-2x-y: 282px;
$fb-2x-offset-x: -852px;
$fb-2x-offset-y: -282px;
$fb-2x-width: 74px;
$fb-2x-height: 74px;
$fb-2x-total-width: 942px;
$fb-2x-total-height: 812px;
$fb-2x-image: '../img/sprite@2x.png';
$fb-2x: (852px, 282px, -852px, -282px, 74px, 74px, 942px, 812px, '../img/sprite@2x.png', 'fb@2x', );
$footer-logo-2x-name: 'footer-logo@2x';
$footer-logo-2x-x: 0px;
$footer-logo-2x-y: 124px;
$footer-logo-2x-offset-x: 0px;
$footer-logo-2x-offset-y: -124px;
$footer-logo-2x-width: 404px;
$footer-logo-2x-height: 106px;
$footer-logo-2x-total-width: 942px;
$footer-logo-2x-total-height: 812px;
$footer-logo-2x-image: '../img/sprite@2x.png';
$footer-logo-2x: (0px, 124px, 0px, -124px, 404px, 106px, 942px, 812px, '../img/sprite@2x.png', 'footer-logo@2x', );
$gas-tab-2x-name: 'gas-tab@2x';
$gas-tab-2x-x: 760px;
$gas-tab-2x-y: 240px;
$gas-tab-2x-offset-x: -760px;
$gas-tab-2x-offset-y: -240px;
$gas-tab-2x-width: 48px;
$gas-tab-2x-height: 56px;
$gas-tab-2x-total-width: 942px;
$gas-tab-2x-total-height: 812px;
$gas-tab-2x-image: '../img/sprite@2x.png';
$gas-tab-2x: (760px, 240px, -760px, -240px, 48px, 56px, 942px, 812px, '../img/sprite@2x.png', 'gas-tab@2x', );
$hamburger-2x-name: 'hamburger@2x';
$hamburger-2x-x: 852px;
$hamburger-2x-y: 542px;
$hamburger-2x-offset-x: -852px;
$hamburger-2x-offset-y: -542px;
$hamburger-2x-width: 72px;
$hamburger-2x-height: 42px;
$hamburger-2x-total-width: 942px;
$hamburger-2x-total-height: 812px;
$hamburger-2x-image: '../img/sprite@2x.png';
$hamburger-2x: (852px, 542px, -852px, -542px, 72px, 42px, 942px, 812px, '../img/sprite@2x.png', 'hamburger@2x', );
$header-location-2x-name: 'header-location@2x';
$header-location-2x-x: 852px;
$header-location-2x-y: 482px;
$header-location-2x-offset-x: -852px;
$header-location-2x-offset-y: -482px;
$header-location-2x-width: 56px;
$header-location-2x-height: 56px;
$header-location-2x-total-width: 942px;
$header-location-2x-total-height: 812px;
$header-location-2x-image: '../img/sprite@2x.png';
$header-location-2x: (852px, 482px, -852px, -482px, 56px, 56px, 942px, 812px, '../img/sprite@2x.png', 'header-location@2x', );
$heading-fridge-2x-name: 'heading-fridge@2x';
$heading-fridge-2x-x: 320px;
$heading-fridge-2x-y: 602px;
$heading-fridge-2x-offset-x: -320px;
$heading-fridge-2x-offset-y: -602px;
$heading-fridge-2x-width: 96px;
$heading-fridge-2x-height: 96px;
$heading-fridge-2x-total-width: 942px;
$heading-fridge-2x-total-height: 812px;
$heading-fridge-2x-image: '../img/sprite@2x.png';
$heading-fridge-2x: (320px, 602px, -320px, -602px, 96px, 96px, 942px, 812px, '../img/sprite@2x.png', 'heading-fridge@2x', );
$heading-heater-2x-name: 'heading-heater@2x';
$heading-heater-2x-x: 420px;
$heading-heater-2x-y: 602px;
$heading-heater-2x-offset-x: -420px;
$heading-heater-2x-offset-y: -602px;
$heading-heater-2x-width: 96px;
$heading-heater-2x-height: 96px;
$heading-heater-2x-total-width: 942px;
$heading-heater-2x-total-height: 812px;
$heading-heater-2x-image: '../img/sprite@2x.png';
$heading-heater-2x: (420px, 602px, -420px, -602px, 96px, 96px, 942px, 812px, '../img/sprite@2x.png', 'heading-heater@2x', );
$heading-home-2x-name: 'heading-home@2x';
$heading-home-2x-x: 520px;
$heading-home-2x-y: 602px;
$heading-home-2x-offset-x: -520px;
$heading-home-2x-offset-y: -602px;
$heading-home-2x-width: 96px;
$heading-home-2x-height: 96px;
$heading-home-2x-total-width: 942px;
$heading-home-2x-total-height: 812px;
$heading-home-2x-image: '../img/sprite@2x.png';
$heading-home-2x: (520px, 602px, -520px, -602px, 96px, 96px, 942px, 812px, '../img/sprite@2x.png', 'heading-home@2x', );
$heading-light-2x-name: 'heading-light@2x';
$heading-light-2x-x: 620px;
$heading-light-2x-y: 602px;
$heading-light-2x-offset-x: -620px;
$heading-light-2x-offset-y: -602px;
$heading-light-2x-width: 96px;
$heading-light-2x-height: 96px;
$heading-light-2x-total-width: 942px;
$heading-light-2x-total-height: 812px;
$heading-light-2x-image: '../img/sprite@2x.png';
$heading-light-2x: (620px, 602px, -620px, -602px, 96px, 96px, 942px, 812px, '../img/sprite@2x.png', 'heading-light@2x', );
$heading-magnifier-2x-name: 'heading-magnifier@2x';
$heading-magnifier-2x-x: 0px;
$heading-magnifier-2x-y: 716px;
$heading-magnifier-2x-offset-x: 0px;
$heading-magnifier-2x-offset-y: -716px;
$heading-magnifier-2x-width: 96px;
$heading-magnifier-2x-height: 96px;
$heading-magnifier-2x-total-width: 942px;
$heading-magnifier-2x-total-height: 812px;
$heading-magnifier-2x-image: '../img/sprite@2x.png';
$heading-magnifier-2x: (0px, 716px, 0px, -716px, 96px, 96px, 942px, 812px, '../img/sprite@2x.png', 'heading-magnifier@2x', );
$heading-report-2x-name: 'heading-report@2x';
$heading-report-2x-x: 100px;
$heading-report-2x-y: 716px;
$heading-report-2x-offset-x: -100px;
$heading-report-2x-offset-y: -716px;
$heading-report-2x-width: 96px;
$heading-report-2x-height: 96px;
$heading-report-2x-total-width: 942px;
$heading-report-2x-total-height: 812px;
$heading-report-2x-image: '../img/sprite@2x.png';
$heading-report-2x: (100px, 716px, -100px, -716px, 96px, 96px, 942px, 812px, '../img/sprite@2x.png', 'heading-report@2x', );
$heading-shower-2x-name: 'heading-shower@2x';
$heading-shower-2x-x: 200px;
$heading-shower-2x-y: 716px;
$heading-shower-2x-offset-x: -200px;
$heading-shower-2x-offset-y: -716px;
$heading-shower-2x-width: 96px;
$heading-shower-2x-height: 96px;
$heading-shower-2x-total-width: 942px;
$heading-shower-2x-total-height: 812px;
$heading-shower-2x-image: '../img/sprite@2x.png';
$heading-shower-2x: (200px, 716px, -200px, -716px, 96px, 96px, 942px, 812px, '../img/sprite@2x.png', 'heading-shower@2x', );
$heading-washer-2x-name: 'heading-washer@2x';
$heading-washer-2x-x: 300px;
$heading-washer-2x-y: 716px;
$heading-washer-2x-offset-x: -300px;
$heading-washer-2x-offset-y: -716px;
$heading-washer-2x-width: 96px;
$heading-washer-2x-height: 96px;
$heading-washer-2x-total-width: 942px;
$heading-washer-2x-total-height: 812px;
$heading-washer-2x-image: '../img/sprite@2x.png';
$heading-washer-2x: (300px, 716px, -300px, -716px, 96px, 96px, 942px, 812px, '../img/sprite@2x.png', 'heading-washer@2x', );
$heading-wrench-2x-name: 'heading-wrench@2x';
$heading-wrench-2x-x: 400px;
$heading-wrench-2x-y: 716px;
$heading-wrench-2x-offset-x: -400px;
$heading-wrench-2x-offset-y: -716px;
$heading-wrench-2x-width: 96px;
$heading-wrench-2x-height: 96px;
$heading-wrench-2x-total-width: 942px;
$heading-wrench-2x-total-height: 812px;
$heading-wrench-2x-image: '../img/sprite@2x.png';
$heading-wrench-2x: (400px, 716px, -400px, -716px, 96px, 96px, 942px, 812px, '../img/sprite@2x.png', 'heading-wrench@2x', );
$mobile-logo-2x-name: 'mobile-logo@2x';
$mobile-logo-2x-x: 0px;
$mobile-logo-2x-y: 234px;
$mobile-logo-2x-offset-x: 0px;
$mobile-logo-2x-offset-y: -234px;
$mobile-logo-2x-width: 314px;
$mobile-logo-2x-height: 80px;
$mobile-logo-2x-total-width: 942px;
$mobile-logo-2x-total-height: 812px;
$mobile-logo-2x-image: '../img/sprite@2x.png';
$mobile-logo-2x: (0px, 234px, 0px, -234px, 314px, 80px, 942px, 812px, '../img/sprite@2x.png', 'mobile-logo@2x', );
$news-tab-2x-name: 'news-tab@2x';
$news-tab-2x-x: 760px;
$news-tab-2x-y: 300px;
$news-tab-2x-offset-x: -760px;
$news-tab-2x-offset-y: -300px;
$news-tab-2x-width: 42px;
$news-tab-2x-height: 56px;
$news-tab-2x-total-width: 942px;
$news-tab-2x-total-height: 812px;
$news-tab-2x-image: '../img/sprite@2x.png';
$news-tab-2x: (760px, 300px, -760px, -300px, 42px, 56px, 942px, 812px, '../img/sprite@2x.png', 'news-tab@2x', );
$phone-btn-2x-name: 'phone-btn@2x';
$phone-btn-2x-x: 852px;
$phone-btn-2x-y: 360px;
$phone-btn-2x-offset-x: -852px;
$phone-btn-2x-offset-y: -360px;
$phone-btn-2x-width: 58px;
$phone-btn-2x-height: 58px;
$phone-btn-2x-total-width: 942px;
$phone-btn-2x-total-height: 812px;
$phone-btn-2x-image: '../img/sprite@2x.png';
$phone-btn-2x: (852px, 360px, -852px, -360px, 58px, 58px, 942px, 812px, '../img/sprite@2x.png', 'phone-btn@2x', );
$question-collapse-2x-name: 'question-collapse@2x';
$question-collapse-2x-x: 912px;
$question-collapse-2x-y: 482px;
$question-collapse-2x-offset-x: -912px;
$question-collapse-2x-offset-y: -482px;
$question-collapse-2x-width: 30px;
$question-collapse-2x-height: 30px;
$question-collapse-2x-total-width: 942px;
$question-collapse-2x-total-height: 812px;
$question-collapse-2x-image: '../img/sprite@2x.png';
$question-collapse-2x: (912px, 482px, -912px, -482px, 30px, 30px, 942px, 812px, '../img/sprite@2x.png', 'question-collapse@2x', );
$question-expand-2x-name: 'question-expand@2x';
$question-expand-2x-x: 910px;
$question-expand-2x-y: 588px;
$question-expand-2x-offset-x: -910px;
$question-expand-2x-offset-y: -588px;
$question-expand-2x-width: 30px;
$question-expand-2x-height: 30px;
$question-expand-2x-total-width: 942px;
$question-expand-2x-total-height: 812px;
$question-expand-2x-image: '../img/sprite@2x.png';
$question-expand-2x: (910px, 588px, -910px, -588px, 30px, 30px, 942px, 812px, '../img/sprite@2x.png', 'question-expand@2x', );
$rebates-2x-name: 'rebates@2x';
$rebates-2x-x: 500px;
$rebates-2x-y: 716px;
$rebates-2x-offset-x: -500px;
$rebates-2x-offset-y: -716px;
$rebates-2x-width: 96px;
$rebates-2x-height: 96px;
$rebates-2x-total-width: 942px;
$rebates-2x-total-height: 812px;
$rebates-2x-image: '../img/sprite@2x.png';
$rebates-2x: (500px, 716px, -500px, -716px, 96px, 96px, 942px, 812px, '../img/sprite@2x.png', 'rebates@2x', );
$savings-2x-name: 'savings@2x';
$savings-2x-x: 600px;
$savings-2x-y: 716px;
$savings-2x-offset-x: -600px;
$savings-2x-offset-y: -716px;
$savings-2x-width: 96px;
$savings-2x-height: 96px;
$savings-2x-total-width: 942px;
$savings-2x-total-height: 812px;
$savings-2x-image: '../img/sprite@2x.png';
$savings-2x: (600px, 716px, -600px, -716px, 96px, 96px, 942px, 812px, '../img/sprite@2x.png', 'savings@2x', );
$search-btn-white-2x-name: 'search-btn-white@2x';
$search-btn-white-2x-x: 318px;
$search-btn-white-2x-y: 234px;
$search-btn-white-2x-offset-x: -318px;
$search-btn-white-2x-offset-y: -234px;
$search-btn-white-2x-width: 40px;
$search-btn-white-2x-height: 40px;
$search-btn-white-2x-total-width: 942px;
$search-btn-white-2x-total-height: 812px;
$search-btn-white-2x-image: '../img/sprite@2x.png';
$search-btn-white-2x: (318px, 234px, -318px, -234px, 40px, 40px, 942px, 812px, '../img/sprite@2x.png', 'search-btn-white@2x', );
$search-btn-2x-name: 'search-btn@2x';
$search-btn-2x-x: 362px;
$search-btn-2x-y: 234px;
$search-btn-2x-offset-x: -362px;
$search-btn-2x-offset-y: -234px;
$search-btn-2x-width: 40px;
$search-btn-2x-height: 40px;
$search-btn-2x-total-width: 942px;
$search-btn-2x-total-height: 812px;
$search-btn-2x-image: '../img/sprite@2x.png';
$search-btn-2x: (362px, 234px, -362px, -234px, 40px, 40px, 942px, 812px, '../img/sprite@2x.png', 'search-btn@2x', );
$tab-appliance-on-2x-name: 'tab-appliance-on@2x';
$tab-appliance-on-2x-x: 760px;
$tab-appliance-on-2x-y: 360px;
$tab-appliance-on-2x-offset-x: -760px;
$tab-appliance-on-2x-offset-y: -360px;
$tab-appliance-on-2x-width: 48px;
$tab-appliance-on-2x-height: 48px;
$tab-appliance-on-2x-total-width: 942px;
$tab-appliance-on-2x-total-height: 812px;
$tab-appliance-on-2x-image: '../img/sprite@2x.png';
$tab-appliance-on-2x: (760px, 360px, -760px, -360px, 48px, 48px, 942px, 812px, '../img/sprite@2x.png', 'tab-appliance-on@2x', );
$tab-appliance-2x-name: 'tab-appliance@2x';
$tab-appliance-2x-x: 760px;
$tab-appliance-2x-y: 620px;
$tab-appliance-2x-offset-x: -760px;
$tab-appliance-2x-offset-y: -620px;
$tab-appliance-2x-width: 50px;
$tab-appliance-2x-height: 46px;
$tab-appliance-2x-total-width: 942px;
$tab-appliance-2x-total-height: 812px;
$tab-appliance-2x-image: '../img/sprite@2x.png';
$tab-appliance-2x: (760px, 620px, -760px, -620px, 50px, 46px, 942px, 812px, '../img/sprite@2x.png', 'tab-appliance@2x', );
$tab-business-large-on-2x-name: 'tab-business-large-on@2x';
$tab-business-large-on-2x-x: 760px;
$tab-business-large-on-2x-y: 0px;
$tab-business-large-on-2x-offset-x: -760px;
$tab-business-large-on-2x-offset-y: 0px;
$tab-business-large-on-2x-width: 88px;
$tab-business-large-on-2x-height: 120px;
$tab-business-large-on-2x-total-width: 942px;
$tab-business-large-on-2x-total-height: 812px;
$tab-business-large-on-2x-image: '../img/sprite@2x.png';
$tab-business-large-on-2x: (760px, 0px, -760px, 0px, 88px, 120px, 942px, 812px, '../img/sprite@2x.png', 'tab-business-large-on@2x', );
$tab-business-lg-2x-name: 'tab-business-lg@2x';
$tab-business-lg-2x-x: 634px;
$tab-business-lg-2x-y: 184px;
$tab-business-lg-2x-offset-x: -634px;
$tab-business-lg-2x-offset-y: -184px;
$tab-business-lg-2x-width: 108px;
$tab-business-lg-2x-height: 148px;
$tab-business-lg-2x-total-width: 942px;
$tab-business-lg-2x-total-height: 812px;
$tab-business-lg-2x-image: '../img/sprite@2x.png';
$tab-business-lg-2x: (634px, 184px, -634px, -184px, 108px, 148px, 942px, 812px, '../img/sprite@2x.png', 'tab-business-lg@2x', );
$tab-business-2x-name: 'tab-business@2x';
$tab-business-2x-x: 904px;
$tab-business-2x-y: 762px;
$tab-business-2x-offset-x: -904px;
$tab-business-2x-offset-y: -762px;
$tab-business-2x-width: 34px;
$tab-business-2x-height: 46px;
$tab-business-2x-total-width: 942px;
$tab-business-2x-total-height: 812px;
$tab-business-2x-image: '../img/sprite@2x.png';
$tab-business-2x: (904px, 762px, -904px, -762px, 34px, 46px, 942px, 812px, '../img/sprite@2x.png', 'tab-business@2x', );
$tab-cooking-lg-on-2x-name: 'tab-cooking-lg-on@2x';
$tab-cooking-lg-on-2x-x: 482px;
$tab-cooking-lg-on-2x-y: 170px;
$tab-cooking-lg-on-2x-offset-x: -482px;
$tab-cooking-lg-on-2x-offset-y: -170px;
$tab-cooking-lg-on-2x-width: 148px;
$tab-cooking-lg-on-2x-height: 132px;
$tab-cooking-lg-on-2x-total-width: 942px;
$tab-cooking-lg-on-2x-total-height: 812px;
$tab-cooking-lg-on-2x-image: '../img/sprite@2x.png';
$tab-cooking-lg-on-2x: (482px, 170px, -482px, -170px, 148px, 132px, 942px, 812px, '../img/sprite@2x.png', 'tab-cooking-lg-on@2x', );
$tab-cooling-lg-on-2x-name: 'tab-cooling-lg-on@2x';
$tab-cooling-lg-on-2x-x: 422px;
$tab-cooling-lg-on-2x-y: 318px;
$tab-cooling-lg-on-2x-offset-x: -422px;
$tab-cooling-lg-on-2x-offset-y: -318px;
$tab-cooling-lg-on-2x-width: 150px;
$tab-cooling-lg-on-2x-height: 104px;
$tab-cooling-lg-on-2x-total-width: 942px;
$tab-cooling-lg-on-2x-total-height: 812px;
$tab-cooling-lg-on-2x-image: '../img/sprite@2x.png';
$tab-cooling-lg-on-2x: (422px, 318px, -422px, -318px, 150px, 104px, 942px, 812px, '../img/sprite@2x.png', 'tab-cooling-lg-on@2x', );
$tab-heating-lg-on-2x-name: 'tab-heating-lg-on@2x';
$tab-heating-lg-on-2x-x: 482px;
$tab-heating-lg-on-2x-y: 0px;
$tab-heating-lg-on-2x-offset-x: -482px;
$tab-heating-lg-on-2x-offset-y: 0px;
$tab-heating-lg-on-2x-width: 148px;
$tab-heating-lg-on-2x-height: 166px;
$tab-heating-lg-on-2x-total-width: 942px;
$tab-heating-lg-on-2x-total-height: 812px;
$tab-heating-lg-on-2x-image: '../img/sprite@2x.png';
$tab-heating-lg-on-2x: (482px, 0px, -482px, 0px, 148px, 166px, 942px, 812px, '../img/sprite@2x.png', 'tab-heating-lg-on@2x', );
$tab-home-large-on-2x-name: 'tab-home-large-on@2x';
$tab-home-large-on-2x-x: 634px;
$tab-home-large-on-2x-y: 336px;
$tab-home-large-on-2x-offset-x: -634px;
$tab-home-large-on-2x-offset-y: -336px;
$tab-home-large-on-2x-width: 116px;
$tab-home-large-on-2x-height: 122px;
$tab-home-large-on-2x-total-width: 942px;
$tab-home-large-on-2x-total-height: 812px;
$tab-home-large-on-2x-image: '../img/sprite@2x.png';
$tab-home-large-on-2x: (634px, 336px, -634px, -336px, 116px, 122px, 942px, 812px, '../img/sprite@2x.png', 'tab-home-large-on@2x', );
$tab-home-lg-2x-name: 'tab-home-lg@2x';
$tab-home-lg-2x-x: 142px;
$tab-home-lg-2x-y: 318px;
$tab-home-lg-2x-offset-x: -142px;
$tab-home-lg-2x-offset-y: -318px;
$tab-home-lg-2x-width: 140px;
$tab-home-lg-2x-height: 148px;
$tab-home-lg-2x-total-width: 942px;
$tab-home-lg-2x-total-height: 812px;
$tab-home-lg-2x-image: '../img/sprite@2x.png';
$tab-home-lg-2x: (142px, 318px, -142px, -318px, 140px, 148px, 942px, 812px, '../img/sprite@2x.png', 'tab-home-lg@2x', );
$tab-home-on-2x-name: 'tab-home-on@2x';
$tab-home-on-2x-x: 760px;
$tab-home-on-2x-y: 412px;
$tab-home-on-2x-offset-x: -760px;
$tab-home-on-2x-offset-y: -412px;
$tab-home-on-2x-width: 48px;
$tab-home-on-2x-height: 48px;
$tab-home-on-2x-total-width: 942px;
$tab-home-on-2x-total-height: 812px;
$tab-home-on-2x-image: '../img/sprite@2x.png';
$tab-home-on-2x: (760px, 412px, -760px, -412px, 48px, 48px, 942px, 812px, '../img/sprite@2x.png', 'tab-home-on@2x', );
$tab-home-2x-name: 'tab-home@2x';
$tab-home-2x-x: 408px;
$tab-home-2x-y: 124px;
$tab-home-2x-offset-x: -408px;
$tab-home-2x-offset-y: -124px;
$tab-home-2x-width: 44px;
$tab-home-2x-height: 46px;
$tab-home-2x-total-width: 942px;
$tab-home-2x-total-height: 812px;
$tab-home-2x-image: '../img/sprite@2x.png';
$tab-home-2x: (408px, 124px, -408px, -124px, 44px, 46px, 942px, 812px, '../img/sprite@2x.png', 'tab-home@2x', );
$tab-laundry-lg-on-2x-name: 'tab-laundry-lg-on@2x';
$tab-laundry-lg-on-2x-x: 286px;
$tab-laundry-lg-on-2x-y: 318px;
$tab-laundry-lg-on-2x-offset-x: -286px;
$tab-laundry-lg-on-2x-offset-y: -318px;
$tab-laundry-lg-on-2x-width: 132px;
$tab-laundry-lg-on-2x-height: 132px;
$tab-laundry-lg-on-2x-total-width: 942px;
$tab-laundry-lg-on-2x-total-height: 812px;
$tab-laundry-lg-on-2x-image: '../img/sprite@2x.png';
$tab-laundry-lg-on-2x: (286px, 318px, -286px, -318px, 132px, 132px, 942px, 812px, '../img/sprite@2x.png', 'tab-laundry-lg-on@2x', );
$tab-outdoors-lg-on-2x-name: 'tab-outdoors-lg-on@2x';
$tab-outdoors-lg-on-2x-x: 634px;
$tab-outdoors-lg-on-2x-y: 0px;
$tab-outdoors-lg-on-2x-offset-x: -634px;
$tab-outdoors-lg-on-2x-offset-y: 0px;
$tab-outdoors-lg-on-2x-width: 122px;
$tab-outdoors-lg-on-2x-height: 180px;
$tab-outdoors-lg-on-2x-total-width: 942px;
$tab-outdoors-lg-on-2x-total-height: 812px;
$tab-outdoors-lg-on-2x-image: '../img/sprite@2x.png';
$tab-outdoors-lg-on-2x: (634px, 0px, -634px, 0px, 122px, 180px, 942px, 812px, '../img/sprite@2x.png', 'tab-outdoors-lg-on@2x', );
$tab-report-on-2x-name: 'tab-report-on@2x';
$tab-report-on-2x-x: 760px;
$tab-report-on-2x-y: 464px;
$tab-report-on-2x-offset-x: -760px;
$tab-report-on-2x-offset-y: -464px;
$tab-report-on-2x-width: 48px;
$tab-report-on-2x-height: 48px;
$tab-report-on-2x-total-width: 942px;
$tab-report-on-2x-total-height: 812px;
$tab-report-on-2x-image: '../img/sprite@2x.png';
$tab-report-on-2x: (760px, 464px, -760px, -464px, 48px, 48px, 942px, 812px, '../img/sprite@2x.png', 'tab-report-on@2x', );
$tab-report-2x-name: 'tab-report@2x';
$tab-report-2x-x: 806px;
$tab-report-2x-y: 300px;
$tab-report-2x-offset-x: -806px;
$tab-report-2x-offset-y: -300px;
$tab-report-2x-width: 40px;
$tab-report-2x-height: 46px;
$tab-report-2x-total-width: 942px;
$tab-report-2x-total-height: 812px;
$tab-report-2x-image: '../img/sprite@2x.png';
$tab-report-2x: (806px, 300px, -806px, -300px, 40px, 46px, 942px, 812px, '../img/sprite@2x.png', 'tab-report@2x', );
$tab-shower-lg-on-2x-name: 'tab-shower-lg-on@2x';
$tab-shower-lg-on-2x-x: 0px;
$tab-shower-lg-on-2x-y: 318px;
$tab-shower-lg-on-2x-offset-x: 0px;
$tab-shower-lg-on-2x-offset-y: -318px;
$tab-shower-lg-on-2x-width: 138px;
$tab-shower-lg-on-2x-height: 166px;
$tab-shower-lg-on-2x-total-width: 942px;
$tab-shower-lg-on-2x-total-height: 812px;
$tab-shower-lg-on-2x-image: '../img/sprite@2x.png';
$tab-shower-lg-on-2x: (0px, 318px, 0px, -318px, 138px, 166px, 942px, 812px, '../img/sprite@2x.png', 'tab-shower-lg-on@2x', );
$tab-wrench-on-2x-name: 'tab-wrench-on@2x';
$tab-wrench-on-2x-x: 760px;
$tab-wrench-on-2x-y: 516px;
$tab-wrench-on-2x-offset-x: -760px;
$tab-wrench-on-2x-offset-y: -516px;
$tab-wrench-on-2x-width: 48px;
$tab-wrench-on-2x-height: 48px;
$tab-wrench-on-2x-total-width: 942px;
$tab-wrench-on-2x-total-height: 812px;
$tab-wrench-on-2x-image: '../img/sprite@2x.png';
$tab-wrench-on-2x: (760px, 516px, -760px, -516px, 48px, 48px, 942px, 812px, '../img/sprite@2x.png', 'tab-wrench-on@2x', );
$tab-wrench-2x-name: 'tab-wrench@2x';
$tab-wrench-2x-x: 760px;
$tab-wrench-2x-y: 568px;
$tab-wrench-2x-offset-x: -760px;
$tab-wrench-2x-offset-y: -568px;
$tab-wrench-2x-width: 48px;
$tab-wrench-2x-height: 48px;
$tab-wrench-2x-total-width: 942px;
$tab-wrench-2x-total-height: 812px;
$tab-wrench-2x-image: '../img/sprite@2x.png';
$tab-wrench-2x: (760px, 568px, -760px, -568px, 48px, 48px, 942px, 812px, '../img/sprite@2x.png', 'tab-wrench@2x', );
$tick-blue-2x-name: 'tick-blue@2x';
$tick-blue-2x-x: 760px;
$tick-blue-2x-y: 670px;
$tick-blue-2x-offset-x: -760px;
$tick-blue-2x-offset-y: -670px;
$tick-blue-2x-width: 48px;
$tick-blue-2x-height: 36px;
$tick-blue-2x-total-width: 942px;
$tick-blue-2x-total-height: 812px;
$tick-blue-2x-image: '../img/sprite@2x.png';
$tick-blue-2x: (760px, 670px, -760px, -670px, 48px, 36px, 942px, 812px, '../img/sprite@2x.png', 'tick-blue@2x', );
$tick-white-2x-name: 'tick-white@2x';
$tick-white-2x-x: 812px;
$tick-white-2x-y: 240px;
$tick-white-2x-offset-x: -812px;
$tick-white-2x-offset-y: -240px;
$tick-white-2x-width: 34px;
$tick-white-2x-height: 26px;
$tick-white-2x-total-width: 942px;
$tick-white-2x-total-height: 812px;
$tick-white-2x-image: '../img/sprite@2x.png';
$tick-white-2x: (812px, 240px, -812px, -240px, 34px, 26px, 942px, 812px, '../img/sprite@2x.png', 'tick-white@2x', );
$spritesheet-width: 471px;
$spritesheet-height: 406px;
$spritesheet-image: '../img/sprite.png';
$spritesheet-sprites: ($advice-tab, $alert, $arrow-down-blue, $arrow-down-white-sm, $arrow-down-white, $arrow-down, $arrow-left, $arrow-right-white, $arrow-right, $arrow-up-blue, $arrow-up-white-sm, $arrow-up-white, $arrow-up, $australia-icon, $back-to-top, $check-mark, $close-large, $close, $desktop-logo, $dot-cursor-lg, $dot-email-xl, $dot-email, $dot-home, $dot-login, $dot-mail-lg, $dot-mail-xl, $dot-mail, $dot-phone-alt, $dot-phone-large, $dot-phone-lg, $dot-phone-xl, $dot-phone, $dot-question-large, $dot-success-large, $environment, $fb, $footer-logo, $gas-tab, $hamburger, $header-location, $heading-fridge, $heading-heater, $heading-home, $heading-light, $heading-magnifier, $heading-report, $heading-shower, $heading-washer, $heading-wrench, $mobile-logo, $news-tab, $phone-btn, $question-collapse, $question-expand, $rebates, $savings, $search-btn-white, $search-btn, $tab-appliance-on, $tab-appliance, $tab-business-large-on, $tab-business-lg, $tab-business, $tab-cooking-lg-on, $tab-cooling-lg-on, $tab-heating-lg-on, $tab-home-large-on, $tab-home-lg, $tab-home-on, $tab-home, $tab-laundry-lg-on, $tab-outdoors-lg-on, $tab-report-on, $tab-report, $tab-shower-lg-on, $tab-wrench-on, $tab-wrench, $tick-blue, $tick-white, );
$spritesheet: (471px, 406px, '../img/sprite.png', $spritesheet-sprites, );
$retina-spritesheet-width: 942px;
$retina-spritesheet-height: 812px;
$retina-spritesheet-image: '../img/sprite@2x.png';
$retina-spritesheet-sprites: ($advice-tab-2x, $alert-2x, $arrow-down-blue-2x, $arrow-down-white-sm-2x, $arrow-down-white-2x, $arrow-down-2x, $arrow-left-2x, $arrow-right-white-2x, $arrow-right-2x, $arrow-up-blue-2x, $arrow-up-white-sm-2x, $arrow-up-white-2x, $arrow-up-2x, $australia-icon-2x, $back-to-top-2x, $check-mark-2x, $close-large-2x, $close-2x, $desktop-logo-2x, $dot-cursor-lg-2x, $dot-email-xl-2x, $dot-email-2x, $dot-home-2x, $dot-login-2x, $dot-mail-lg-2x, $dot-mail-xl-2x, $dot-mail-2x, $dot-phone-alt-2x, $dot-phone-large-2x, $dot-phone-lg-2x, $dot-phone-xl-2x, $dot-phone-2x, $dot-question-large-2x, $dot-success-large-2x, $environment-2x, $fb-2x, $footer-logo-2x, $gas-tab-2x, $hamburger-2x, $header-location-2x, $heading-fridge-2x, $heading-heater-2x, $heading-home-2x, $heading-light-2x, $heading-magnifier-2x, $heading-report-2x, $heading-shower-2x, $heading-washer-2x, $heading-wrench-2x, $mobile-logo-2x, $news-tab-2x, $phone-btn-2x, $question-collapse-2x, $question-expand-2x, $rebates-2x, $savings-2x, $search-btn-white-2x, $search-btn-2x, $tab-appliance-on-2x, $tab-appliance-2x, $tab-business-large-on-2x, $tab-business-lg-2x, $tab-business-2x, $tab-cooking-lg-on-2x, $tab-cooling-lg-on-2x, $tab-heating-lg-on-2x, $tab-home-large-on-2x, $tab-home-lg-2x, $tab-home-on-2x, $tab-home-2x, $tab-laundry-lg-on-2x, $tab-outdoors-lg-on-2x, $tab-report-on-2x, $tab-report-2x, $tab-shower-lg-on-2x, $tab-wrench-on-2x, $tab-wrench-2x, $tick-blue-2x, $tick-white-2x, );
$retina-spritesheet: (942px, 812px, '../img/sprite@2x.png', $retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$advice-tab-group-name: 'advice-tab';
$advice-tab-group: ('advice-tab', $advice-tab, $advice-tab-2x, );
$alert-group-name: 'alert';
$alert-group: ('alert', $alert, $alert-2x, );
$arrow-down-blue-group-name: 'arrow-down-blue';
$arrow-down-blue-group: ('arrow-down-blue', $arrow-down-blue, $arrow-down-blue-2x, );
$arrow-down-white-sm-group-name: 'arrow-down-white-sm';
$arrow-down-white-sm-group: ('arrow-down-white-sm', $arrow-down-white-sm, $arrow-down-white-sm-2x, );
$arrow-down-white-group-name: 'arrow-down-white';
$arrow-down-white-group: ('arrow-down-white', $arrow-down-white, $arrow-down-white-2x, );
$arrow-down-group-name: 'arrow-down';
$arrow-down-group: ('arrow-down', $arrow-down, $arrow-down-2x, );
$arrow-left-group-name: 'arrow-left';
$arrow-left-group: ('arrow-left', $arrow-left, $arrow-left-2x, );
$arrow-right-white-group-name: 'arrow-right-white';
$arrow-right-white-group: ('arrow-right-white', $arrow-right-white, $arrow-right-white-2x, );
$arrow-right-group-name: 'arrow-right';
$arrow-right-group: ('arrow-right', $arrow-right, $arrow-right-2x, );
$arrow-up-blue-group-name: 'arrow-up-blue';
$arrow-up-blue-group: ('arrow-up-blue', $arrow-up-blue, $arrow-up-blue-2x, );
$arrow-up-white-sm-group-name: 'arrow-up-white-sm';
$arrow-up-white-sm-group: ('arrow-up-white-sm', $arrow-up-white-sm, $arrow-up-white-sm-2x, );
$arrow-up-white-group-name: 'arrow-up-white';
$arrow-up-white-group: ('arrow-up-white', $arrow-up-white, $arrow-up-white-2x, );
$arrow-up-group-name: 'arrow-up';
$arrow-up-group: ('arrow-up', $arrow-up, $arrow-up-2x, );
$australia-icon-group-name: 'australia-icon';
$australia-icon-group: ('australia-icon', $australia-icon, $australia-icon-2x, );
$back-to-top-group-name: 'back-to-top';
$back-to-top-group: ('back-to-top', $back-to-top, $back-to-top-2x, );
$check-mark-group-name: 'check-mark';
$check-mark-group: ('check-mark', $check-mark, $check-mark-2x, );
$close-large-group-name: 'close-large';
$close-large-group: ('close-large', $close-large, $close-large-2x, );
$close-group-name: 'close';
$close-group: ('close', $close, $close-2x, );
$desktop-logo-group-name: 'desktop-logo';
$desktop-logo-group: ('desktop-logo', $desktop-logo, $desktop-logo-2x, );
$dot-cursor-lg-group-name: 'dot-cursor-lg';
$dot-cursor-lg-group: ('dot-cursor-lg', $dot-cursor-lg, $dot-cursor-lg-2x, );
$dot-email-xl-group-name: 'dot-email-xl';
$dot-email-xl-group: ('dot-email-xl', $dot-email-xl, $dot-email-xl-2x, );
$dot-email-group-name: 'dot-email';
$dot-email-group: ('dot-email', $dot-email, $dot-email-2x, );
$dot-home-group-name: 'dot-home';
$dot-home-group: ('dot-home', $dot-home, $dot-home-2x, );
$dot-login-group-name: 'dot-login';
$dot-login-group: ('dot-login', $dot-login, $dot-login-2x, );
$dot-mail-lg-group-name: 'dot-mail-lg';
$dot-mail-lg-group: ('dot-mail-lg', $dot-mail-lg, $dot-mail-lg-2x, );
$dot-mail-xl-group-name: 'dot-mail-xl';
$dot-mail-xl-group: ('dot-mail-xl', $dot-mail-xl, $dot-mail-xl-2x, );
$dot-mail-group-name: 'dot-mail';
$dot-mail-group: ('dot-mail', $dot-mail, $dot-mail-2x, );
$dot-phone-alt-group-name: 'dot-phone-alt';
$dot-phone-alt-group: ('dot-phone-alt', $dot-phone-alt, $dot-phone-alt-2x, );
$dot-phone-large-group-name: 'dot-phone-large';
$dot-phone-large-group: ('dot-phone-large', $dot-phone-large, $dot-phone-large-2x, );
$dot-phone-lg-group-name: 'dot-phone-lg';
$dot-phone-lg-group: ('dot-phone-lg', $dot-phone-lg, $dot-phone-lg-2x, );
$dot-phone-xl-group-name: 'dot-phone-xl';
$dot-phone-xl-group: ('dot-phone-xl', $dot-phone-xl, $dot-phone-xl-2x, );
$dot-phone-group-name: 'dot-phone';
$dot-phone-group: ('dot-phone', $dot-phone, $dot-phone-2x, );
$dot-question-large-group-name: 'dot-question-large';
$dot-question-large-group: ('dot-question-large', $dot-question-large, $dot-question-large-2x, );
$dot-success-large-group-name: 'dot-success-large';
$dot-success-large-group: ('dot-success-large', $dot-success-large, $dot-success-large-2x, );
$environment-group-name: 'environment';
$environment-group: ('environment', $environment, $environment-2x, );
$fb-group-name: 'fb';
$fb-group: ('fb', $fb, $fb-2x, );
$footer-logo-group-name: 'footer-logo';
$footer-logo-group: ('footer-logo', $footer-logo, $footer-logo-2x, );
$gas-tab-group-name: 'gas-tab';
$gas-tab-group: ('gas-tab', $gas-tab, $gas-tab-2x, );
$hamburger-group-name: 'hamburger';
$hamburger-group: ('hamburger', $hamburger, $hamburger-2x, );
$header-location-group-name: 'header-location';
$header-location-group: ('header-location', $header-location, $header-location-2x, );
$heading-fridge-group-name: 'heading-fridge';
$heading-fridge-group: ('heading-fridge', $heading-fridge, $heading-fridge-2x, );
$heading-heater-group-name: 'heading-heater';
$heading-heater-group: ('heading-heater', $heading-heater, $heading-heater-2x, );
$heading-home-group-name: 'heading-home';
$heading-home-group: ('heading-home', $heading-home, $heading-home-2x, );
$heading-light-group-name: 'heading-light';
$heading-light-group: ('heading-light', $heading-light, $heading-light-2x, );
$heading-magnifier-group-name: 'heading-magnifier';
$heading-magnifier-group: ('heading-magnifier', $heading-magnifier, $heading-magnifier-2x, );
$heading-report-group-name: 'heading-report';
$heading-report-group: ('heading-report', $heading-report, $heading-report-2x, );
$heading-shower-group-name: 'heading-shower';
$heading-shower-group: ('heading-shower', $heading-shower, $heading-shower-2x, );
$heading-washer-group-name: 'heading-washer';
$heading-washer-group: ('heading-washer', $heading-washer, $heading-washer-2x, );
$heading-wrench-group-name: 'heading-wrench';
$heading-wrench-group: ('heading-wrench', $heading-wrench, $heading-wrench-2x, );
$mobile-logo-group-name: 'mobile-logo';
$mobile-logo-group: ('mobile-logo', $mobile-logo, $mobile-logo-2x, );
$news-tab-group-name: 'news-tab';
$news-tab-group: ('news-tab', $news-tab, $news-tab-2x, );
$phone-btn-group-name: 'phone-btn';
$phone-btn-group: ('phone-btn', $phone-btn, $phone-btn-2x, );
$question-collapse-group-name: 'question-collapse';
$question-collapse-group: ('question-collapse', $question-collapse, $question-collapse-2x, );
$question-expand-group-name: 'question-expand';
$question-expand-group: ('question-expand', $question-expand, $question-expand-2x, );
$rebates-group-name: 'rebates';
$rebates-group: ('rebates', $rebates, $rebates-2x, );
$savings-group-name: 'savings';
$savings-group: ('savings', $savings, $savings-2x, );
$search-btn-white-group-name: 'search-btn-white';
$search-btn-white-group: ('search-btn-white', $search-btn-white, $search-btn-white-2x, );
$search-btn-group-name: 'search-btn';
$search-btn-group: ('search-btn', $search-btn, $search-btn-2x, );
$tab-appliance-on-group-name: 'tab-appliance-on';
$tab-appliance-on-group: ('tab-appliance-on', $tab-appliance-on, $tab-appliance-on-2x, );
$tab-appliance-group-name: 'tab-appliance';
$tab-appliance-group: ('tab-appliance', $tab-appliance, $tab-appliance-2x, );
$tab-business-large-on-group-name: 'tab-business-large-on';
$tab-business-large-on-group: ('tab-business-large-on', $tab-business-large-on, $tab-business-large-on-2x, );
$tab-business-lg-group-name: 'tab-business-lg';
$tab-business-lg-group: ('tab-business-lg', $tab-business-lg, $tab-business-lg-2x, );
$tab-business-group-name: 'tab-business';
$tab-business-group: ('tab-business', $tab-business, $tab-business-2x, );
$tab-cooking-lg-on-group-name: 'tab-cooking-lg-on';
$tab-cooking-lg-on-group: ('tab-cooking-lg-on', $tab-cooking-lg-on, $tab-cooking-lg-on-2x, );
$tab-cooling-lg-on-group-name: 'tab-cooling-lg-on';
$tab-cooling-lg-on-group: ('tab-cooling-lg-on', $tab-cooling-lg-on, $tab-cooling-lg-on-2x, );
$tab-heating-lg-on-group-name: 'tab-heating-lg-on';
$tab-heating-lg-on-group: ('tab-heating-lg-on', $tab-heating-lg-on, $tab-heating-lg-on-2x, );
$tab-home-large-on-group-name: 'tab-home-large-on';
$tab-home-large-on-group: ('tab-home-large-on', $tab-home-large-on, $tab-home-large-on-2x, );
$tab-home-lg-group-name: 'tab-home-lg';
$tab-home-lg-group: ('tab-home-lg', $tab-home-lg, $tab-home-lg-2x, );
$tab-home-on-group-name: 'tab-home-on';
$tab-home-on-group: ('tab-home-on', $tab-home-on, $tab-home-on-2x, );
$tab-home-group-name: 'tab-home';
$tab-home-group: ('tab-home', $tab-home, $tab-home-2x, );
$tab-laundry-lg-on-group-name: 'tab-laundry-lg-on';
$tab-laundry-lg-on-group: ('tab-laundry-lg-on', $tab-laundry-lg-on, $tab-laundry-lg-on-2x, );
$tab-outdoors-lg-on-group-name: 'tab-outdoors-lg-on';
$tab-outdoors-lg-on-group: ('tab-outdoors-lg-on', $tab-outdoors-lg-on, $tab-outdoors-lg-on-2x, );
$tab-report-on-group-name: 'tab-report-on';
$tab-report-on-group: ('tab-report-on', $tab-report-on, $tab-report-on-2x, );
$tab-report-group-name: 'tab-report';
$tab-report-group: ('tab-report', $tab-report, $tab-report-2x, );
$tab-shower-lg-on-group-name: 'tab-shower-lg-on';
$tab-shower-lg-on-group: ('tab-shower-lg-on', $tab-shower-lg-on, $tab-shower-lg-on-2x, );
$tab-wrench-on-group-name: 'tab-wrench-on';
$tab-wrench-on-group: ('tab-wrench-on', $tab-wrench-on, $tab-wrench-on-2x, );
$tab-wrench-group-name: 'tab-wrench';
$tab-wrench-group: ('tab-wrench', $tab-wrench, $tab-wrench-2x, );
$tick-blue-group-name: 'tick-blue';
$tick-blue-group: ('tick-blue', $tick-blue, $tick-blue-2x, );
$tick-white-group-name: 'tick-white';
$tick-white-group: ('tick-white', $tick-white, $tick-white-2x, );
$retina-groups: ($advice-tab-group, $alert-group, $arrow-down-blue-group, $arrow-down-white-sm-group, $arrow-down-white-group, $arrow-down-group, $arrow-left-group, $arrow-right-white-group, $arrow-right-group, $arrow-up-blue-group, $arrow-up-white-sm-group, $arrow-up-white-group, $arrow-up-group, $australia-icon-group, $back-to-top-group, $check-mark-group, $close-large-group, $close-group, $desktop-logo-group, $dot-cursor-lg-group, $dot-email-xl-group, $dot-email-group, $dot-home-group, $dot-login-group, $dot-mail-lg-group, $dot-mail-xl-group, $dot-mail-group, $dot-phone-alt-group, $dot-phone-large-group, $dot-phone-lg-group, $dot-phone-xl-group, $dot-phone-group, $dot-question-large-group, $dot-success-large-group, $environment-group, $fb-group, $footer-logo-group, $gas-tab-group, $hamburger-group, $header-location-group, $heading-fridge-group, $heading-heater-group, $heading-home-group, $heading-light-group, $heading-magnifier-group, $heading-report-group, $heading-shower-group, $heading-washer-group, $heading-wrench-group, $mobile-logo-group, $news-tab-group, $phone-btn-group, $question-collapse-group, $question-expand-group, $rebates-group, $savings-group, $search-btn-white-group, $search-btn-group, $tab-appliance-on-group, $tab-appliance-group, $tab-business-large-on-group, $tab-business-lg-group, $tab-business-group, $tab-cooking-lg-on-group, $tab-cooling-lg-on-group, $tab-heating-lg-on-group, $tab-home-large-on-group, $tab-home-lg-group, $tab-home-on-group, $tab-home-group, $tab-laundry-lg-on-group, $tab-outdoors-lg-on-group, $tab-report-on-group, $tab-report-group, $tab-shower-lg-on-group, $tab-wrench-on-group, $tab-wrench-group, $tick-blue-group, $tick-white-group, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );
//
// .icon-home {
//   @include retina-sprite($icon-home-group);
// }
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS
//
// @include retina-sprites($retina-groups);
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}

.state-dropdown {
  @include outer-wrap;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 24px 0 0;

  @include media($tablet) {
    max-height: 60px;
    margin: 32px 0 0;
    align-items: center;
    flex-direction: row;
  }

  @include media($desktop-lg) {
    margin: -28px -60px 0 0;
  }

  label {
    margin: 0;

    @include media($tablet) {
      margin: auto 15px;
    }
  }

  .select-wrapper {
    display: inline-flex;
    margin-right: -24px;
    height: 60px;

    &::after {
      content: '';
      background: url('../../../assets/icons/chevron-down.svg');
      width: 24px;
      height: 24px;
      display: flex;
      position: relative;
      right: 36px;
      margin: auto;
      background-repeat: no-repeat;
      z-index: 9999;
      pointer-events: none;
    }
  }

  .select-state {
    position: relative;
    padding: 15px 12px;
    margin-bottom: 0;
    background: $white;
    border: 1px solid #e7e7e7;
    border-radius: 4px;
    outline: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;

    @include media($tablet) {
      width: 300px;
      padding: 15px 24px;
    }

    &::-ms-expand {
      display: none;
    }
  }
}

// Code specifically targetting the sticky header
// The .sticky class will be added when the javascript code determines the correct time to display it to the user

@include media($tablet) {
  header.sticky {

    position: fixed;
    top: 0;

    .contact-line {
      display: none; }

    .container {
      margin: 0;
      width: 100%;
      max-width: none;
      background: white;
      box-shadow: rgba(0,0,0,0.15) 0px 15px 45px; }

    .main {
      height: 100px; }

    li.show-nav > div {
      margin-left: 0;
      box-sizing: border-box; }

    @include media($desktop-lg) {
      .logo {
        @include retina-sprite($mobile-logo-group); }
      nav > ul {
        @include padding(40px 360px null 240px); }
      .search {
        margin-top: 33px; } } } }

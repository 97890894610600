// have your say CTA box
.have-your-say {
  background: red;
  background: url(../img/have-your-say-bg.png) no-repeat 0 100% $dark-blue;
  color: #fff;
  padding: $base-spacing;
  position: relative;
  .btn {
    width: 100%;
    @include padding(null 5px);
    position: relative;
    margin: 0 auto;
  }
  @include media($mobile) {
    background-size: 20em;
  }
  @include media($tablet) {
    @include span-columns(4 of 12);
    background-size: 150%;
    .btn {
      width: 80%;
      margin: 0;
      @include padding(null 5px);
      position: absolute;
      bottom: 20px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
  @include media($desktop) {
    padding: $base-spacing * 1.5;
  }
  h3 {
    color: #fff;
  }
}

.have-your-say-wrap {
  @include outer-wrap;
  .have-your-say {
    background: url(../img/have-your-say-bg.png) no-repeat 0 100% $dark-blue;
    background-size: 20em;
    margin-bottom: $module-spacing;
    .btn {
      float: none;
    }
    @include media($tablet) {
      width: 100%;
      margin-right: 0;
      background: url(../img/have-your-say-bg-narrow.png) no-repeat center right #003c71;
      p,
      h3 {
        width: 72%;
        float: left;
      }
      .btn {
        float: right;
        width: 25%;
        margin-right: 1%;
        // @include padding(null 25px);
        // padding: 23px 22px;
        position: relative;
        left: auto;
        transform: none;
      }
    }
    @include media($desktop) {
      .btn {
        padding: 19px 30px;
        width: 28%;
      }
      p,
      h3 {
        width: 67%;
        float: left;
      }
    }
    @include media($desktop-lg) {
      p,
      h3 {
        width: 60%;
        float: left;
      }
    }
    // @include media($tablet) {
    //   .btn {
    //     float: right;
    //     width: 35%;
    //     margin-right: 1%;
    //     @include padding(null 25px);
    //     position: relative;
    //     bottom: 0px;
    //     left: auto;
    //     transform: none;
    //   }
    // }

    // @include media($desktop) {
    //   // .btn {
    //   //   margin-right: 4%;
    //   //   max-width: 280px;
    //   //   padding-top: 18px;
    //   //   padding-bottom: 18px;
    //   // }
    //   .btn {
    //     float: right;
    //     width: 35%;
    //     margin-right: 1%;
    //     @include padding(null 25px);
    //     position: relative;
    //     bottom: 0px;
    //     left: auto;
    //     transform: none;
    //   }
    // }
  }
}

.is-gas-available {
  $body-gutter: flex-gutter(container-span(2 of 4)) / 2;

  @include outer-wrap;
  margin-bottom: $module-spacing;
  @include media($mobile) {
    @include margin(null 10px); }

  .body-wrap {
    display: table;
    width: 100%; }

  .body-left {
    padding-right: $body-gutter;
    &.vcenter {
      vertical-align: middle; } }

  .body-right {
    padding-left: $body-gutter;
    vertical-align: middle; }

  .body-left,
  .body-right {
    @include span-columns(2 of 4, table);
    position: relative;
    @include media($mobile) {
      display: block;
      padding: 0;
      width: 100%; } }

  .not-available-title {
    font-size: 1.5em;
    margin-bottom: 0;
    text-transform: none;
    @include media($mobile) {
      font-size: 1.4em;
      line-height: 1.2em; } }

  .gas-available-title {
    font-size: 2.2em; }

  .phone-heading {
    margin-top: 30px; }

  .gas-heading {
    background: $teal;
    color: $white;
    @include inner-wrap;
    @include padding(20px null);
    span {
      line-height: 42px; }
    .btn {
      background-color: transparent;
      color: $white;
      float: right;
      line-height: 42px;
      margin: 0;
      padding: 0;
      text-decoration: underline;
      text-transform: none; }
    @include media($mobile) {
      display: flex;
      display: -webkit-flex;
      flex-direction: column-reverse;
      -webkit-flex-direction: column-reverse;
      span {
        line-height: 22px;
        margin-bottom: 10px; } } }

  .gas-status {
    @include inner-wrap;
    @include padding(40px null);
    border-left: 2px solid $light-grey;
    border-right: 2px solid $light-grey;
    .phone-heading {
      margin-top: 20px; } }

  .gas-body {
    @include inner-wrap;
    @include padding(40px null);
    border: 2px solid $light-grey;
    display: table;
    table-layout: fixed;
    .btn {
      @include media($tablet) {
        margin: 0;
        padding: 22px 0 21px;
        width: 100%; }
      &.phone {
        font-size: 1em;
        padding: 14px;
        @include media($tablet) {
          float: right;
          width: auto; } } }
    &.loading {
      opacity: 0.1; } }

  .gas-available-form {
    display: table;
    table-layout: fixed;
    width: 100%;
    @include media($tablet) {
      margin-top: 1em; } }

  .gas-available-postcode,
  .gas-available-submit {
    @include media($tablet) {
      display: table-cell;
      vertical-align: top; } }

  .gas-available-postcode {
    @include media($tablet) {
      padding-right: 4%;
      width: 76%; } }

  .gas-available-submit {
    @include media($tablet) {
      width: 20%; } }

  [type="text"] {
    width: 100%; }

  .twitter-typeahead, .tt-hint, .tt-input, .tt-menu {
    width: 100%; }

  .tt-menu {
    max-height: 150px;
    overflow-y: auto; }

  .btn {
    @include media($mobile) {
      float: none !important;
      width: 100%;
      margin: 0; } }

  .gas-cta {
    float: right;
    margin: 0;
    @include media($mobile) {
      float: none;
      margin-top: 20px;
      width: 100%; } }

  .gas-distributor {
    @include inner-wrap;
    @include padding(40px null);
    border-left: 2px solid $light-grey;
    border-right: 2px solid $light-grey;
    display: flex;
    flex-wrap: wrap;
    @include media($desktop) {
      flex-wrap: nowrap;
      justify-content: start;
      align-items: center; }

    img {
      display: block;
      margin: 0 auto 20px;
      max-height: 80px;
      @include media($desktop) {
        margin: 0 auto; } }

    .gas-distributor__copy {
      @include media($desktop) {
        flex: auto;
        padding-left: 30px; }
      @include media($desktop-lg) {
        padding-left: 50px; }

      p {
        text-align: center;
        font-family: $albert;
        font-size: 16px;
        line-height: 24px;
        color: $dark-blue;
        margin: 0 32px;
        @include media($desktop) {
          text-align: left;
          font-size: 18px;
          line-height: 32px;
          margin: 0; } } } } }

.section-intro_container {
  padding-top: 120px;
  @include span-columns(12 of 12);
  float: none;

  @include media($mobile) {
    padding-top: 64px;
  }

  @include media($tablet-lg) {
    padding-top: 100px;
  }

  .section-intro {
    @include outer-wrap;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 60px;

    @include media($mobile) {
      margin-top: 30px;
    }

    .col-8 {
      @include span-columns(8 of 12);
      float: none;
      display: flex;
      flex-direction: column;

      @include media($tablet-lg) {
        width: 80%;
      }

      @include media($mobile) {
        width: 90%;
        margin: 0 24px;

        &:last-child {
          margin-right: 24px;
        }
      }
    }

    &_title {
      color: $dark-blue;

      @include media($tablet) {
        margin-bottom: 0.2em;
      }
    }

    &_name {
      color: $light-blue;
      font-family: $albert-bold;
      text-transform: uppercase;
    }

    &_desc {
      line-height: 1.6;
      margin-bottom: 45px;

      @include media($mobile) {
        margin-bottom: 28px;
      }
    }
  }
}

.connection-process-page {
  .content {
    padding: 0;
    margin: auto;
  }
}

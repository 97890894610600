@charset "UTF-8";

/// Provides an easy way to change the `word-wrap` property.
///
/// @argument {string} $wrap [break-word]
///   Value for the `word-break` property.
///
/// @example scss
///   .wrapper {
///     @include word-wrap(break-word);
///   }
///
/// @example css
///   .wrapper {
///     overflow-wrap: break-word;
///     word-break: break-all;
///     word-wrap: break-word;
///   }

@mixin word-wrap($wrap: break-word) {
  overflow-wrap: $wrap;
  word-wrap: $wrap;

  @if $wrap == break-word {
    word-break: break-all;
  } @else {
    word-break: $wrap;
  }
}

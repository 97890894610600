@charset "UTF-8";

/// Provides a quick method for targeting `border-width` on specific sides of a
/// box. Use a `null` value to “skip” a side.
///
/// @argument {arglist} $values
///   List of border widths, defined as CSS shorthand.
///
/// @example scss
///   .element {
///     @include border-width(1em null 20px);
///   }
///
/// @example css
///   .element {
///     border-bottom-width: 20px;
///     border-top-width: 1em;
///   }
///
/// @require {mixin} _directional-property

@mixin border-width($values...) {
  @include _directional-property(border, width, $values...);
}

.latest-news {
  @include clearfix;
  margin-bottom: $module-spacing;
  @include media($mobile) {
    margin-bottom: 10px;
  }
  > h2 {
    text-align: center;
    margin-bottom: 50px;
  }
  .container {
    @include inner-wrap;
  }
  //.promo-full { margin-bottom: $module-spacing; }
  .item {
    @media screen and (min-width: $tablet-screen) {
      @include span-columns(4);
    }
    img {
      @include block-image;
    }
  }

  @include media($tablet) {
    .inner {
      position: relative;
    }
    .inner:after {
      content: '';
      display: block;
      clear: both;
    }
    .have-your-say {
      position: absolute;
      top: 0;
      right: 0;
      min-height: 100%;
    }
    .have-your-say .btn {
      margin-top: 10%;
    }
    .have-your-say p {
      margin-bottom: 80px;
    }
  }
}

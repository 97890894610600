// promotional banners
// can be themed as
// * cyan (default)
// * navy
// * teal
.promo-banner {
  @include outer-wrap;
  // background: $cyan;
  color: $white;
  margin-bottom: $module-spacing;
  display: flex;
  display: -webkit-flex;
  align-items: content;
  -webkit-align-items: content;
  justify-content: center;
  -webkit-justify-content: center;
  @include media($mobile) {
    @include margin(null 10px);
    .content {
      padding: 1.25em !important;
    }
    .image {
      display: none;
    }

    .promo-banner-locator & {
      .image {
        display: block;
        img {
          object-fit: cover;
        }
      }

      .content {
        margin-bottom: 0;
      }
    }
  }
  .content {
    @include inner-wrap;
    background: url(../img/promo-bg-cyan.png) no-repeat 100% 100% $cyan;
    background-size: 35% 60%;
    @include media($mobile) {
      background-size: 6.5em;
    }
    p:first-child {
      @include h3;
      font-family: $bree;
    }
    .btn {
      background-color: $yellow;
      margin: 0;
    }
  }
  .image {
    //display: none;
  }
  // Alt Design
  &.alt {
    max-width: 840px;
    margin-left: 0;
    margin-right: 0;
    .content {
      background: url(../img/have-your-say-bg.png) bottom left no-repeat $dark-blue;
      width: 100%;
      background-size: 100%;
      @include media ($tablet) {
        width: 57%;
        padding-left: 39px;
        padding-right: 39px;
      }
      @include media ($desktop) {
        padding-left: 39px;
        padding-right: 39px;
      }
      @include media ($desktop-lg) {
        // padding-left: 63px;
        // padding-right: 63px;
        padding-left: 39px;
        padding-right: 39px;
      }
      p:first-child {
        @include h3;
        font-family: $bree;
        @include media($tablet) {
          font-size: 1.25em;
        }
        @include media($desktop) {
          font-size: 1em;
        }
        @include media($desktop-lg) {
          @include h3;
        }
      }
      p {
        @include media($tablet) {
          font-size: .85em;
        }
        @include media($desktop) {
          font-size: .85em;
        }
        @include media($desktop-lg) {
          font-size: 1.125em;
        }
      }
      .btn {
        background: #43b02a;
        padding: 16px 30px;
        font-size: 1em;
        @include media($tablet) {
          font-size: .75em;
        }
      }
    }
    .image {
      display: none;
      img {
        display: none;
      }
      @include media ($tablet) {
        width: 43%;
        display: block;
        img {
          display: block;
        }
      }
    }
  }
  @include media($tablet) {
    .image {
      width: 50%;
      float: left;
      img {
        width: 100%;
        display: block;
        height: 100%;
      }
    }
    .content {
      width: 50%;
      float: left;
      //padding:0;
      margin: 0;
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      -webkit-flex-direction: column;
      justify-content: center;
      -webkit-justify-content: center;
    }
  }
}
// Header
header .promo-banner {
  .image {
    width: 100%;
  }
  .content {
    width: auto;
    float: none;
    padding: 20px;
  }
}

// Side column
.side-col .promo-banner {
  display: block;
  margin: 0;
  &.fixed {
    position: fixed;
    top: 120px;
  }
  &.absolute {
    position: absolute;
    bottom: 0;
  }
  .image {
    width: 100%;
    height: auto !important;
    background: $dark-blue;
    img {
      height: auto;
    }
  }

  &.sticky {
    
    .image {
      background: $light-blue;
    }
    
    .btn {
      white-space: normal;
    }
  }

  .content {
    width: 100%;
    background: $light-blue;
    display: block;
    padding: 1.25em;
    h3 {
      color: white;
    }
    .btn {
      width: 100%;
    }
  }
}

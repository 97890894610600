@charset "UTF-8";

/// Checks if a list does not contains a value.
///
/// @argument {list} $list
///   The list to check against.
///
/// @return {boolean}
///
/// @access private

@function _contains-falsy($list) {
  @each $item in $list {
    @if not $item {
      @return true;
    }
  }

  @return false;
}

@charset "UTF-8";

/// Translates an element horizontally by a number of columns. Positive arguments shift the element to the active layout direction, while negative ones shift it to the opposite direction.
///
/// @param {Number (unitless)} $n-columns [1]
///   Number of columns by which the element shifts.
///
/// @example scss - Usage
///   .element {
///     @include shift(-3);
///   }
///
/// @example css - CSS output
///   .element {
///     margin-left: -25.58941%;
///   }

@mixin shift($n-columns: 1) {
  @include shift-in-context($n-columns);
}

/// Translates an element horizontally by a number of columns, in a specific nesting context.
///
/// @param {List} $shift
///   A list containing the number of columns to shift (`$columns`) and the number of columns of the parent element (`$container-columns`).
///
///   The two values can be separated with any string such as `of`, `/`, etc.
///
/// @example scss - Usage
///   .element {
///     @include shift(-3 of 6);
///   }
///
/// @example css - CSS output
///   .element {
///     margin-left: -52.41458%;
///   }

@mixin shift-in-context($shift: $columns of $container-columns) {
  $n-columns: nth($shift, 1);
  $parent-columns: container-shift($shift) !global;

  $direction: get-direction($layout-direction, $default-layout-direction);
  $opposite-direction: get-opposite-direction($direction);

  margin-#{$opposite-direction}: $n-columns * flex-grid(1, $parent-columns) + $n-columns * flex-gutter($parent-columns);

  // Reset nesting context
  $parent-columns: $grid-columns !global;
}

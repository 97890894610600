.sitemap {
  @include outer-wrap;
  @include inner-wrap;
  margin-bottom: 50px;

  a:hover {
    text-decoration: underline; }

  // First Level
  > ul > li {
    margin-bottom: 50px;
    > a {
      @include h3;
      font-family: $heading-font;
      margin-bottom: 15px;
      display: block; } }

  // Second Level
  > ul > li > ul > li {
    @include media($desktop) {
      display: inline-block;
      width: 24%; }
    vertical-align: top;
    > a {
      @include h5;
      font-family: $heading-font;
      padding-left: 20px;
      display: block;
      margin-bottom: 10px; } }
  // Third Level
  > ul > li > ul > li > ul > li {
    a {
      padding-left: 40px;
      line-height: 2em;
      display: block; }

    // Pad out all the rest
    ul {
      padding-left: 20px; } } }
